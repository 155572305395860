<div class="modal-body">
  <div class="popover-intro">
    <h3 class="display-4" *ngIf="!isUpdating"><fa-icon [icon]="headerIcon"></fa-icon> Create New Content </h3>
    <h3 class="display-4" *ngIf="isUpdating"><fa-icon [icon]="headerIcon"></fa-icon> Update Content </h3>
    <p class="lead">In the area below, add the desired file on your device and add the name and tags.</p>
  </div>

  <div class="file-drop mt-3" uploadxDrop>
    <input class="inputfile" type="file" [uploadx]="options" (state)="onUpload($event)">
    <div class="file-container">
      <div class="icon">
        <fa-icon [icon]="uploadIcon"></fa-icon>
      </div>
      <label *ngIf="!isUploaded">
        <span class="inputClick">Choose a File</span> or drag it here
      </label>
      <div *ngIf="currentState" class="state">
        <div>
          <strong>{{currentState.status|uppercase}} - {{fileName}}</strong>
        </div>
        <div class="mt-2 mb-2">
          <strong>Attached File</strong> - {{fileName}} ({{size}} bytes)
          <span *ngIf="currentState?.uploaded" class="badge badge-success">Uploaded</span>
        </div>
        <div class="progress width-container mt-3">
          <progressbar *ngIf="currentState.status == 'uploading'||'complete'" class="progress-striped active"
            [value]="currentState.progress" type="success" [striped]="true" [animate]="true"></progressbar>
          <progressbar *ngIf="currentState.status == 'error'" class="progress-striped active"
            [value]="currentState.progress" type="danger" [striped]="true" [animate]="true"></progressbar>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <div class="d-flex align-items-start">
      <fa-icon class="fs-4 me-3" [icon]="infoIcon"></fa-icon>
      <div class="flex-grow-1">
        <div class="fs-4">
          Content Name
        </div>
        <div class="lead">Enter in the name of the Content</div>
        <div class="input-group input-group-lg mt-2">
          <input class="form-control input-group-lg flex-grow-1" required id="name" type="text" [(ngModel)]="givenName">
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <div class="d-flex align-items-start">
      <fa-icon class="fs-4 me-3" [icon]="infoIcon"></fa-icon>
      <div class="flex-grow-1">
        <div class="fs-4">
          Tags
        </div>
        <div class="lead">Enter in the name and the value of the tag</div>
        <div class="pb-4 pt-3 pe-3 ps-3 form-group row mt-2">
          <label for="noText" class="col-sm-1 fs-5  col-form-label">Name:</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" [(ngModel)]="tagName" required />
          </div>
          <label for="noText" class="pl-2 col-sm-1 fs-5 col-form-label">Value:</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" [(ngModel)]="tagValue" required />
          </div>
          <button class="col-sm-2 btn btn-primary btn-sm" (click)="addtags()">Add Tag</button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row mt-2">
    <div class="col-sm-10">
      <label *ngFor="let c of content.tags"
        class="d-line mt-1 me-2 p-1 px-2 bg-primary text-white text-nowrap">{{c.name}} : {{c.value}}
        <button class="btn btn-icon-link text-white" (click)="removeCharacteristic(c)">
          <fa-icon [icon]="removeIcon"></fa-icon>
        </button>
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="!isUpdating" type="button" [disabled]="isSaving || !isUploaded || !givenName"
    class="btn btn-outline-success" type="submit" (click)="confirm()">
    <fa-icon [icon]="isSaving ? processingIcon : saveIcon" [spin]="isSaving"></fa-icon> Add Content
  </button>
  <button *ngIf="isUpdating" type="button" [disabled]="isSaving || !isUploaded || !givenName"
    class="btn btn-outline-success" type="submit" (click)="update()">
    <fa-icon [icon]="isSaving ? processingIcon : saveIcon" [spin]="isSaving"></fa-icon> Update Content
  </button>
  <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
</div>
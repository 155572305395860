/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activateAnchor } from '../fn/anchor-administration/activate-anchor';
import { ActivateAnchor$Params } from '../fn/anchor-administration/activate-anchor';
import { anchorValidaiton } from '../fn/anchor-administration/anchor-validaiton';
import { AnchorValidaiton$Params } from '../fn/anchor-administration/anchor-validaiton';
import { cloneAnchor } from '../fn/anchor-administration/clone-anchor';
import { CloneAnchor$Params } from '../fn/anchor-administration/clone-anchor';
import { createAnchor } from '../fn/anchor-administration/create-anchor';
import { CreateAnchor$Params } from '../fn/anchor-administration/create-anchor';
import { deactivateAnchor } from '../fn/anchor-administration/deactivate-anchor';
import { DeactivateAnchor$Params } from '../fn/anchor-administration/deactivate-anchor';
import { updateAnchor } from '../fn/anchor-administration/update-anchor';
import { UpdateAnchor$Params } from '../fn/anchor-administration/update-anchor';

@Injectable({ providedIn: 'root' })
export class AnchorAdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createAnchor()` */
  static readonly CreateAnchorPath = '/anchors/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAnchor$Response(params?: CreateAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createAnchor(params?: CreateAnchor$Params, context?: HttpContext): Observable<void> {
    return this.createAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `activateAnchor()` */
  static readonly ActivateAnchorPath = '/anchors/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activateAnchor$Response(params?: ActivateAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return activateAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  activateAnchor(params?: ActivateAnchor$Params, context?: HttpContext): Observable<void> {
    return this.activateAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deactivateAnchor()` */
  static readonly DeactivateAnchorPath = '/anchors/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deactivateAnchor$Response(params?: DeactivateAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deactivateAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deactivateAnchor(params?: DeactivateAnchor$Params, context?: HttpContext): Observable<void> {
    return this.deactivateAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `cloneAnchor()` */
  static readonly CloneAnchorPath = '/anchors/clone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloneAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cloneAnchor$Response(params?: CloneAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cloneAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cloneAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cloneAnchor(params?: CloneAnchor$Params, context?: HttpContext): Observable<void> {
    return this.cloneAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAnchor()` */
  static readonly UpdateAnchorPath = '/anchors/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAnchor$Response(params: UpdateAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAnchor(params: UpdateAnchor$Params, context?: HttpContext): Observable<void> {
    return this.updateAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `anchorValidaiton()` */
  static readonly AnchorValidaitonPath = '/anchors/inUse/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `anchorValidaiton()` instead.
   *
   * This method doesn't expect any request body.
   */
  anchorValidaiton$Response(params: AnchorValidaiton$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return anchorValidaiton(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `anchorValidaiton$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  anchorValidaiton(params: AnchorValidaiton$Params, context?: HttpContext): Observable<boolean> {
    return this.anchorValidaiton$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}

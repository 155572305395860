/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { closeCompletedTasks } from '../fn/tasking-bulk-action/close-completed-tasks';
import { CloseCompletedTasks$Params } from '../fn/tasking-bulk-action/close-completed-tasks';
import { deleteOutstandingTasks } from '../fn/tasking-bulk-action/delete-outstanding-tasks';
import { DeleteOutstandingTasks$Params } from '../fn/tasking-bulk-action/delete-outstanding-tasks';

@Injectable({ providedIn: 'root' })
export class TaskingBulkActionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `closeCompletedTasks()` */
  static readonly CloseCompletedTasksPath = '/tasking/bulk/close/completed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeCompletedTasks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  closeCompletedTasks$Response(params?: CloseCompletedTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return closeCompletedTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeCompletedTasks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  closeCompletedTasks(params?: CloseCompletedTasks$Params, context?: HttpContext): Observable<void> {
    return this.closeCompletedTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteOutstandingTasks()` */
  static readonly DeleteOutstandingTasksPath = '/tasking/bulk/delete/outstanding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOutstandingTasks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteOutstandingTasks$Response(params?: DeleteOutstandingTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOutstandingTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOutstandingTasks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteOutstandingTasks(params?: DeleteOutstandingTasks$Params, context?: HttpContext): Observable<void> {
    return this.deleteOutstandingTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createExecutionItem } from '../fn/tasking/create-execution-item';
import { CreateExecutionItem$Params } from '../fn/tasking/create-execution-item';
import { generateAdhocItem } from '../fn/tasking/generate-adhoc-item';
import { GenerateAdhocItem$Params } from '../fn/tasking/generate-adhoc-item';
import { generateAdhocLocation } from '../fn/tasking/generate-adhoc-location';
import { GenerateAdhocLocation$Params } from '../fn/tasking/generate-adhoc-location';
import { generateExecutionItem } from '../fn/tasking/generate-execution-item';
import { GenerateExecutionItem$Params } from '../fn/tasking/generate-execution-item';
import { generateItemTask } from '../fn/tasking/generate-item-task';
import { GenerateItemTask$Params } from '../fn/tasking/generate-item-task';
import { generateItemTasks } from '../fn/tasking/generate-item-tasks';
import { GenerateItemTasks$Params } from '../fn/tasking/generate-item-tasks';
import { generateLocationTask } from '../fn/tasking/generate-location-task';
import { GenerateLocationTask$Params } from '../fn/tasking/generate-location-task';
import { getExecution } from '../fn/tasking/get-execution';
import { GetExecution$Params } from '../fn/tasking/get-execution';
import { getTask } from '../fn/tasking/get-task';
import { GetTask$Params } from '../fn/tasking/get-task';
import { getTemplate } from '../fn/tasking/get-template';
import { GetTemplate$Params } from '../fn/tasking/get-template';
import { getTemplates } from '../fn/tasking/get-templates';
import { GetTemplates$Params } from '../fn/tasking/get-templates';
import { getVersions } from '../fn/tasking/get-versions';
import { GetVersions$Params } from '../fn/tasking/get-versions';
import { InventoryItemTask as LuklaInventoryInventoryItemTask } from '../models/Lukla/Inventory/inventory-item-task';
import { Response as LuklaInventorySearchTemplatesResponse } from '../models/Lukla/Inventory/SearchTemplates/response';
import { TaskExecutionItem as LuklaInventoryTaskExecutionItem } from '../models/Lukla/Inventory/task-execution-item';
import { TaskTemplate as LuklaInventoryTaskTemplate } from '../models/Lukla/Inventory/task-template';
import { TaskTemplateDefinition as LuklaInventoryTaskTemplateDefinition } from '../models/Lukla/Inventory/task-template-definition';
import { TaskTemplateVersionItem as LuklaInventoryTaskTemplateVersionItem } from '../models/Lukla/Inventory/task-template-version-item';
import { searchTemplates } from '../fn/tasking/search-templates';
import { SearchTemplates$Params } from '../fn/tasking/search-templates';
import { upsertTemplate } from '../fn/tasking/upsert-template';
import { UpsertTemplate$Params } from '../fn/tasking/upsert-template';
import { validateTemplate } from '../fn/tasking/validate-template';
import { ValidateTemplate$Params } from '../fn/tasking/validate-template';

@Injectable({ providedIn: 'root' })
export class TaskingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTemplates()` */
  static readonly GetTemplatesPath = '/tasking/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplates()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getTemplates$Response(params?: GetTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTaskTemplateDefinition>>> {
    return getTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getTemplates(params?: GetTemplates$Params, context?: HttpContext): Observable<Array<LuklaInventoryTaskTemplateDefinition>> {
    return this.getTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTaskTemplateDefinition>>): Array<LuklaInventoryTaskTemplateDefinition> => r.body)
    );
  }

  /** Path part for operation `searchTemplates()` */
  static readonly SearchTemplatesPath = '/tasking/templates/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTemplates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTemplates$Response(params?: SearchTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventorySearchTemplatesResponse>> {
    return searchTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchTemplates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTemplates(params?: SearchTemplates$Params, context?: HttpContext): Observable<LuklaInventorySearchTemplatesResponse> {
    return this.searchTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventorySearchTemplatesResponse>): LuklaInventorySearchTemplatesResponse => r.body)
    );
  }

  /** Path part for operation `getTemplate()` */
  static readonly GetTemplatePath = '/tasking/template/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate$Response(params: GetTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryTaskTemplate>> {
    return getTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate(params: GetTemplate$Params, context?: HttpContext): Observable<LuklaInventoryTaskTemplate> {
    return this.getTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryTaskTemplate>): LuklaInventoryTaskTemplate => r.body)
    );
  }

  /** Path part for operation `getVersions()` */
  static readonly GetVersionsPath = '/tasking/template/{taskTemplateId}/versions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersions$Response(params: GetVersions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTaskTemplateVersionItem>>> {
    return getVersions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersions(params: GetVersions$Params, context?: HttpContext): Observable<Array<LuklaInventoryTaskTemplateVersionItem>> {
    return this.getVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTaskTemplateVersionItem>>): Array<LuklaInventoryTaskTemplateVersionItem> => r.body)
    );
  }

  /** Path part for operation `upsertTemplate()` */
  static readonly UpsertTemplatePath = '/tasking/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertTemplate$Response(params?: UpsertTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryTaskTemplateDefinition>> {
    return upsertTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertTemplate(params?: UpsertTemplate$Params, context?: HttpContext): Observable<LuklaInventoryTaskTemplateDefinition> {
    return this.upsertTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryTaskTemplateDefinition>): LuklaInventoryTaskTemplateDefinition => r.body)
    );
  }

  /** Path part for operation `validateTemplate()` */
  static readonly ValidateTemplatePath = '/tasking/template/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateTemplate$Response(params?: ValidateTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateTemplate(params?: ValidateTemplate$Params, context?: HttpContext): Observable<void> {
    return this.validateTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `generateItemTask()` */
  static readonly GenerateItemTaskPath = '/tasking/generate/task/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateItemTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateItemTask$Response(params?: GenerateItemTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return generateItemTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateItemTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateItemTask(params?: GenerateItemTask$Params, context?: HttpContext): Observable<void> {
    return this.generateItemTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `generateItemTasks()` */
  static readonly GenerateItemTasksPath = '/tasking/generate/task/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateItemTasks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateItemTasks$Response(params?: GenerateItemTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return generateItemTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateItemTasks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateItemTasks(params?: GenerateItemTasks$Params, context?: HttpContext): Observable<void> {
    return this.generateItemTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `generateLocationTask()` */
  static readonly GenerateLocationTaskPath = '/tasking/generate/task/location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateLocationTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateLocationTask$Response(params?: GenerateLocationTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return generateLocationTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateLocationTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateLocationTask(params?: GenerateLocationTask$Params, context?: HttpContext): Observable<void> {
    return this.generateLocationTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `generateAdhocItem()` */
  static readonly GenerateAdhocItemPath = '/tasking/generate/adhoc/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateAdhocItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAdhocItem$Response(params?: GenerateAdhocItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return generateAdhocItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateAdhocItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAdhocItem(params?: GenerateAdhocItem$Params, context?: HttpContext): Observable<void> {
    return this.generateAdhocItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `generateAdhocLocation()` */
  static readonly GenerateAdhocLocationPath = '/tasking/generate/adhoc/location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateAdhocLocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAdhocLocation$Response(params?: GenerateAdhocLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return generateAdhocLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateAdhocLocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAdhocLocation(params?: GenerateAdhocLocation$Params, context?: HttpContext): Observable<void> {
    return this.generateAdhocLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `generateExecutionItem()` */
  static readonly GenerateExecutionItemPath = '/tasking/generate/execution';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateExecutionItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateExecutionItem$Response(params?: GenerateExecutionItem$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryTaskExecutionItem>> {
    return generateExecutionItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateExecutionItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateExecutionItem(params?: GenerateExecutionItem$Params, context?: HttpContext): Observable<LuklaInventoryTaskExecutionItem> {
    return this.generateExecutionItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryTaskExecutionItem>): LuklaInventoryTaskExecutionItem => r.body)
    );
  }

  /** Path part for operation `createExecutionItem()` */
  static readonly CreateExecutionItemPath = '/tasking/create/execution';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExecutionItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExecutionItem$Response(params?: CreateExecutionItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createExecutionItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExecutionItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createExecutionItem(params?: CreateExecutionItem$Params, context?: HttpContext): Observable<void> {
    return this.createExecutionItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTask()` */
  static readonly GetTaskPath = '/tasking/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask$Response(params: GetTask$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryInventoryItemTask>> {
    return getTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask(params: GetTask$Params, context?: HttpContext): Observable<LuklaInventoryInventoryItemTask> {
    return this.getTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryInventoryItemTask>): LuklaInventoryInventoryItemTask => r.body)
    );
  }

  /** Path part for operation `getExecution()` */
  static readonly GetExecutionPath = '/tasking/item/execution/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExecution()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExecution$Response(params: GetExecution$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryTaskExecutionItem>> {
    return getExecution(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExecution$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExecution(params: GetExecution$Params, context?: HttpContext): Observable<LuklaInventoryTaskExecutionItem> {
    return this.getExecution$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryTaskExecutionItem>): LuklaInventoryTaskExecutionItem => r.body)
    );
  }

}

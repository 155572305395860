/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CommentView as LuklaInventoryCommentView } from '../../models/Lukla/Inventory/comment-view';

export interface GetComments$Params {
  inventoryItemId: string;
}

export function getComments(http: HttpClient, rootUrl: string, params: GetComments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryCommentView>>> {
  const rb = new RequestBuilder(rootUrl, getComments.PATH, 'get');
  if (params) {
    rb.path('inventoryItemId', params.inventoryItemId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<LuklaInventoryCommentView>>;
    })
  );
}

getComments.PATH = '/comments/{inventoryItemId}';

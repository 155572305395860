<div class="mt-4 p-5 bg-primary text-white rounded">
    <h1>Welcome to Lukla Tasks</h1>
    <p class="m-2 pb-2 border-bottom">Lukla tasks allows you to add/create inventory items, task templates, campagins and ultimately tasks to run in the field. Use this checklist to get started:</p>
    <ul class="m-2 mt-3 list-unstyled">
      <li role="button" (click)="goToAnchors()" class="mt-2 d-flex align-items-center"><fa-icon [icon]="checklistIcon" class="h3 me-4"></fa-icon> <span class="h5">Create <span class="fw-bold">Anchors</span> to define data that your items may have</span></li>
      <li role="button" (click)="goToItems()" class="mt-2 d-flex align-items-center"><fa-icon [icon]="checklistIcon" class="h3 me-4"></fa-icon> <span class="h5">Create new <span class="fw-bold">Divisions</span> that define various divisions for technicians to be assigned</span></li>
      <li role="button" (click)="goToItems()" class="mt-2 d-flex align-items-center"><fa-icon [icon]="checklistIcon" class="h3 me-4"></fa-icon> <span class="h5">Create new <span class="fw-bold">Areas</span> that define various areas for technicians to be assigned</span></li>
      <li role="button" (click)="goToItems()" class="mt-2 d-flex align-items-center"><fa-icon [icon]="checklistIcon" class="h3 me-4"></fa-icon> <span class="h5">Create or Import <span class="fw-bold">Inventory Items</span> to populate inventory items</span></li>
      <li role="button" (click)="goToTemplates()" class="mt-2 d-flex align-items-center"><fa-icon [icon]="checklistIcon" class="h3 me-4"></fa-icon> <span class="h5">Build <span class="fw-bold">Task Templates</span> to define tasks in the system</span></li> 
      <li role="button" (click)="goToCampaigns()" class="mt-2 d-flex align-items-center"><fa-icon [icon]="checklistIcon" class="h3 me-4"></fa-icon> <span class="h5">Create your first <span class="fw-bold">Campaign</span> to organize and schedule tasks</span></li> 
    </ul>
  </div>
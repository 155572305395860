import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCircleBolt } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TaskActionEnum } from '../../../utils/task-actions-enum.model';

@Component({
  selector: 'app-select-action-modal',
  templateUrl: './select-action-modal.component.html',
  styleUrls: ['./select-action-modal.component.scss']
})
export class SelectActionModalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  @Output() selectAction = new EventEmitter<TaskActionEnum>();

  //  Icons
  selectIcon = faCheckCircle;
  headerIcon = faCircleBolt;

  //  Model
  selectedAction: TaskActionEnum;

  ngOnInit(): void {}

  noActionSelected = () => this.selectedAction === undefined;
  onSelectAction = (action: TaskActionEnum) => this.selectedAction = action;
  select = () => this.selectAction.emit(this.selectedAction);
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { attachLocation } from '../fn/item-updates/attach-location';
import { AttachLocation$Params } from '../fn/item-updates/attach-location';
import { removeInventoryAnchor } from '../fn/item-updates/remove-inventory-anchor';
import { RemoveInventoryAnchor$Params } from '../fn/item-updates/remove-inventory-anchor';
import { updateItemArea } from '../fn/item-updates/update-item-area';
import { UpdateItemArea$Params } from '../fn/item-updates/update-item-area';
import { updateItemDivision } from '../fn/item-updates/update-item-division';
import { UpdateItemDivision$Params } from '../fn/item-updates/update-item-division';
import { upsertInventoryAnchor } from '../fn/item-updates/upsert-inventory-anchor';
import { UpsertInventoryAnchor$Params } from '../fn/item-updates/upsert-inventory-anchor';

@Injectable({ providedIn: 'root' })
export class ItemUpdatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `upsertInventoryAnchor()` */
  static readonly UpsertInventoryAnchorPath = '/inventory/anchors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertInventoryAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertInventoryAnchor$Response(params?: UpsertInventoryAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return upsertInventoryAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertInventoryAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertInventoryAnchor(params?: UpsertInventoryAnchor$Params, context?: HttpContext): Observable<string> {
    return this.upsertInventoryAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `removeInventoryAnchor()` */
  static readonly RemoveInventoryAnchorPath = '/inventory/anchors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeInventoryAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeInventoryAnchor$Response(params?: RemoveInventoryAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeInventoryAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeInventoryAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeInventoryAnchor(params?: RemoveInventoryAnchor$Params, context?: HttpContext): Observable<void> {
    return this.removeInventoryAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateItemDivision()` */
  static readonly UpdateItemDivisionPath = '/inventory/division';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateItemDivision()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateItemDivision$Response(params?: UpdateItemDivision$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateItemDivision(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateItemDivision$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateItemDivision(params?: UpdateItemDivision$Params, context?: HttpContext): Observable<void> {
    return this.updateItemDivision$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateItemArea()` */
  static readonly UpdateItemAreaPath = '/inventory/area';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateItemArea()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateItemArea$Response(params?: UpdateItemArea$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateItemArea(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateItemArea$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateItemArea(params?: UpdateItemArea$Params, context?: HttpContext): Observable<void> {
    return this.updateItemArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attachLocation()` */
  static readonly AttachLocationPath = '/inventory/location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachLocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachLocation$Response(params?: AttachLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachLocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachLocation(params?: AttachLocation$Params, context?: HttpContext): Observable<void> {
    return this.attachLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

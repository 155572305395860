import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faArrowRotateRight, faExpandWide, faFileDownload, faQuestionSquare, faTag, faTrashXmark } from '@fortawesome/pro-solid-svg-icons';
import { saveAs } from 'file-saver';
import { ContentService } from 'src/app/api/services/content.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentView, LocationContentItemView } from './content-model';
import { BsModalRef } from 'ngx-bootstrap/modal';



@Component({
  selector: 'inventory-image-viewer',
  templateUrl: './inventory-image-viewer.component.html',
  styleUrls: ['./inventory-image-viewer.component.scss']
})
export class InventoryImageViewerComponent implements OnInit {

  constructor(
    private readonly contentService: ContentService,
    private readonly domSanitizer: DomSanitizer,
    public bsModalRef: BsModalRef  ) { }

  @Input() editable = false;
  @Input() content: ContentView|LocationContentItemView;
  @Output() delete = new EventEmitter();
  @Output() reupload = new EventEmitter();
  @Output() uploaded = new EventEmitter();

  displayError = false;

  selectedImageIndex: number = 1;

  // Flags
  expandImages = false;
  isLoaded=false

  // Icons
  downloadIcon = faFileDownload;
  expandIcon = faExpandWide;
  tagIcon = faTag
  noItemsIcon = faQuestionSquare;
  removeIcon = faTrashXmark
  updateIcon = faArrowRotateRight

  async ngOnInit(): Promise<void> {
    await this.load()
  }

  async load() {
    try {
      this.isLoaded = false;
      var image = await this.contentService.downloadHistoricalContent({ fileId: this.content.fileId }).toPromise();
      this.createImageFromBlob(image);
    } catch (err: any) {
      if (err.status === 500) {
        this.displayError = true
      }
    }
  }

  showLightbox(index) {
    this.selectedImageIndex = index;
    this.expandImages = true;
  }

  closeEventHandler() {
    this.expandImages = false;
    this.selectedImageIndex = -1;
  }

  download(item) {
    var re = /(?:\.([^.]+))?$/;
    var extension = re.exec(item.fileName);
    var fileName = this.content.name ? `${this.content.name}${extension[0]}` : this.content.fileName;
    saveAs(item.image, fileName);
  }

  async remove(){
   this.delete.emit(this.content)
  }

 async update(){
      this.reupload.emit(this.content)
  }


  createImageFromBlob( image: Blob) {
    try {
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          this.content.thumb = this.domSanitizer.bypassSecurityTrustUrl(
            <string>reader.result
          );
          this.content.image = reader.result;
          this.isLoaded = true
        },
        false
      );

      if (image) {
        reader.readAsDataURL(image);
      }
    } catch (err) {
    }
  }

}



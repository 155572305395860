import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from '../notification-list/notification-list/notification-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { faBell, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NotificationLogicService } from '../notification-logic-service';
import { SubscriptionEventsView } from 'src/app/api/models/Lukla/Subscriptions/subscription-events-view';

@Component({
  selector: 'notification-drawer',
  standalone: true,
  imports: [CommonModule, NotificationListComponent, FontAwesomeModule, TooltipModule],
  templateUrl: './notification-drawer.component.html',
  styleUrls: ['./notification-drawer.component.scss']
})
export class NotificationDrawerComponent implements OnInit, OnDestroy {

  constructor(private elRef: ElementRef,
    private readonly toastrService: ToastrService,
    private readonly notificationService: NotificationLogicService
  ) {}

  private outsideClickListener: any;
  notifications: SubscriptionEventsView [] = [];
  previousUnreadCount = 0;


  notificationIcon = faBell;
  closeIcon = faTimes;
  
  isOpen = false;



  async ngOnInit(): Promise<void> {
    this.outsideClickListener = this.closeOffcanvasOnClickOutside.bind(this);
    document.addEventListener('click', this.outsideClickListener);
    await this.updateNotifications();
  }


  async updateNotifications() {
    this.notifications = await this.notificationService.getNotifications();
  }
  
  ngOnDestroy(): void {
    document.removeEventListener('click', this.outsideClickListener);
  }

  toggleOffcanvas() {
    this.elRef.nativeElement.querySelector('.offcanvas').classList.toggle('open');
  }

  closeOffcanvasOnClickOutside(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.elRef.nativeElement.querySelector('.offcanvas').classList.remove('open');
    }
  }
}

<div class="row">
    <div id="landing-page" class="g-0 col-xs-12">
        <div id="landing-container">
            <div class="header">
                <img src="https://app.lukla.co/Content/img/Login/logo-white.png">
                <div class="d-flex justify-content-end align-items-baseline workspace-title">
                    <h3 class="display-2">Inventory</h3>
                </div>
                <div class="alert alert-warning landing-item">
                    <div class="d-flex align-items-center">
                        <h3>
                            <fa-icon [icon]="noAccessIcon"></fa-icon> No Access
                        </h3>
                    </div>
                    <p>This organization does not have access to this application, if you believe this is in error
                        please
                        contact our <a href="mailto:support@lukla.co">support team</a></p>

                    <div class="d-flex justify-content-center" *ngIf="isReady">
                        <div class="btn-group" dropdown>
                            <button id="button-basic" dropdownToggle type="button"
                                class="btn btn-dark btn-lg dropdown-toggle" aria-controls="dropdown-basic">
                                {{currentCompany.name}}
                                <span class="caret"></span>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                                aria-labelledby="button-basic">
                                <li *ngFor="let c of companies" role="menuitem"><span class="dropdown-item"
                                        (click)="changeCompany(c)">{{c.name}}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

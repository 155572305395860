<div class="page-header sticky ps-2">
    <div class="d-flex justify-content-between">
        <span class="header-title fs-3">Templates</span>
        <div class="d-flex align-items-center">
            <button class="btn btn-icon-link h3" (click)="showFilters = !showFilters">
                <fa-icon [icon]="filterIcon" tooltip="Filters"></fa-icon> {{showFilters ? 'Hide Filters' : 'Show Filters'}}
            </button>        
            <div class="ms-3 counter">
                <fa-icon [icon]="countIcon"></fa-icon>
                <span class="ms-1">Showing {{ filtered }} of {{ total }}</span>
            </div>
        </div>
    </div>
    <div class="mt-3" *ngIf="showFilters">
        <div class="input-group input-group-lg flex-nowrap">
            <span class="input-group-text">
                <fa-icon [icon]="isSearching ? processingIcon : searchIcon" [spin]="isSearching"></fa-icon>
            </span>
            <debounced-input class="input-group-lg flex-grow-1" placeholder="Search..." (value)="searchChanged($event)">
            </debounced-input>
        </div>
    </div>
</div>
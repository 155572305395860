<ng-select
    [items]="items$ | async"
    [(ngModel)]="selectedItem"
    [addTag]="allowExternal"
    [multiple]="allowMultiple"
    bindLabel="userName"
    bindValue="userName"
    [trackByFn]="trackByFn"
    [minTermLength]="2"
    [loading]="loading"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="searchInput$"
    (change)="onChange($event)">
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
       <lukla-user [user]=item></lukla-user>
    </ng-template>
</ng-select>

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { campaignItems } from '../fn/campaign/campaign-items';
import { CampaignItems$Params } from '../fn/campaign/campaign-items';
import { closeCampaign } from '../fn/campaign/close-campaign';
import { CloseCampaign$Params } from '../fn/campaign/close-campaign';
import { createCampaign } from '../fn/campaign/create-campaign';
import { CreateCampaign$Params } from '../fn/campaign/create-campaign';
import { getCampaign } from '../fn/campaign/get-campaign';
import { GetCampaign$Params } from '../fn/campaign/get-campaign';
import { getCampaigns } from '../fn/campaign/get-campaigns';
import { GetCampaigns$Params } from '../fn/campaign/get-campaigns';
import { CampaignTaskItem as LuklaInventoryCampaignTaskItem } from '../models/Lukla/Inventory/campaign-task-item';
import { CampaignView as LuklaInventoryCampaignView } from '../models/Lukla/Inventory/campaign-view';
import { mergeCampaign } from '../fn/campaign/merge-campaign';
import { MergeCampaign$Params } from '../fn/campaign/merge-campaign';

@Injectable({ providedIn: 'root' })
export class CampaignService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCampaign()` */
  static readonly CreateCampaignPath = '/campaigns/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCampaign()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCampaign$Response(params?: CreateCampaign$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createCampaign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCampaign$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCampaign(params?: CreateCampaign$Params, context?: HttpContext): Observable<string> {
    return this.createCampaign$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getCampaign()` */
  static readonly GetCampaignPath = '/campaigns/details/{identifier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaign()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaign$Response(params: GetCampaign$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryCampaignView>> {
    return getCampaign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampaign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaign(params: GetCampaign$Params, context?: HttpContext): Observable<LuklaInventoryCampaignView> {
    return this.getCampaign$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryCampaignView>): LuklaInventoryCampaignView => r.body)
    );
  }

  /** Path part for operation `campaignItems()` */
  static readonly CampaignItemsPath = '/campaigns/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  campaignItems$Response(params?: CampaignItems$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryCampaignTaskItem>>> {
    return campaignItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `campaignItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  campaignItems(params?: CampaignItems$Params, context?: HttpContext): Observable<Array<LuklaInventoryCampaignTaskItem>> {
    return this.campaignItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryCampaignTaskItem>>): Array<LuklaInventoryCampaignTaskItem> => r.body)
    );
  }

  /** Path part for operation `closeCampaign()` */
  static readonly CloseCampaignPath = '/campaigns/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeCampaign()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  closeCampaign$Response(params?: CloseCampaign$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return closeCampaign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeCampaign$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  closeCampaign(params?: CloseCampaign$Params, context?: HttpContext): Observable<void> {
    return this.closeCampaign$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mergeCampaign()` */
  static readonly MergeCampaignPath = '/campaigns/merge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeCampaign()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeCampaign$Response(params?: MergeCampaign$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mergeCampaign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeCampaign$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeCampaign(params?: MergeCampaign$Params, context?: HttpContext): Observable<void> {
    return this.mergeCampaign$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCampaigns()` */
  static readonly GetCampaignsPath = '/campaigns/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaigns$Response(params?: GetCampaigns$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryCampaignView>>> {
    return getCampaigns(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaigns(params?: GetCampaigns$Params, context?: HttpContext): Observable<Array<LuklaInventoryCampaignView>> {
    return this.getCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryCampaignView>>): Array<LuklaInventoryCampaignView> => r.body)
    );
  }

}

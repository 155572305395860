import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSplit } from '@fortawesome/pro-solid-svg-icons';
import { AnchorTypeEnum } from '../../../../api/models/Lukla/Inventory/anchor-type-enum';
import { AnchorService } from '../../../../api/services/anchor.service';

@Component({
  selector: 'task-define-verify-condition',
  templateUrl: './define-verify-condition.component.html',
  styleUrls: ['./define-verify-condition.component.scss']
})
export class DefineVerifyConditionComponent implements OnInit {

  constructor(
    private anchorService: AnchorService
  ) { }

  @Input() definition: any;
  @Input() item: any;
  @Input() isEditable = true;
  @Output() itemChange = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();

  conditionIcon = faSplit;

  name: string;
  choices: any[];
  dataType: AnchorTypeEnum;
  parentName: string;

  operators = [
    { name: 'Equals', operator: '=' },
    { name: 'Not Equals', operator: '!=' }
  ];

  async ngOnInit(): Promise<void> {
    if (this.definition.anchorId !== null) {
      const selectedAnchor = await this.anchorService.getAnchor({id: this.definition.anchorId}).toPromise();
      this.name = selectedAnchor.displayName;
      this.choices = selectedAnchor.choices;
      this.dataType = selectedAnchor.anchorType;
      this.parentName = selectedAnchor.parent;
    } else {
      this.name = this.definition.name;
      this.choices = this.definition.choices;
      this.dataType = this.definition.dataType;
    }
  }

  onRemove = () => this.remove.emit(this.item);
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faAnchorCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnchorView } from '../../../api/models/Lukla/Inventory/anchor-view';

@Component({
  selector: 'app-select-anchor-modal',
  templateUrl: './select-anchor-modal.component.html',
  styleUrls: ['./select-anchor-modal.component.scss']
})
export class SelectAnchorModalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  @Output() selectAnchor = new EventEmitter<AnchorView>();

  //  Icons
  selectIcon = faCheckCircle;
  headerIcon = faAnchorCircleCheck;

  //  Model
  selectedAnchor: AnchorView;

  ngOnInit(): void {}

  noAnchorSelected = () => this.selectedAnchor === undefined;
  onSelectAnchor = (Anchor: AnchorView) => this.selectedAnchor = Anchor;
  select = () => this.selectAnchor.emit(this.selectedAnchor);
}

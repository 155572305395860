/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { checkItemStatus } from '../fn/task-exceptions/check-item-status';
import { CheckItemStatus$Params } from '../fn/task-exceptions/check-item-status';
import { getItemExceptions } from '../fn/task-exceptions/get-item-exceptions';
import { GetItemExceptions$Params } from '../fn/task-exceptions/get-item-exceptions';
import { TaskExceptionView as LuklaInventoryTaskExceptionView } from '../models/Lukla/Inventory/task-exception-view';
import { resolveException } from '../fn/task-exceptions/resolve-exception';
import { ResolveException$Params } from '../fn/task-exceptions/resolve-exception';

@Injectable({ providedIn: 'root' })
export class TaskExceptionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getItemExceptions()` */
  static readonly GetItemExceptionsPath = '/tasking/exceptions/active/{itemTaskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemExceptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemExceptions$Response(params: GetItemExceptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTaskExceptionView>>> {
    return getItemExceptions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItemExceptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemExceptions(params: GetItemExceptions$Params, context?: HttpContext): Observable<Array<LuklaInventoryTaskExceptionView>> {
    return this.getItemExceptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTaskExceptionView>>): Array<LuklaInventoryTaskExceptionView> => r.body)
    );
  }

  /** Path part for operation `resolveException()` */
  static readonly ResolveExceptionPath = '/tasking/exceptions/resolve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveException()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resolveException$Response(params?: ResolveException$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resolveException(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveException$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resolveException(params?: ResolveException$Params, context?: HttpContext): Observable<void> {
    return this.resolveException$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `checkItemStatus()` */
  static readonly CheckItemStatusPath = '/tasking/exceptions/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkItemStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkItemStatus$Response(params?: CheckItemStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkItemStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkItemStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkItemStatus(params?: CheckItemStatus$Params, context?: HttpContext): Observable<void> {
    return this.checkItemStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

<div class="form-group" *ngFor="let g of groupAnchors">
    <label class="h5 fw-normal my-2">
        {{g.displayName}}
    </label>
    <div class="input-group flex-nowrap">

            <span class="input-group-text">
                <fa-icon [icon]="g.iconName" [fixedWidth]="true"></fa-icon>
            </span>

        <ng-select class=" flex-grow-1"
            [items]="g.items"
            [multiple]="true"
            [closeOnSelect]="false"
            [searchable]="true"
            [virtualScroll]="true"
            placeholder="Group By {{g.displayName}}"
            [(ngModel)]="g.selected"
            (scroll)="onScroll($event, g)"
            (scrollToEnd)="onScrollToEnd(g)"
            (change)="groupUpdated($event)">
        </ng-select>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import { faAnchor, faSparkles, faTimes, IconName } from '@fortawesome/pro-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SelectAnchorModalComponent } from '../../../../anchors';
import { AnchorTypeEnum } from '../../../../api/models/Lukla/Inventory/anchor-type-enum';
import { AnchorView } from '../../../../api/models/Lukla/Inventory/anchor-view';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';
import { AnchorService } from '../../../../api/services/anchor.service';

@Component({
  selector: 'task-define-verify-data',
  templateUrl: './define-verify-data.component.html',
  styleUrls: ['./define-verify-data.component.scss']
})
export class DefineVerifyDataComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    private anchorService: AnchorService
  ) { }

  //  Icon
  headerIcon = faCheckDouble;
  anchorIcon = faAnchor;
  adhocIcon = faSparkles;
  removeIcon = faTimes;

  @Input() isEditable = true;
  @Input() step: TaskTemplateStep;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();

  //  Model
  useAnchor: boolean = false;
  useAdhoc: boolean = false;
  decisionMade = () => this.useAnchor || this.useAdhoc;
  anchorTypes = AnchorTypeEnum;

  selectedAnchor: AnchorView;
  availableDataTypes = [
    { name: 'Free Text', id: AnchorTypeEnum.FreeText },
    { name: 'Choice', id: AnchorTypeEnum.Choice },
    { name: 'Numeric', id: AnchorTypeEnum.Numeric },
    { name: 'Date', id: AnchorTypeEnum.Date }
  ];

  //  MODAL
  bsModalRef: BsModalRef;

  async ngOnInit(): Promise<void> {
    if (this.step && !this.step.actionDefinition) {
      this.step.actionDefinition = {
        allowNotApplicable: true,
        allowUnknown: true,
        exceptions: [],
        conditionalSteps: [],
        conditionalSections: []
      };
    }
    else if (this.step?.actionDefinition) {
      this.useAnchor = this.step.actionDefinition.anchorId !== null;
      this.useAdhoc = !this.useAnchor;

      if (this.useAnchor) {
        this.selectedAnchor = await this.anchorService.getAnchor({ id: this.step.actionDefinition.anchorId }).toPromise();
      }
    }
  }

  selectAnchor = () => {
    this.useAnchor = true;
    this.useAdhoc = false;
    this.step.actionDefinition = {
      anchorId: null,
      name: null,
      choices: null,
      dataType: null,
      icon: null
    }

    //  Open Selector
    this.bsModalRef = this.modalService.show(SelectAnchorModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.selectAnchor.subscribe((anchor: AnchorView) => {
      this.selectedAnchor = anchor;
      this.step.actionDefinition.anchorId = anchor.id;
      this.stepChange.emit(this.step);
      this.bsModalRef.hide();
    });
  }

  getTypeName(type: AnchorTypeEnum) {
    if (type == AnchorTypeEnum.FreeText) {
      return 'Free Text';
    }
    if (type == AnchorTypeEnum.Choice) {
      return 'Choice';
    }
    if (type == AnchorTypeEnum.Numeric) {
      return 'Numeric';
    }
    if (type == AnchorTypeEnum.Date) {
      return 'Date';
    }
    return '';
  }

  getIcon(iconName: string) {
    return <IconName>iconName;
  }

  defineAdhoc = () => {
    this.useAdhoc = true;
    this.useAnchor = false;
    this.step.actionDefinition = {
      anchorId: null,
      name: null,
      choices: [],
      dataType: null,
      icon: null
    }
    this.stepChange.emit(this.step);
  }

  //  Choices
  currentChoiceName: string;
  isChoiceType = () => this.step.actionDefinition.dataType === this.anchorTypes.Choice
  addChoice() {
    if (this.currentChoiceName) {
      this.step.actionDefinition.choices.push(this.currentChoiceName);
      this.stepChange.emit(this.step);
      this.currentChoiceName = null;
    }
  }
  removeChoice(name: string) {
    this.step.actionDefinition.choices = this.step.actionDefinition.choices.filter(x => x !== name);
    this.stepChange.emit(this.step);
  }

  //  Options
  toggleNotApplicable() {
    this.step.actionDefinition.allowNotApplicable = !this.step.actionDefinition.allowNotApplicable;
    this.stepChange.emit(this.step);
  }

  toggleUnknown() {
    this.step.actionDefinition.allowUnknown = !this.step.actionDefinition.allowUnknown
    this.stepChange.emit(this.step);
  }

  toggleRaiseException() {
    this.step.actionDefinition.raiseFlagWhenChanged = !this.step.actionDefinition.raiseFlagWhenChanged;
    this.stepChange.emit(this.step);
  }

  toggleRequireDateWhenChanged() {
    this.step.actionDefinition.requireDateWhenChanged = !this.step.actionDefinition.requireDateWhenChanged;
    this.stepChange.emit(this.step);
  }
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { attachFlag } from '../fn/flag/attach-flag';
import { AttachFlag$Params } from '../fn/flag/attach-flag';
import { clearFlag } from '../fn/flag/clear-flag';
import { ClearFlag$Params } from '../fn/flag/clear-flag';
import { getFlags } from '../fn/flag/get-flags';
import { GetFlags$Params } from '../fn/flag/get-flags';
import { FlagView as LuklaInventoryFlagView } from '../models/Lukla/Inventory/flag-view';
import { upsertFlag } from '../fn/flag/upsert-flag';
import { UpsertFlag$Params } from '../fn/flag/upsert-flag';

@Injectable({ providedIn: 'root' })
export class FlagService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFlags()` */
  static readonly GetFlagsPath = '/flags/flags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlags$Response(params?: GetFlags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryFlagView>>> {
    return getFlags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlags(params?: GetFlags$Params, context?: HttpContext): Observable<Array<LuklaInventoryFlagView>> {
    return this.getFlags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryFlagView>>): Array<LuklaInventoryFlagView> => r.body)
    );
  }

  /** Path part for operation `upsertFlag()` */
  static readonly UpsertFlagPath = '/flags/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertFlag()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertFlag$Response(params?: UpsertFlag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertFlag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertFlag$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertFlag(params?: UpsertFlag$Params, context?: HttpContext): Observable<void> {
    return this.upsertFlag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attachFlag()` */
  static readonly AttachFlagPath = '/flags/attach';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachFlag()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachFlag$Response(params?: AttachFlag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachFlag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachFlag$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachFlag(params?: AttachFlag$Params, context?: HttpContext): Observable<void> {
    return this.attachFlag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `clearFlag()` */
  static readonly ClearFlagPath = '/flags/clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearFlag()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clearFlag$Response(params?: ClearFlag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clearFlag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearFlag$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clearFlag(params?: ClearFlag$Params, context?: HttpContext): Observable<void> {
    return this.clearFlag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faQuestion } from '@fortawesome/pro-regular-svg-icons';
import { faQuestion as faQuestionBold } from '@fortawesome/pro-solid-svg-icons';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';

@Component({
  selector: 'task-define-check',
  templateUrl: './define-check.component.html',
  styleUrls: ['./define-check.component.scss']
})
export class DefineCheckComponent implements OnInit {

  @ViewChild("stepElement") stepElement: ElementRef;

  constructor() { }

  headerIcon = faQuestion;
  questionIcon = faQuestionBold

  @Input() isEditable = true;
  @Input() step: TaskTemplateStep;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();

  ngOnInit(): void {
    if (this.step && !this.step.actionDefinition) {
      this.step.actionDefinition = {
        question: '',
        noText: 'NO',
        yesText: 'YES'
      };
    }
  }
}


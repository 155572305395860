<div class="d-flex align-items-center" *ngIf="user || email" [class.flip-user]="flip">
  <div *ngIf="!flip && !nameOnly" class="avatar flex-shrink-0" [class.bland]="bland" [style.backgroundColor]="color" [class.small]="small">{{avatarName()}}</div>
  <div *ngIf="user && !avatarOnly && !nameOnly" [class.user-flip]="flip" class="name">
    <div class="fullname">
      {{user.name}}
    </div>
    <div class="email" *ngIf="!slim">
      {{ subtext || user.userName}}
    </div>
  </div>
  <div *ngIf="!user && !avatarOnly">
    <div class="email">{{email}}</div>
  </div>
  <div *ngIf="nameOnly">
    <div class="fullname">
      {{user.name}}
    </div>
  </div>
  <div *ngIf="flip" class="avatar flex-shrink-0" [style.backgroundColor]="color" [class.small]="small" [class.flip]="flip">{{avatarName()}}</div>
</div>

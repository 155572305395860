/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { associate_1 } from '../fn/tag-administration/associate-1';
import { Associate_1$Params } from '../fn/tag-administration/associate-1';
import { associateNew } from '../fn/tag-administration/associate-new';
import { AssociateNew$Params } from '../fn/tag-administration/associate-new';
import { generate } from '../fn/tag-administration/generate';
import { Generate$Params } from '../fn/tag-administration/generate';
import { generateBulk } from '../fn/tag-administration/generate-bulk';
import { GenerateBulk$Params } from '../fn/tag-administration/generate-bulk';
import { TagItem as LuklaInventoryTagItem } from '../models/Lukla/Inventory/tag-item';

@Injectable({ providedIn: 'root' })
export class TagAdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `generate()` */
  static readonly GeneratePath = '/administration/tags/generate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generate()` instead.
   *
   * This method doesn't expect any request body.
   */
  generate$Response(params?: Generate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryTagItem>> {
    return generate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generate(params?: Generate$Params, context?: HttpContext): Observable<LuklaInventoryTagItem> {
    return this.generate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryTagItem>): LuklaInventoryTagItem => r.body)
    );
  }

  /** Path part for operation `generateBulk()` */
  static readonly GenerateBulkPath = '/administration/tags/generate/bulk/{amount}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateBulk()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateBulk$Response(params: GenerateBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return generateBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateBulk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateBulk(params: GenerateBulk$Params, context?: HttpContext): Observable<Blob> {
    return this.generateBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `associate_1()` */
  static readonly Associate_1Path = '/administration/tags/associate/asset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associate_1()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associate_1$Response(params?: Associate_1$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return associate_1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associate_1$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associate_1(params?: Associate_1$Params, context?: HttpContext): Observable<void> {
    return this.associate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `associateNew()` */
  static readonly AssociateNewPath = '/administration/tags/associate/new/assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateNew()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associateNew$Response(params?: AssociateNew$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return associateNew(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associateNew$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associateNew(params?: AssociateNew$Params, context?: HttpContext): Observable<string> {
    return this.associateNew$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}

<div class="page-header sticky">
    <div class="d-flex align-items-center justify-content-between" *ngIf="template">
        <div class="d-flex align-items-center">
            <ng-content></ng-content>
            <div class="me-2 p-1 px-2 fs-5 text-white version" [ngClass]="statusClass()" (click)="changeVersionVisibility()"> {{statusText()}} | Version {{template.version}}</div>
            <span class="header-title fs-3">{{template.name}}</span>
        
            <div class="actions ms-5" *ngIf="enableEdit">
                <button class="btn btn-outline-primary" [disabled]="isSaving" (click)="saveTemplate()"><fa-icon [icon]="isSaving ? processingIcon : saveIcon" [spin]="isSaving" [fixedWidth]="true"></fa-icon> Save Template</button>
                <button class="ms-2 btn" [class.btn-primary]="!isEditable" [class.btn-outline-primary]="isEditable" (click)="changeEditable()">View Read-only</button>
                <button class="ms-4 btn btn-dark" (click)="clone()">
                    <fa-icon [icon]="cloneIcon"></fa-icon> Clone Template
                </button>
            </div>    
        </div> 
        <a class="btn btn-icon-link fs-3" tooltip="Open Full">
            <fa-icon [icon]="goToIcon"></fa-icon>
        </a>
    </div>
    
    <div *ngIf="!isValid" class="alert alert-danger p-5 mt-3 d-flex">
        <fa-icon class="me-5 display-3" [icon]="validationIcon"></fa-icon>
        <div class="d-flex flex-grow-1 justify-content-between">
            <div>
                <h3>Template Has Errors</h3>
                <p>We cannot save the template, there are validation errors that need to be fixed before it can be saved, please see the errors below</p>
                <ul>
                    <li *ngFor="let t of validationErrors">{{t}}</li>
                </ul>    
            </div>
            <button type="button" class="btn-close" aria-label="Close" (click)="isValid = true"></button>
        </div>
    </div>

    <div *ngIf="!isLatest && isEditable" class="alert alert-warning p-3 mt-3 d-flex">
        <fa-icon class="me-5 display-3" [icon]="latestIcon"></fa-icon>
        <div class="d-flex flex-grow-1 justify-content-between">
            <div>
                <h3>Previous Version</h3>
                <p>This is a previous version of the template that cannot be edited, if you would like to edit this template, please find the latest version</p>
            </div>
        </div>
    </div>
</div>

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteLocationAnchor } from '../fn/location-anchor/delete-location-anchor';
import { DeleteLocationAnchor$Params } from '../fn/location-anchor/delete-location-anchor';
import { upsertLocationAnchor } from '../fn/location-anchor/upsert-location-anchor';
import { UpsertLocationAnchor$Params } from '../fn/location-anchor/upsert-location-anchor';

@Injectable({ providedIn: 'root' })
export class LocationAnchorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `upsertLocationAnchor()` */
  static readonly UpsertLocationAnchorPath = '/locations/anchors/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertLocationAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertLocationAnchor$Response(params?: UpsertLocationAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertLocationAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertLocationAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertLocationAnchor(params?: UpsertLocationAnchor$Params, context?: HttpContext): Observable<void> {
    return this.upsertLocationAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteLocationAnchor()` */
  static readonly DeleteLocationAnchorPath = '/locations/anchors/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLocationAnchor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteLocationAnchor$Response(params?: DeleteLocationAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteLocationAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLocationAnchor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteLocationAnchor(params?: DeleteLocationAnchor$Params, context?: HttpContext): Observable<void> {
    return this.deleteLocationAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

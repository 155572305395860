import { Component, OnInit } from '@angular/core';
import { faBomb } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'lukla-app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorIcon = faBomb;
  constructor() { }
  ngOnInit(): void {
    //  TODO: Log error/ticket
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { CurrentUserService } from '../lukla/user.service';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ErrorComponent } from './pages/error/error.component';
import { UnknownComponent } from './pages/unknown/unknown.component';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { LuklaModule } from '../lukla/lukla.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SchedulerService } from './scheduler.service';
import { MomentModule } from 'ngx-moment';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { PrettyJsonPipe } from './pretty-print-pipe';
import { SafePipe } from './safe-pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { AssetGroupComponent } from './asset-group/asset-group.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { UploadxModule } from 'ngx-uploadx';
import { WelcomeComponentComponent } from './welcome-component/welcome-component.component';
import { NgPipesModule } from 'ngx-pipes';
import { InventoryImageViewerComponent } from './inventory-image-viewer/inventory-image-viewer.component';
import { InventoryContentComponent } from '../inventory-item/inventory-content/inventory-content.component';
import { UtilitiesModule } from 'projects/ottomate-tasks/utilities/utilities.module';
import { AuthGuard } from './auth/auth-guard';
import { LuklaUserGuard } from './auth/lukla-user-guard';
import { NotificationDrawerComponent } from '../features/notification-page/notification-drawer/notification-drawer.component';

@NgModule({
  declarations: [
    UnauthorizedComponent,
    ErrorComponent,
    UnknownComponent,
    NoAccessComponent,
    HeaderBarComponent,
    PrettyJsonPipe,
    SafePipe,
    AssetGroupComponent,
    WelcomeComponentComponent,
    InventoryImageViewerComponent
  ],
  imports: [
    CommonModule,
    LuklaModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderModule,
    RouterModule,
    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MomentModule,
    NgPipesModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    HighchartsChartModule,
    UtilitiesModule,
    NotificationDrawerComponent


  ],
  exports: [
    CommonModule,
    LuklaModule,
    FontAwesomeModule,
    ContentLoaderModule,
    FormsModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    InfiniteScrollModule,
    HeaderBarComponent,
    BsDatepickerModule,
    BsDropdownModule,
    DragToSelectModule,
    UploadxModule,
    TooltipModule,
    SafePipe,
    AssetGroupComponent,
    MomentModule,
    ModalModule,
    NgPipesModule,
    NgSelectModule,
    ToastrModule,
    PrettyJsonPipe,
    HighchartsChartModule,
    WelcomeComponentComponent,
    InventoryImageViewerComponent
  ],
  providers: [
    InventoryContentComponent,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      providers: [AuthGuard, LuklaUserGuard, CurrentUserService, SchedulerService, BsDropdownConfig, provideNgxMask()
      ],
      ngModule: SharedModule
    };
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from 'src/app/lukla/user.service';
import { faBell, faBoxes, faExclamation, faLayerGroup, faSignOutAlt, faTachometerAlt, faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { ProfileView } from 'src/app/api/models/Lukla/Profile/profile-view';
import { faAnchor, faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons';
import { NotificationCountService } from 'src/app/features/notification-page/notification-count-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'inventory-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private notificationCountService: NotificationCountService

  ) { }

  //  Icons
  logoutIcon = faSignOutAlt;
  assetIcon = faBoxes;
  campaignIcon = faRoute;
  adminIcon = faUserTie;
  dashboardIcon = faTachometerAlt;
  locationsIcon = faLocationDot;
  notificationIcon = faBell;

  //subscirpiton
  unreadCountSubscription:Subscription;
  unreadCount:number =0;
  subscriptions:Subscription[] =[]

  activeIncidents = 0;
  hasActiveIncidents = false;
  isAdmin = false;
  currentUser: ProfileView;

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.notificationCountService.unreadCount$.subscribe(count =>
        this.unreadCount = count)
    );
    this.currentUser = await this.currentUserService.get();
    this.isAdmin = this.currentUserService.isAdmin();
    this.notificationCountService.fetchUnreadCount();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  logout() {
    this.router.navigate(['session', 'end']);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faFilter, faListOl } from '@fortawesome/pro-regular-svg-icons';
import { faCircleNotch, faSearch, faTurkey } from '@fortawesome/pro-solid-svg-icons';
import { TemplateSearchQuery } from '../../utils/template-search-query';

@Component({
  selector: 'task-template-filters',
  templateUrl: './template-filters.component.html',
  styleUrls: ['./template-filters.component.scss']
})
export class TemplateFiltersComponent implements OnInit {

  constructor() { }

  //   Icons
  filterIcon = faFilter;
  searchIcon = faSearch;
  processingIcon = faCircleNotch;

  //  Flags
  isSearching = false;
  showFilters = false;

  //  Filtering
  countIcon = faListOl;
  @Input() filtered = 0;
  @Input() total = 0;
  
  //  Events
  @Output() filterChanged = new EventEmitter<TemplateSearchQuery>();

  //  Model
  currentFilter: TemplateSearchQuery = {};

  ngOnInit(): void {}

  searchChanged(value: string) {
    this.currentFilter.text = value;
    this.filterChanged.emit(this.currentFilter);
  }
}

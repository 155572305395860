/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteScheduledTask } from '../fn/task-schedule/delete-scheduled-task';
import { DeleteScheduledTask$Params } from '../fn/task-schedule/delete-scheduled-task';
import { getItemSchedules } from '../fn/task-schedule/get-item-schedules';
import { GetItemSchedules$Params } from '../fn/task-schedule/get-item-schedules';
import { getLocationSchedules } from '../fn/task-schedule/get-location-schedules';
import { GetLocationSchedules$Params } from '../fn/task-schedule/get-location-schedules';
import { getSchedule } from '../fn/task-schedule/get-schedule';
import { GetSchedule$Params } from '../fn/task-schedule/get-schedule';
import { ScheduleView as LuklaInventoryScheduleView } from '../models/Lukla/Inventory/schedule-view';
import { scheduleTrigger } from '../fn/task-schedule/schedule-trigger';
import { ScheduleTrigger$Params } from '../fn/task-schedule/schedule-trigger';
import { triggerTasks } from '../fn/task-schedule/trigger-tasks';
import { TriggerTasks$Params } from '../fn/task-schedule/trigger-tasks';
import { updateScheduledTask } from '../fn/task-schedule/update-scheduled-task';
import { UpdateScheduledTask$Params } from '../fn/task-schedule/update-scheduled-task';
import { upsertSchedule } from '../fn/task-schedule/upsert-schedule';
import { UpsertSchedule$Params } from '../fn/task-schedule/upsert-schedule';

@Injectable({ providedIn: 'root' })
export class TaskScheduleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `upsertSchedule()` */
  static readonly UpsertSchedulePath = '/tasking/scheduler/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertSchedule()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertSchedule$Response(params?: UpsertSchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertSchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertSchedule$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertSchedule(params?: UpsertSchedule$Params, context?: HttpContext): Observable<void> {
    return this.upsertSchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `triggerTasks()` */
  static readonly TriggerTasksPath = '/tasking/scheduler/trigger/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerTasks$Response(params?: TriggerTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return triggerTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `triggerTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  triggerTasks(params?: TriggerTasks$Params, context?: HttpContext): Observable<void> {
    return this.triggerTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `scheduleTrigger()` */
  static readonly ScheduleTriggerPath = '/tasking/scheduler/schedule/trigger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scheduleTrigger()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleTrigger$Response(params?: ScheduleTrigger$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return scheduleTrigger(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scheduleTrigger$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  scheduleTrigger(params?: ScheduleTrigger$Params, context?: HttpContext): Observable<void> {
    return this.scheduleTrigger$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSchedule()` */
  static readonly GetSchedulePath = '/tasking/scheduler/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchedule()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedule$Response(params: GetSchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryScheduleView>> {
    return getSchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchedule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedule(params: GetSchedule$Params, context?: HttpContext): Observable<LuklaInventoryScheduleView> {
    return this.getSchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryScheduleView>): LuklaInventoryScheduleView => r.body)
    );
  }

  /** Path part for operation `getItemSchedules()` */
  static readonly GetItemSchedulesPath = '/tasking/scheduler/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemSchedules$Response(params: GetItemSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryScheduleView>>> {
    return getItemSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItemSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemSchedules(params: GetItemSchedules$Params, context?: HttpContext): Observable<Array<LuklaInventoryScheduleView>> {
    return this.getItemSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryScheduleView>>): Array<LuklaInventoryScheduleView> => r.body)
    );
  }

  /** Path part for operation `getLocationSchedules()` */
  static readonly GetLocationSchedulesPath = '/tasking/scheduler/location/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationSchedules$Response(params: GetLocationSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryScheduleView>>> {
    return getLocationSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocationSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationSchedules(params: GetLocationSchedules$Params, context?: HttpContext): Observable<Array<LuklaInventoryScheduleView>> {
    return this.getLocationSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryScheduleView>>): Array<LuklaInventoryScheduleView> => r.body)
    );
  }

  /** Path part for operation `deleteScheduledTask()` */
  static readonly DeleteScheduledTaskPath = '/tasking/scheduler/tasks/{scheduledTaskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteScheduledTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScheduledTask$Response(params: DeleteScheduledTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteScheduledTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteScheduledTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScheduledTask(params: DeleteScheduledTask$Params, context?: HttpContext): Observable<void> {
    return this.deleteScheduledTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateScheduledTask()` */
  static readonly UpdateScheduledTaskPath = '/tasking/scheduler/tasks/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScheduledTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateScheduledTask$Response(params?: UpdateScheduledTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateScheduledTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateScheduledTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateScheduledTask(params?: UpdateScheduledTask$Params, context?: HttpContext): Observable<void> {
    return this.updateScheduledTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

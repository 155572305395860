<div class="modal-body">
    <div class="popover-intro">
        <h3 class="display-4">
            <fa-icon [icon]="headerIcon"></fa-icon> Select Task Action
        </h3>
        <p class="lead">Select an action to add to the workflow below</p>
    </div>
    <task-select-action (selectedAction)="onSelectAction($event)"></task-select-action>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-success" (click)="select()" [disabled]="noActionSelected()"> 
        <fa-icon [icon]="selectIcon"></fa-icon> Select Action
    </button>
</div>
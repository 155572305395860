/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assignAreas } from '../fn/area/assign-areas';
import { AssignAreas$Params } from '../fn/area/assign-areas';
import { getAreas } from '../fn/area/get-areas';
import { GetAreas$Params } from '../fn/area/get-areas';
import { AreaView as LuklaInventoryAreaView } from '../models/Lukla/Inventory/area-view';
import { technicianAreas } from '../fn/area/technician-areas';
import { TechnicianAreas$Params } from '../fn/area/technician-areas';
import { upsertArea } from '../fn/area/upsert-area';
import { UpsertArea$Params } from '../fn/area/upsert-area';

@Injectable({ providedIn: 'root' })
export class AreaService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAreas()` */
  static readonly GetAreasPath = '/areas/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAreas$Response(params?: GetAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryAreaView>>> {
    return getAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAreas(params?: GetAreas$Params, context?: HttpContext): Observable<Array<LuklaInventoryAreaView>> {
    return this.getAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryAreaView>>): Array<LuklaInventoryAreaView> => r.body)
    );
  }

  /** Path part for operation `upsertArea()` */
  static readonly UpsertAreaPath = '/areas/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertArea()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertArea$Response(params?: UpsertArea$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertArea(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertArea$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertArea(params?: UpsertArea$Params, context?: HttpContext): Observable<void> {
    return this.upsertArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `technicianAreas()` */
  static readonly TechnicianAreasPath = '/areas/technician/assigned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `technicianAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicianAreas$Response(params?: TechnicianAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryAreaView>>> {
    return technicianAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `technicianAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicianAreas(params?: TechnicianAreas$Params, context?: HttpContext): Observable<Array<LuklaInventoryAreaView>> {
    return this.technicianAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryAreaView>>): Array<LuklaInventoryAreaView> => r.body)
    );
  }

  /** Path part for operation `assignAreas()` */
  static readonly AssignAreasPath = '/areas/technician/assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignAreas()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAreas$Response(params?: AssignAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assignAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignAreas$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAreas(params?: AssignAreas$Params, context?: HttpContext): Observable<void> {
    return this.assignAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

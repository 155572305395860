/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createLocation } from '../fn/location/create-location';
import { CreateLocation$Params } from '../fn/location/create-location';
import { getLocation } from '../fn/location/get-location';
import { GetLocation$Params } from '../fn/location/get-location';
import { getLocationItems } from '../fn/location/get-location-items';
import { GetLocationItems$Params } from '../fn/location/get-location-items';
import { getLocationTasks } from '../fn/location/get-location-tasks';
import { GetLocationTasks$Params } from '../fn/location/get-location-tasks';
import { getLsdSpatialData } from '../fn/location/get-lsd-spatial-data';
import { GetLsdSpatialData$Params } from '../fn/location/get-lsd-spatial-data';
import { ItemTaskView as LuklaInventoryItemTaskView } from '../models/Lukla/Inventory/item-task-view';
import { LocationItem as LuklaInventoryLocationItem } from '../models/Lukla/Inventory/location-item';
import { LocationSearchResponse as LuklaInventoryLocationSearchResponse } from '../models/Lukla/Inventory/location-search-response';
import { SkeletonInventoryView as LuklaInventorySkeletonInventoryView } from '../models/Lukla/Inventory/skeleton-inventory-view';
import { searchIdentification } from '../fn/location/search-identification';
import { SearchIdentification$Params } from '../fn/location/search-identification';
import { searchLocations } from '../fn/location/search-locations';
import { SearchLocations$Params } from '../fn/location/search-locations';
import { updateAccessGroups } from '../fn/location/update-access-groups';
import { UpdateAccessGroups$Params } from '../fn/location/update-access-groups';
import { updateLocationArea } from '../fn/location/update-location-area';
import { UpdateLocationArea$Params } from '../fn/location/update-location-area';
import { updateLocationDivision } from '../fn/location/update-location-division';
import { UpdateLocationDivision$Params } from '../fn/location/update-location-division';

@Injectable({ providedIn: 'root' })
export class LocationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createLocation()` */
  static readonly CreateLocationPath = '/locations/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLocation$Response(params?: CreateLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createLocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLocation(params?: CreateLocation$Params, context?: HttpContext): Observable<string> {
    return this.createLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `updateLocationArea()` */
  static readonly UpdateLocationAreaPath = '/locations/area';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLocationArea()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocationArea$Response(params?: UpdateLocationArea$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateLocationArea(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLocationArea$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocationArea(params?: UpdateLocationArea$Params, context?: HttpContext): Observable<void> {
    return this.updateLocationArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateLocationDivision()` */
  static readonly UpdateLocationDivisionPath = '/locations/division';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLocationDivision()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocationDivision$Response(params?: UpdateLocationDivision$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateLocationDivision(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLocationDivision$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocationDivision(params?: UpdateLocationDivision$Params, context?: HttpContext): Observable<void> {
    return this.updateLocationDivision$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAccessGroups()` */
  static readonly UpdateAccessGroupsPath = '/locations/access-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccessGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccessGroups$Response(params?: UpdateAccessGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateAccessGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccessGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccessGroups(params?: UpdateAccessGroups$Params, context?: HttpContext): Observable<void> {
    return this.updateAccessGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLocation()` */
  static readonly GetLocationPath = '/locations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocation$Response(params: GetLocation$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryLocationItem>> {
    return getLocation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocation(params: GetLocation$Params, context?: HttpContext): Observable<LuklaInventoryLocationItem> {
    return this.getLocation$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryLocationItem>): LuklaInventoryLocationItem => r.body)
    );
  }

  /** Path part for operation `searchIdentification()` */
  static readonly SearchIdentificationPath = '/locations/search/identification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchIdentification()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchIdentification$Response(params?: SearchIdentification$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryLocationItem>> {
    return searchIdentification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchIdentification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchIdentification(params?: SearchIdentification$Params, context?: HttpContext): Observable<LuklaInventoryLocationItem> {
    return this.searchIdentification$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryLocationItem>): LuklaInventoryLocationItem => r.body)
    );
  }

  /** Path part for operation `getLsdSpatialData()` */
  static readonly GetLsdSpatialDataPath = '/locations/lsd/{lsd}/spatial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLsdSpatialData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLsdSpatialData$Response(params: GetLsdSpatialData$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getLsdSpatialData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLsdSpatialData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLsdSpatialData(params: GetLsdSpatialData$Params, context?: HttpContext): Observable<any> {
    return this.getLsdSpatialData$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `searchLocations()` */
  static readonly SearchLocationsPath = '/locations/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchLocations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchLocations$Response(params?: SearchLocations$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryLocationSearchResponse>> {
    return searchLocations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchLocations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchLocations(params?: SearchLocations$Params, context?: HttpContext): Observable<LuklaInventoryLocationSearchResponse> {
    return this.searchLocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryLocationSearchResponse>): LuklaInventoryLocationSearchResponse => r.body)
    );
  }

  /** Path part for operation `getLocationItems()` */
  static readonly GetLocationItemsPath = '/locations/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationItems$Response(params: GetLocationItems$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventorySkeletonInventoryView>>> {
    return getLocationItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocationItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationItems(params: GetLocationItems$Params, context?: HttpContext): Observable<Array<LuklaInventorySkeletonInventoryView>> {
    return this.getLocationItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventorySkeletonInventoryView>>): Array<LuklaInventorySkeletonInventoryView> => r.body)
    );
  }

  /** Path part for operation `getLocationTasks()` */
  static readonly GetLocationTasksPath = '/locations/{id}/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationTasks$Response(params: GetLocationTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryItemTaskView>>> {
    return getLocationTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocationTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationTasks(params: GetLocationTasks$Params, context?: HttpContext): Observable<Array<LuklaInventoryItemTaskView>> {
    return this.getLocationTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryItemTaskView>>): Array<LuklaInventoryItemTaskView> => r.body)
    );
  }

}

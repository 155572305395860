import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskTemplateStatus } from '../../utils/task-template-status';
import { TaskingService } from '../../../api/services/tasking.service';
import { TaskTemplateVersionItem } from '../../../api/models/Lukla/Inventory/task-template-version-item';

@Component({
  selector: 'task-template-versions',
  templateUrl: './template-versions.component.html',
  styleUrls: ['./template-versions.component.scss']
})
export class TemplateVersionsComponent implements OnInit {

  constructor(
    private readonly taskingService: TaskingService
  ) { }

  @Input() taskTemplateId: string;
  @Output() changeVersion = new EventEmitter<TaskTemplateVersionItem>();

  //  Flags
  loaded = false;

  //  Model
  versions : any[];

  async ngOnInit(): Promise<void> {
    this.versions = await this.taskingService.getVersions({taskTemplateId: this.taskTemplateId}).toPromise();
    this.versions[0].selected = true;
  }

    //  Status Helpers
    statusClass = (status: any) => {
      switch (<TaskTemplateStatus>status) {
        case TaskTemplateStatus.New: return 'bg-primary';
        case TaskTemplateStatus.Draft: return 'bg-primary';
        case TaskTemplateStatus.Active: return 'bg-success';
        case TaskTemplateStatus.Archived: return 'bg-danger';
      }
    }
  
    statusText = (status: any) => status.toString();  

    selectVersion(version: any) {
      this.versions.forEach(v => v.selected = false);
      version.selected = true;
      this.changeVersion.emit(version);
    }
}

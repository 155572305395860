import { ModuleWithProviders, NgModule } from '@angular/core';
import { OttoTasksConfig } from './ottotasks.config';
import { OttoTasksApiModule } from '../api/otto-tasks-api.module';
import { ApiConfiguration } from '../api/api-configuration';

@NgModule({
  declarations:[],
  imports: [OttoTasksApiModule.forRoot({rootUrl: ''})],
  providers: []
})
export class OttoTasksCoreModule {
  public static forRoot(options: OttoTasksConfig): ModuleWithProviders<OttoTasksCoreModule> {
    return { 
      ngModule: OttoTasksCoreModule, 
      providers: [
        {provide: OttoTasksConfig, useValue: options},
        { provide: ApiConfiguration, useValue: {rootUrl: options.apiRootUrl} },
       ]};
  }
 }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';

@Component({
  selector: 'task-define-photo',
  templateUrl: './define-photo.component.html',
  styleUrls: ['./define-photo.component.scss']
})
export class DefinePhotoComponent implements OnInit {
  
  constructor() { }

  headerIcon = faImage;

  @Input() isEditable = true;
  @Input() step : TaskTemplateStep;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();

  tinymceinit = {
    menubar: false,
    plugins: 'lists',
    height: 200,
    content_style: "body {font-size: 11pt;}",
    toolbar: 'undo redo | bold italic | numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent'
  }

  ngOnInit(): void {
    if (this.step && !this.step.actionDefinition) {
      this.step.actionDefinition = {};
    }
  }
}

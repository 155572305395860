import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UserComponent } from 'projects/ottomate-tasks/lukla/user/user.component';
import { faBellSlash, faMagnifyingGlass, faFlag as fasFlag } from '@fortawesome/pro-solid-svg-icons';
import { faCircleNotch, faEnvelopeCircleCheck,faSquareArrowUpRight, faTrashAlt, faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NotificationLogicService } from '../../notification-logic-service';
import { SubscriptionEventsView } from 'src/app/api/models/Lukla/Subscriptions/subscription-events-view';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmationModalComponent } from 'projects/ottomate-tasks/utilities/confirmation-modal/confirmation-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilitiesModule } from 'projects/ottomate-tasks/utilities/utilities.module';
import { MomentModule } from 'ngx-moment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NotificationQuery } from '../../paging.model';
import { NotificationCountService } from '../../notification-count-service';
import { Subscription } from 'rxjs';



@Component({
  standalone: true,
  imports: [CommonModule, UserComponent, FontAwesomeModule, RouterModule, ToastrModule, FormsModule, ContentLoaderModule, TooltipModule, UtilitiesModule, MomentModule, InfiniteScrollModule],
  providers: [NotificationLogicService],
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnDestroy, OnInit {

  private readonly notificationLogicService = inject(NotificationLogicService);
  private readonly notificationCountService = inject(NotificationCountService)
  private readonly bsmodalService = inject(BsModalService);



  @Output() notificationDetails = new EventEmitter<SubscriptionEventsView>();
  @Input() loaded = false

  //models
  listUpdateSubscription:Subscription;
  notifications: SubscriptionEventsView[] = []
  filteredNotifications: SubscriptionEventsView[] = [];
  originalNotifications: SubscriptionEventsView[] = [];
  searchNotifications: SubscriptionEventsView[] = [];
  bsModalRef: BsModalRef
  allNotifications: SubscriptionEventsView[] = [];


 // paging
  searchTerm =''
  filtered: number = 0;
  total: number = 0;
  page = 1
  take = 25
  currentQuery: NotificationQuery = {
    page: this.page,
    take: this.take
  }
  
  //flags
  isScrolling = false;
  isRead: boolean;
  isFlagged: boolean;
  showFlagOnly = false;
  isAllDataLoaded: boolean = false;
  isSaving = false;

  //icons
  flagIconRegular = farFlag;
  flagIconSolid = fasFlag;
  readAllIcon = faEnvelopeCircleCheck;
  deleteIcon = faTrashAlt;
  noNotificationsIcon = faBellSlash;
  searchIcon = faMagnifyingGlass;
  goToIcon = faSquareArrowUpRight;
  processIcon = faCircleNotch;



  // #region loading logic
  async ngOnInit(): Promise<void> {
    this.listUpdateSubscription = this.notificationCountService.listUpdate$
    .subscribe(() =>{this.load();} )
    this.loaded = false;
    await this.load();
    this.loaded = true;
  }

  async load() {
    await this.loadNotifications();

  }

  async loadNotifications() {
    const response = await this.notificationLogicService.getNotifications()
    this.allNotifications = response;
    await this.getNotifications();

  }

  async getNotifications() {
    try {
      let filteredNotifications = this.showFlagOnly
        ? this.allNotifications.filter(notification => notification.flagged)
        : [...this.allNotifications];
      this.notifications = filteredNotifications;
      this.total = this.notifications.length;
      this.filtered = this.notifications.length;
    }catch(err){}
  }

  ngOnDestroy(): void {
    this.notificationLogicService.notificationChanged.unsubscribe();
    if(this.listUpdateSubscription){
      this.listUpdateSubscription.unsubscribe();
    }
  }
  // #endregion

  async search(searchTerm: string) {
    if (this.allNotifications.length === 0) {
      this.allNotifications = [...this.notifications];
    }
    if (searchTerm) {
      this.notifications = this.allNotifications.filter(notification =>
        notification.eventTitle.toUpperCase().includes(searchTerm.toUpperCase()) ||
        notification.formattedEvent.toUpperCase().includes(searchTerm.toUpperCase())
      );
      this.filtered = this.notifications.length
      this.total = this.notifications.length;
    } else {
      this.notifications = [...this.allNotifications];
      this.filtered = this.allNotifications.length
      this.total = this.allNotifications.length;
    }
  }


  async onScroll() {
    if (this.isScrolling || this.isAllDataLoaded) return
    this.isScrolling = true;
    this.loaded = false;
    this.currentQuery.page = this.currentQuery.page + 1;
    const newItems = await this.notificationLogicService.getNotificationsPaging(this.currentQuery);
    if (newItems.length === 0) {
      this.isAllDataLoaded = true;
    }
    this.notifications = [...this.notifications, ...newItems];
    this.filtered = this.notifications.length;
    this.total = this.notifications.length;
    this.isScrolling = false;
    this.loaded = true
  }

  // #region notification number logic
  unreadNotificationsExist() {
    return this.notificationLogicService.unreadNotificationsExist();
  }
  getUnreadCount(): number {
    return this.notifications.filter(notification => !notification.read).length;
  }
  //#endregion

  //#region header buttons
  deleteAllNotifications() {
    this.bsModalRef = this.bsmodalService.show(ConfirmationModalComponent, { initialState: { title: 'Delete All Notifications', confirmText: 'Are you sure you want to delete all notifications?' },ignoreBackdropClick: true});
    this.bsModalRef.content.confirmed.subscribe(async () => {
      this.isSaving = true;
      this.notificationLogicService.deleteAllNotifications();
      this.isSaving=false;
      this.notificationLogicService.notificationChanged.subscribe(async () => { this.load(); })
    })
  }

  markAllAsRead() {
    this.bsModalRef = this.bsmodalService.show(ConfirmationModalComponent, { initialState: { title: 'Mark All Notifications As Read ', confirmText: 'Are you sure you want to mark all notifications as read?' },ignoreBackdropClick: true});
    this.bsModalRef.content.confirmed.subscribe(async () => {
      this.notifications.forEach(notification => {
        if (!notification.read) {
          this.isSaving = true;
          notification.read = true;
          this.notificationLogicService.setRead(notification);
          this.isSaving = false;
          this.notificationLogicService.notificationChanged.subscribe(async () => {
            this.load();
          })
        }
      });
    })
  }

  toggleShowFlagged() {
    this.showFlagOnly = !this.showFlagOnly;
    if (this.showFlagOnly) {
      this.originalNotifications = [...this.notifications];
      this.notifications = this.notifications?.filter(notification => notification.flagged);
      this.filtered = this.notifications?.length;
    } else {
      this.getNotifications();
      this.notifications = [...this.originalNotifications];
      this.filtered = this.notifications?.length
    }
  }
  //#endregion

  //#region notification logic
  async toggleFlagged(id: string) {
    let notification = this.notifications.find(notification => notification.id === id);
    notification.flagged = !notification.flagged;
    this.notificationLogicService.toggleFlagged(notification);
  }

  async setRead(id: string) {
    let notification = this.notifications.find(notification => notification.id === id);
    if (!notification.read) {
      notification.read = true;
      this.notificationLogicService.setRead(notification);

    }
  }

  async deleteNotification(id: string) {

    let notification = this.notifications.find(notification => notification.id === id);
    this.bsModalRef = this.bsmodalService.show(ConfirmationModalComponent, { initialState: { title: 'Delete Notification', confirmText: 'Are you sure you want to delete this notification?' },ignoreBackdropClick: true });
    this.bsModalRef.content.confirmed.subscribe(async () => {
      this.isSaving = true;
      this.notificationLogicService.deleteNotification(notification);
      this.isSaving = false;
      this.notificationLogicService.notificationChanged.subscribe(async () => {
        this.load();

      })
    });
  }

  routeToEvent(id: string) {
    this.notificationLogicService.routeToEvent(id, this.notifications)
  }

  getEventIcon(notification: SubscriptionEventsView) {
    if (this.loaded) {
     return this.notificationLogicService.getEventIcon(notification);
    }
  }

  //#endregion

}
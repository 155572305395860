import { Component, OnInit } from '@angular/core';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { ProfileView } from 'src/app/api/models/Lukla/Profile/profile-view';
import { Item as CompanyItem } from 'src/app/api/models/Lukla/Profile/GetCompanies/item';
import { CurrentUserService } from 'src/app/lukla/user.service';
import { ProfileService } from 'src/app/api/services/profile.service';

@Component({
  selector: 'lukla-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

  constructor(
    private currentUserService: CurrentUserService,
    private profileService: ProfileService
  ) { }

  noAccessIcon = faBan;

  isReady = false;
  currentUser: ProfileView;
  companies: CompanyItem[];
  currentCompany: CompanyItem;

  async ngOnInit() {
    this.currentUser = await this.currentUserService.get();
    const companyResponse = await this.profileService.getCompanies().toPromise();
    this.companies = companyResponse.items!;
    this.currentCompany = <CompanyItem>this.companies.find(x => x.id === this.currentUser.companyId);
    this.isReady = true;
  }

  changeCompany = async (c: CompanyItem) => await this.currentUserService.changeCompany(c);
}

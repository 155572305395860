/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { indexRemoveItemIdDelete } from '../fn/index/index-remove-item-id-delete';
import { IndexRemoveItemIdDelete$Params } from '../fn/index/index-remove-item-id-delete';
import { indexRemoveItemsDelete } from '../fn/index/index-remove-items-delete';
import { IndexRemoveItemsDelete$Params } from '../fn/index/index-remove-items-delete';
import { indexUpsertEntirePost } from '../fn/index/index-upsert-entire-post';
import { IndexUpsertEntirePost$Params } from '../fn/index/index-upsert-entire-post';
import { indexUpsertItemIdPost } from '../fn/index/index-upsert-item-id-post';
import { IndexUpsertItemIdPost$Params } from '../fn/index/index-upsert-item-id-post';
import { indexUpsertItemsPost } from '../fn/index/index-upsert-items-post';
import { IndexUpsertItemsPost$Params } from '../fn/index/index-upsert-items-post';
import { SearchResponse as LuklaInventorySearchResponse } from '../models/Lukla/Inventory/search-response';
import { search } from '../fn/index/search';
import { Search$Params } from '../fn/index/search';

@Injectable({ providedIn: 'root' })
export class IndexService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `indexUpsertItemIdPost()` */
  static readonly IndexUpsertItemIdPostPath = '/index/upsert/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexUpsertItemIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUpsertItemIdPost$Response(params: IndexUpsertItemIdPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return indexUpsertItemIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexUpsertItemIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUpsertItemIdPost(params: IndexUpsertItemIdPost$Params, context?: HttpContext): Observable<void> {
    return this.indexUpsertItemIdPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `indexUpsertItemsPost()` */
  static readonly IndexUpsertItemsPostPath = '/index/upsert/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexUpsertItemsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUpsertItemsPost$Response(params?: IndexUpsertItemsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return indexUpsertItemsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexUpsertItemsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUpsertItemsPost(params?: IndexUpsertItemsPost$Params, context?: HttpContext): Observable<void> {
    return this.indexUpsertItemsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `indexUpsertEntirePost()` */
  static readonly IndexUpsertEntirePostPath = '/index/upsert/entire';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexUpsertEntirePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUpsertEntirePost$Response(params?: IndexUpsertEntirePost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return indexUpsertEntirePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexUpsertEntirePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUpsertEntirePost(params?: IndexUpsertEntirePost$Params, context?: HttpContext): Observable<void> {
    return this.indexUpsertEntirePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `search()` */
  static readonly SearchPath = '/index/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventorySearchResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<LuklaInventorySearchResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventorySearchResponse>): LuklaInventorySearchResponse => r.body)
    );
  }

  /** Path part for operation `indexRemoveItemsDelete()` */
  static readonly IndexRemoveItemsDeletePath = '/index/remove/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexRemoveItemsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexRemoveItemsDelete$Response(params?: IndexRemoveItemsDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return indexRemoveItemsDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexRemoveItemsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexRemoveItemsDelete(params?: IndexRemoveItemsDelete$Params, context?: HttpContext): Observable<void> {
    return this.indexRemoveItemsDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `indexRemoveItemIdDelete()` */
  static readonly IndexRemoveItemIdDeletePath = '/index/remove/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexRemoveItemIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexRemoveItemIdDelete$Response(params: IndexRemoveItemIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return indexRemoveItemIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexRemoveItemIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexRemoveItemIdDelete(params: IndexRemoveItemIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.indexRemoveItemIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkInviteCampaignMembers } from '../fn/campaign-access/bulk-invite-campaign-members';
import { BulkInviteCampaignMembers$Params } from '../fn/campaign-access/bulk-invite-campaign-members';
import { bulkInviteTemplate } from '../fn/campaign-access/bulk-invite-template';
import { bulkInviteTemplate$Any } from '../fn/campaign-access/bulk-invite-template-any';
import { BulkInviteTemplate$Any$Params } from '../fn/campaign-access/bulk-invite-template-any';
import { BulkInviteTemplate$Params } from '../fn/campaign-access/bulk-invite-template';
import { confirmUserAccess } from '../fn/campaign-access/confirm-user-access';
import { ConfirmUserAccess$Params } from '../fn/campaign-access/confirm-user-access';
import { extendCampaignPartner } from '../fn/campaign-access/extend-campaign-partner';
import { ExtendCampaignPartner$Params } from '../fn/campaign-access/extend-campaign-partner';
import { getCampaignPartners } from '../fn/campaign-access/get-campaign-partners';
import { GetCampaignPartners$Params } from '../fn/campaign-access/get-campaign-partners';
import { getCampaignTechnicians } from '../fn/campaign-access/get-campaign-technicians';
import { GetCampaignTechnicians$Params } from '../fn/campaign-access/get-campaign-technicians';
import { CampaignPartnerView as LuklaInventoryCampaignPartnerView } from '../models/Lukla/Inventory/campaign-partner-view';
import { TechnicianView as LuklaInventoryTechnicianView } from '../models/Lukla/Inventory/technician-view';
import { registerCampaignPartner } from '../fn/campaign-access/register-campaign-partner';
import { RegisterCampaignPartner$Params } from '../fn/campaign-access/register-campaign-partner';
import { registerCampaignTechnician } from '../fn/campaign-access/register-campaign-technician';
import { RegisterCampaignTechnician$Params } from '../fn/campaign-access/register-campaign-technician';
import { revokeCampaignPartner } from '../fn/campaign-access/revoke-campaign-partner';
import { RevokeCampaignPartner$Params } from '../fn/campaign-access/revoke-campaign-partner';
import { revokeCampaignTechnician } from '../fn/campaign-access/revoke-campaign-technician';
import { RevokeCampaignTechnician$Params } from '../fn/campaign-access/revoke-campaign-technician';

@Injectable({ providedIn: 'root' })
export class CampaignAccessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCampaignTechnicians()` */
  static readonly GetCampaignTechniciansPath = '/campaigns/access/{id}/technicians';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaignTechnicians()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignTechnicians$Response(params: GetCampaignTechnicians$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTechnicianView>>> {
    return getCampaignTechnicians(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampaignTechnicians$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignTechnicians(params: GetCampaignTechnicians$Params, context?: HttpContext): Observable<Array<LuklaInventoryTechnicianView>> {
    return this.getCampaignTechnicians$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTechnicianView>>): Array<LuklaInventoryTechnicianView> => r.body)
    );
  }

  /** Path part for operation `registerCampaignTechnician()` */
  static readonly RegisterCampaignTechnicianPath = '/campaigns/access/register/technician';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerCampaignTechnician()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerCampaignTechnician$Response(params?: RegisterCampaignTechnician$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerCampaignTechnician(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerCampaignTechnician$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerCampaignTechnician(params?: RegisterCampaignTechnician$Params, context?: HttpContext): Observable<void> {
    return this.registerCampaignTechnician$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revokeCampaignTechnician()` */
  static readonly RevokeCampaignTechnicianPath = '/campaigns/access/revoke/technician';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeCampaignTechnician()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeCampaignTechnician$Response(params?: RevokeCampaignTechnician$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeCampaignTechnician(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeCampaignTechnician$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeCampaignTechnician(params?: RevokeCampaignTechnician$Params, context?: HttpContext): Observable<void> {
    return this.revokeCampaignTechnician$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registerCampaignPartner()` */
  static readonly RegisterCampaignPartnerPath = '/campaigns/access/register/partner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerCampaignPartner()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerCampaignPartner$Response(params?: RegisterCampaignPartner$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerCampaignPartner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerCampaignPartner$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerCampaignPartner(params?: RegisterCampaignPartner$Params, context?: HttpContext): Observable<void> {
    return this.registerCampaignPartner$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCampaignPartners()` */
  static readonly GetCampaignPartnersPath = '/campaigns/access/{id}/partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaignPartners()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignPartners$Response(params: GetCampaignPartners$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryCampaignPartnerView>>> {
    return getCampaignPartners(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampaignPartners$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignPartners(params: GetCampaignPartners$Params, context?: HttpContext): Observable<Array<LuklaInventoryCampaignPartnerView>> {
    return this.getCampaignPartners$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryCampaignPartnerView>>): Array<LuklaInventoryCampaignPartnerView> => r.body)
    );
  }

  /** Path part for operation `revokeCampaignPartner()` */
  static readonly RevokeCampaignPartnerPath = '/campaigns/access/revoke/partner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeCampaignPartner()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeCampaignPartner$Response(params?: RevokeCampaignPartner$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeCampaignPartner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeCampaignPartner$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeCampaignPartner(params?: RevokeCampaignPartner$Params, context?: HttpContext): Observable<void> {
    return this.revokeCampaignPartner$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `extendCampaignPartner()` */
  static readonly ExtendCampaignPartnerPath = '/campaigns/access/extend/partner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extendCampaignPartner()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extendCampaignPartner$Response(params?: ExtendCampaignPartner$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return extendCampaignPartner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extendCampaignPartner$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extendCampaignPartner(params?: ExtendCampaignPartner$Params, context?: HttpContext): Observable<void> {
    return this.extendCampaignPartner$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `confirmUserAccess()` */
  static readonly ConfirmUserAccessPath = '/campaigns/access/{id}/confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmUserAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmUserAccess$Response(params: ConfirmUserAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return confirmUserAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmUserAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmUserAccess(params: ConfirmUserAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.confirmUserAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `bulkInviteTemplate()` */
  static readonly BulkInviteTemplatePath = '/campaigns/access/bulk/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkInviteTemplate$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkInviteTemplate$Any$Response(params?: BulkInviteTemplate$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return bulkInviteTemplate$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkInviteTemplate$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkInviteTemplate$Any(params?: BulkInviteTemplate$Any$Params, context?: HttpContext): Observable<Blob> {
    return this.bulkInviteTemplate$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkInviteTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkInviteTemplate$Response(params?: BulkInviteTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return bulkInviteTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkInviteTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkInviteTemplate(params?: BulkInviteTemplate$Params, context?: HttpContext): Observable<Blob> {
    return this.bulkInviteTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `bulkInviteCampaignMembers()` */
  static readonly BulkInviteCampaignMembersPath = '/campaigns/access/{id}/bulk/invites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkInviteCampaignMembers()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkInviteCampaignMembers$Response(params: BulkInviteCampaignMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkInviteCampaignMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkInviteCampaignMembers$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkInviteCampaignMembers(params: BulkInviteCampaignMembers$Params, context?: HttpContext): Observable<void> {
    return this.bulkInviteCampaignMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

export enum TaskActionEnum {
  Geolocate = "Geolocate",
  AssignTag = "AssignTag",
  Check = "Check",
  Verify = "Verify",
  Photo = "Photo",
  File ="File",
  Comment = "Comment",
  End = "End",
  Inventory = "Inventory",
  SparkHarvest = "SparkHarvest"

}
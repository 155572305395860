import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'welcome-component',
  templateUrl: './welcome-component.component.html',
  styleUrls: ['./welcome-component.component.scss']
})
export class WelcomeComponentComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  //  Icons
  checklistIcon = faSquareCheck;

  ngOnInit(): void {}

  goToAnchors = () => this.router.navigate(['/admin/anchors']);
  goToItems = () => this.router.navigate(['']);
  goToTemplates = () => this.router.navigate(['/admin/templates'])
  goToCampaigns = () => this.router.navigate(['/campaigns'])

}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.router.navigate(['/session/end']);
                return throwError(err);
            } else if (err.status === 403) {
                this.router.navigate(['/unauthorized']);
                return throwError(err);
            } else {
                // const error = err.error ? err.error.error.message : err.statusText;
                return throwError(err);
            }
        }));
    }
}

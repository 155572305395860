/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getIndexCampaign } from '../fn/campaign-index/get-index-campaign';
import { GetIndexCampaign$Params } from '../fn/campaign-index/get-index-campaign';
import { CampaignIndexItem as LuklaInventoryCampaignIndexItem } from '../models/Lukla/Inventory/campaign-index-item';
import { CampaignSearchResponse as LuklaInventoryCampaignSearchResponse } from '../models/Lukla/Inventory/campaign-search-response';
import { populateCampaignIndex } from '../fn/campaign-index/populate-campaign-index';
import { PopulateCampaignIndex$Params } from '../fn/campaign-index/populate-campaign-index';
import { removeCampaignIndex } from '../fn/campaign-index/remove-campaign-index';
import { RemoveCampaignIndex$Params } from '../fn/campaign-index/remove-campaign-index';
import { searchCampaigns } from '../fn/campaign-index/search-campaigns';
import { SearchCampaigns$Params } from '../fn/campaign-index/search-campaigns';
import { upsertCampaignIndexItem } from '../fn/campaign-index/upsert-campaign-index-item';
import { UpsertCampaignIndexItem$Params } from '../fn/campaign-index/upsert-campaign-index-item';

@Injectable({ providedIn: 'root' })
export class CampaignIndexService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `populateCampaignIndex()` */
  static readonly PopulateCampaignIndexPath = '/campaigns/index/index/populate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `populateCampaignIndex()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  populateCampaignIndex$Response(params?: PopulateCampaignIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return populateCampaignIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `populateCampaignIndex$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  populateCampaignIndex(params?: PopulateCampaignIndex$Params, context?: HttpContext): Observable<void> {
    return this.populateCampaignIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeCampaignIndex()` */
  static readonly RemoveCampaignIndexPath = '/campaigns/index/index/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCampaignIndex()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeCampaignIndex$Response(params?: RemoveCampaignIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeCampaignIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeCampaignIndex$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeCampaignIndex(params?: RemoveCampaignIndex$Params, context?: HttpContext): Observable<void> {
    return this.removeCampaignIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `upsertCampaignIndexItem()` */
  static readonly UpsertCampaignIndexItemPath = '/campaigns/index/index/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertCampaignIndexItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertCampaignIndexItem$Response(params?: UpsertCampaignIndexItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertCampaignIndexItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertCampaignIndexItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertCampaignIndexItem(params?: UpsertCampaignIndexItem$Params, context?: HttpContext): Observable<void> {
    return this.upsertCampaignIndexItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `searchCampaigns()` */
  static readonly SearchCampaignsPath = '/campaigns/index/index/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCampaigns()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCampaigns$Response(params?: SearchCampaigns$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryCampaignSearchResponse>> {
    return searchCampaigns(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCampaigns$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCampaigns(params?: SearchCampaigns$Params, context?: HttpContext): Observable<LuklaInventoryCampaignSearchResponse> {
    return this.searchCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryCampaignSearchResponse>): LuklaInventoryCampaignSearchResponse => r.body)
    );
  }

  /** Path part for operation `getIndexCampaign()` */
  static readonly GetIndexCampaignPath = '/campaigns/index/index/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndexCampaign()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndexCampaign$Response(params: GetIndexCampaign$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryCampaignIndexItem>> {
    return getIndexCampaign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIndexCampaign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndexCampaign(params: GetIndexCampaign$Params, context?: HttpContext): Observable<LuklaInventoryCampaignIndexItem> {
    return this.getIndexCampaign$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryCampaignIndexItem>): LuklaInventoryCampaignIndexItem => r.body)
    );
  }

}

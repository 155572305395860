import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faStopCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'task-end-workflow',
  templateUrl: './end-workflow.component.html',
  styleUrls: ['./end-workflow.component.scss']
})
export class EndWorkflowComponent implements OnInit {

  constructor() { }

  @Input() isEditable = true;
  @Output() endRemoved = new EventEmitter();

  stopIcon = faStopCircle;
  removeIcon = faTimes;

  ngOnInit(): void {  }

  onRemove = () => this.endRemoved.emit();
}

<task-template-header [(template)]="template" [(isEditable)]="isEditable" [(showVersions)]="showVersions"
    [canEdit]="true">
    <ng-content></ng-content>
</task-template-header>

<div *ngIf="hasDrafts" class="alert alert-info p-5 mt-3 d-flex">
    <fa-icon class="me-5 display-3" [icon]="draftIcon"></fa-icon>
    <div class="d-flex flex-grow-1 justify-content-between">
        <div>
            <h3>Drafts</h3>
            <p>There are unsaved drafts available that can be continued, click on the draft below to load it</p>
            <ul>
                <li *ngFor="let draft of draftItems">
                    <button class="btn btn-link" (click)="loadDraft(draft)">{{draft.date | amDateFormat:'LLLL'}} -
                        {{draft.template.name}}</button>
                    <button class="btn btn-link text-danger fw-bold text-decoration-none"
                        (click)="discardDraft(draft)"><fa-icon [icon]="removeIcon" class="me-1"></fa-icon>
                        Discard</button>
                </li>
            </ul>
        </div>
        <button type="button" class="btn-close" aria-label="Close" (click)="hasDrafts = false"></button>
    </div>
</div>

<div class="d-flex">
    <div *ngIf="showVersions">
        <task-template-versions [taskTemplateId]="template.taskTemplateId"
            (changeVersion)="changeVersion($event)"></task-template-versions>
    </div>
    <div class="flex-grow-1">
        <template-details [(template)]="template" [isEditable]="isEditable" (templateChange)="templateUpdated($event)"></template-details>
    </div>
</div>
/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deactivateItems } from '../fn/bulk/deactivate-items';
import { DeactivateItems$Params } from '../fn/bulk/deactivate-items';
import { deleteBulkItems } from '../fn/bulk/delete-bulk-items';
import { DeleteBulkItems$Params } from '../fn/bulk/delete-bulk-items';
import { lockItems } from '../fn/bulk/lock-items';
import { LockItems$Params } from '../fn/bulk/lock-items';
import { reactivateItems } from '../fn/bulk/reactivate-items';
import { ReactivateItems$Params } from '../fn/bulk/reactivate-items';
import { unlockItems } from '../fn/bulk/unlock-items';
import { UnlockItems$Params } from '../fn/bulk/unlock-items';
import { updateAreas } from '../fn/bulk/update-areas';
import { UpdateAreas$Params } from '../fn/bulk/update-areas';
import { updateDivisions } from '../fn/bulk/update-divisions';
import { UpdateDivisions$Params } from '../fn/bulk/update-divisions';
import { updateLocations } from '../fn/bulk/update-locations';
import { UpdateLocations$Params } from '../fn/bulk/update-locations';

@Injectable({ providedIn: 'root' })
export class BulkService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateDivisions()` */
  static readonly UpdateDivisionsPath = '/bulk/divisions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDivisions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDivisions$Response(params?: UpdateDivisions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateDivisions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDivisions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDivisions(params?: UpdateDivisions$Params, context?: HttpContext): Observable<void> {
    return this.updateDivisions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAreas()` */
  static readonly UpdateAreasPath = '/bulk/areas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAreas()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAreas$Response(params?: UpdateAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAreas$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAreas(params?: UpdateAreas$Params, context?: HttpContext): Observable<void> {
    return this.updateAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deactivateItems()` */
  static readonly DeactivateItemsPath = '/bulk/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deactivateItems$Response(params?: DeactivateItems$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deactivateItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivateItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deactivateItems(params?: DeactivateItems$Params, context?: HttpContext): Observable<void> {
    return this.deactivateItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reactivateItems()` */
  static readonly ReactivateItemsPath = '/bulk/reactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reactivateItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reactivateItems$Response(params?: ReactivateItems$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reactivateItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reactivateItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reactivateItems(params?: ReactivateItems$Params, context?: HttpContext): Observable<void> {
    return this.reactivateItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `lockItems()` */
  static readonly LockItemsPath = '/bulk/lock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lockItems$Response(params?: LockItems$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return lockItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lockItems(params?: LockItems$Params, context?: HttpContext): Observable<void> {
    return this.lockItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unlockItems()` */
  static readonly UnlockItemsPath = '/bulk/unlock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlockItems$Response(params?: UnlockItems$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unlockItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlockItems(params?: UnlockItems$Params, context?: HttpContext): Observable<void> {
    return this.unlockItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateLocations()` */
  static readonly UpdateLocationsPath = '/bulk/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLocations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocations$Response(params?: UpdateLocations$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateLocations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLocations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocations(params?: UpdateLocations$Params, context?: HttpContext): Observable<void> {
    return this.updateLocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteBulkItems()` */
  static readonly DeleteBulkItemsPath = '/bulk/items/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBulkItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteBulkItems$Response(params?: DeleteBulkItems$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBulkItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBulkItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteBulkItems(params?: DeleteBulkItems$Params, context?: HttpContext): Observable<void> {
    return this.deleteBulkItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

<div class="modal-body">
    <div class="popover-intro">
        <h3 class="display-4">
            <fa-icon [icon]="icon"></fa-icon> {{title}}
        </h3>
        <p class="lead">{{confirmText}}</p>
    </div>
    <div *ngIf="body" [innerHtml]="body"></div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="cancel()">{{closeBtnName}}</button>
    <button type="button" class="btn btn-success" (click)="confirm()">{{confirmBtnName}}</button>
</div>
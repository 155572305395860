import { Component, Input, OnInit } from '@angular/core';
import { TaskTemplate } from '../../../api/models/Lukla/Inventory/task-template';
import { TaskingService } from '../../../api/services/tasking.service';

@Component({
  selector: 'template-readonly',
  templateUrl: './template-readonly.component.html'
})
export class TemplateReadonlyComponent implements OnInit {
  
  constructor(
    private readonly taskingService : TaskingService
  ) { }

  @Input() templateId: string;

  template: TaskTemplate;
  loaded = false;

  isEditable = false;
  showVersions = false;

  async ngOnInit(): Promise<void> {
    this.template = await this.taskingService.getTemplate({id: this.templateId}).toPromise();
    this.loaded = true;
  }
}

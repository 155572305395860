/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { TaskExceptionDefinition as LuklaInventoryTaskExceptionDefinition } from '../models/Lukla/Inventory/task-exception-definition';
import { TaskPhaseView as LuklaInventoryTaskPhaseView } from '../models/Lukla/Inventory/task-phase-view';
import { possibleExceptions } from '../fn/tasking-meta/possible-exceptions';
import { PossibleExceptions$Params } from '../fn/tasking-meta/possible-exceptions';
import { possiblePhases } from '../fn/tasking-meta/possible-phases';
import { PossiblePhases$Params } from '../fn/tasking-meta/possible-phases';

@Injectable({ providedIn: 'root' })
export class TaskingMetaService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `possibleExceptions()` */
  static readonly PossibleExceptionsPath = '/tasking/meta/possible-exceptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `possibleExceptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  possibleExceptions$Response(params?: PossibleExceptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTaskExceptionDefinition>>> {
    return possibleExceptions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `possibleExceptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  possibleExceptions(params?: PossibleExceptions$Params, context?: HttpContext): Observable<Array<LuklaInventoryTaskExceptionDefinition>> {
    return this.possibleExceptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTaskExceptionDefinition>>): Array<LuklaInventoryTaskExceptionDefinition> => r.body)
    );
  }

  /** Path part for operation `possiblePhases()` */
  static readonly PossiblePhasesPath = '/tasking/meta/possible-phases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `possiblePhases()` instead.
   *
   * This method doesn't expect any request body.
   */
  possiblePhases$Response(params?: PossiblePhases$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTaskPhaseView>>> {
    return possiblePhases(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `possiblePhases$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  possiblePhases(params?: PossiblePhases$Params, context?: HttpContext): Observable<Array<LuklaInventoryTaskPhaseView>> {
    return this.possiblePhases$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTaskPhaseView>>): Array<LuklaInventoryTaskPhaseView> => r.body)
    );
  }

}

<div class="container">
    <div class="col-xxl-6 col-xl-8 col-lg-12" style="margin:auto">
        <div class="flow-container flex-column align-items-center">
            <div class="flow flex-column align-items-center mb-5">
                <task-template-definition [(template)]="template" [isEditable]="isEditable"></task-template-definition>
                <div class="separator">
                </div>
                <div class="badge bg-primary">
                    <span class="h3">
                        Start
                    </span>
                </div>
                <div class="separator"></div>
                <div *ngFor="let s of template.sections; index as i">
                    <div class="section">
                        <task-template-define-section [isEditable]="isEditable" [(section)]="template.sections[i]"
                            (sectionDeleted)="deleteSection(i)" (moveSectionDown)="moveSectionDown(i)"
                            (moveSectionUp)="moveSectionUp(i)" (sectionChange)="templateChange.emit(template)">
                        </task-template-define-section>
                    </div>
                    <div class="separator" *ngIf="isEditable || i < template.sections.length-1">
                    </div>
                </div>
                <div class="add-section" *ngIf="isEditable">
                    <button class="btn btn-outline-primary" (click)="addSection()">
                        <fa-icon [icon]="addIcon"></fa-icon> Add Section
                    </button>
                </div>
                <div class="separator"></div>
                <div class="d-flex align-items-center">
                    <div style="margin:auto">
                        <task-end-workflow [isEditable]="false"></task-end-workflow>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

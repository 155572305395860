import { Component, Input, OnInit } from '@angular/core';
import { faExpandWide, faFileDownload, faImagePolaroid, faTimes, faUpload, faQuestionSquare, faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import { ContentService } from 'src/app/api/services/content.service';
import { InventoryItemView } from 'src/app/api/models/Lukla/Inventory/inventory-item-view';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ContentModalComponent } from './content-modal/content-modal.component';
import { ContentView } from 'src/app/shared/inventory-image-viewer/content-model';
import { ToastrService } from 'ngx-toastr';
import { ExportConventionSmartComponent } from 'src/app/features/export-photo-convention/smart/export-convention-smart.component';

@Component({
  selector: 'inventory-content',
  templateUrl: './inventory-content.component.html',
  styleUrls: ['./inventory-content.component.scss'],
})
export class InventoryContentComponent implements OnInit {
  constructor(
    private readonly contentService: ContentService,
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private readonly toastrService: ToastrService,

  ) { }

  //  Inputs/Outputs
  @Input() item: InventoryItemView;

  //  Flags
  hasContent = false;
  canSubmit = false;
  canUpload = true;
  loaded = false;
  expandImages = false;


  //  Model
  selectedImageIndex: number = -1;
  content: ContentView[] = [];

  //  Icons
  headerIcon = faImagePolaroid;
  expandIcon = faExpandWide;
  downloadIcon = faFileDownload;
  uploadIcon = faUpload;
  removeIcon = faTimes;
  noItemsIcon = faQuestionSquare;
  addIcon = faCloudUpload;
  exportIcon = faFileDownload;

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  async load() {
    this.loaded = false
    this.content = await this.contentService.getContent({ inventoryItemId: this.item.id }).toPromise();
    this.hasContent = this.content.length > 0;
    this.loaded = true;
  }

  onContentUpload() {
    const initialState = {
      item: this.item,
    };
    this.bsModalRef = this.bsModalService.show(ContentModalComponent, {
      initialState,
      class: 'modal-lg',
    });
    this.bsModalRef.content.confirmed.subscribe(async () => {
      await this.load();
    });
  }



  async remove(content: ContentView) {
    try{
      await this.contentService.removeContent({ inventoryContentItemId: content.id }).toPromise();
      this.toastrService.success('Content Removed');
      await this.load();
    }catch(err){
      this.toastrService.error('Please try again or contact Lukla support','Something Went Wrong');
    }
  }

  async update(content:ContentView){
    const initialState = {
      content: content,
      item: this.item,
      isUpdating: true,
    };
    this.bsModalRef = this.bsModalService.show(ContentModalComponent, {
      initialState,
      class: 'modal-lg',
    });
    this.bsModalRef.content.confirmed.subscribe(async () => {
     await this.load();
    });

  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';

@Component({
  selector: 'task-define-geolocation',
  templateUrl: './define-geolocation.component.html',
  styleUrls: ['./define-geolocation.component.scss']
})
export class DefineGeolocationComponent implements OnInit {

  constructor() { }

  headerIcon = faLocationDot;

  @Input() isEditable = true;
  @Input() step : TaskTemplateStep;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();

  ngOnInit(): void {
    if (this.step && !this.step.actionDefinition) {
      this.step.actionDefinition = {};
    }
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'lukla-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}

  title: string;
  closeBtnName: string = "Cancel";
  confirmBtnName: string = "Confirm";
  confirmText: string;
  body: string;
  color: string;
  icon: any;

  @Output() confirmed = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  ngOnInit(): void {
    this.icon = this.icon ?? faCheck;
    this.color = this.color ?? 'success';
  }

  confirm() {
    this.confirmed.emit();
    this.bsModalRef.hide();
  }

  cancel() {
    this.cancelled.emit();
    this.bsModalRef.hide();
  }

}

<div class="section-header was-validated has-validation">
    <div id="collapseBasic">
        <div *ngIf="!section.name && isEditable" class="alert alert-danger">
            <fa-icon [icon]="newIcon"></fa-icon>
            <span class="ms-2 lead">Please enter in the name for this section (Required)</span>
        </div>
        <div class="mb-3 d-flex justify-content-between">
            <div class="flex-grow-1 me-2">
                <h3 *ngIf="!editingName || !isEditable" class="field" [class.editable]="isEditable"
                    (click)="editName()">
                    {{section.name}}</h3>
                <input *ngIf="editingName && isEditable" type="text" class="form-control" [(ngModel)]="section.name"
                    (blur)="blurName()" (ngModelChange)="sectionChange.emit(section)" required>
            </div>
            <div *ngIf="isEditable">
                <div *ngIf="!confirmDelete" class="btn btn-outline-danger" (click)="confirmingDelete()">
                    <fa-icon [icon]="deleteIcon"></fa-icon> Delete Section
                </div>
                <div *ngIf="confirmDelete" class="btn btn-danger" (click)="deleteSection()">
                    <fa-icon [icon]="deleteIcon"></fa-icon> Confirm & Delete Section
                </div>
                <button class="ms-2 btn btn-outline-primary" tooltip="Move section up" (click)="moveup()">
                    <fa-icon [icon]="upIcon"></fa-icon>
                </button>
                <button class="ms-2 btn btn-outline-primary" tooltip="Move section down" (click)="movedown()">
                    <fa-icon [icon]="downIcon"></fa-icon>
                </button>
            </div>
            <div class="ms-2">
                <button type="button" tooltip="{{this.tooltipText}}" class="btn btn-primary" (click)="toggleCollapse()"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"><fa-icon
                        [icon]="isCollapsed ? expandIcon : collapseIcon"></fa-icon>
                </button>
            </div>
        </div>
        <div [collapse]="isCollapsed" [isAnimated]="true">
            <div class="mb-3">
                <p *ngIf="!editingDescription || !isEditable" class="field" [class.editable]="isEditable"
                    (click)="editDescription()" [innerHtml]="section.description"></p>
                <div *ngIf="!section.description && isEditable" class="alert alert-info">
                    <fa-icon [icon]="newIcon"></fa-icon>
                    <span class="ms-2 lead">Please enter in the description for the purpose of this section (Not
                        Required)</span>
                </div>
                <editor *ngIf="editingDescription && isEditable"
                    apiKey="s9mgynkayyur88nt7gtwowcbjchlfbb4x8cj554p1w8k3kmr"
                    (ngModelChange)="sectionChange.emit(section)" [init]="tinymceinit" [(ngModel)]="section.description"
                    (onBlur)="blurDescription()"></editor>
            </div>
            <div class="mb-3">
                <h4 class="mt-3">Steps/Actions</h4>
                <div class="flow-container flex-column align-items-center">
                    <div class="flow flex-column align-items-center">
                        <div *ngFor="let step of section.steps; let i = index">
                            <task-define-step [isEditable]="isEditable" [(step)]="section.steps[i]"
                                (stepDeleted)="deleteStep(i)" (moveStepDown)="moveStepDown(i)"
                                (moveStepUp)="moveStepUp(i)" (stepChange)="sectionChange.emit(section)">
                            </task-define-step>
                            <div class="separator" *ngIf="isEditable || i < section.steps.length-1">
                            </div>
                        </div>
                        <div class="add-step" *ngIf="isEditable && canAddStep()">
                            <div *ngIf="section.steps.length === 0" class="alert alert-danger">
                                Please add at least one step to the section
                            </div>
                            <button class="btn btn-outline-primary" (click)="addStep()">
                                <fa-icon [icon]="addIcon"></fa-icon> Add Step
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from "@angular/core";
import { TaskTemplate } from "../../api/models/Lukla/Inventory/task-template";
import { BehaviorSubject, lastValueFrom, take } from "rxjs";
import { AvailableActionItem } from "../utils/available-action-item.modal";
import { TaskActionEnum } from "../utils/task-actions-enum.model";
import { TemplateDraft } from "../utils/template-draft-model";
import { TaskTemplateSection } from "../../api/models/Lukla/Inventory/task-template-section";
import { TaskTargetEnum } from "../../api/models/Lukla/Inventory/task-target-enum";
import { TaskTemplateStep } from "../../api/models/Lukla/Inventory/task-template-step";
import { TaskTemplateExceptionDefinition } from "../../api/models/Lukla/Inventory/task-template-exception-definition";

@Injectable({
    providedIn: 'root'
})
export class TemplateUpdateService {

    private currentDrafts: TemplateDraft[];

    private _currentTemplate = new BehaviorSubject<TaskTemplate>({});
    currentTemplate = this._currentTemplate.asObservable();

    constructor() { }

    load = (template: TaskTemplate) => this._currentTemplate.next(template);
    loadDrafts() {
        this.currentDrafts = JSON.parse(localStorage["template_drafts"] || '[]');
        return this.currentDrafts;
    }

    discardDraft(id: string) {
        if (!id) return;
        var items = JSON.parse(localStorage["template_drafts"] || '[]');
        items = items.filter(x => x.id !== id);
        localStorage.setItem("template_drafts", JSON.stringify(items));
        this.currentDrafts = items;
    }

    saveDraft(template: TaskTemplate) {
        //  save object to local storage
        if (!template.name) return;

        (<any>template).draftId = (<any>template).draftId || Date.now().toString();

        var draft: TemplateDraft = {
            id: (<any>template).draftId,
            isNew: template.id ? false : true,
            template: template,
            date: Date.now()
        };
        var items = JSON.parse(localStorage["template_drafts"] || '[]');
        var draftItem = items.find(x => x.id == draft.id);
        if (!draftItem) {
            items.push(draft);
        } else {
            draftItem.template = template
        }
        localStorage.setItem("template_drafts", JSON.stringify(items));
    }

    async getAvailableActions(): Promise<AvailableActionItem[]> {
        const currentTemplate = await lastValueFrom(this.currentTemplate.pipe(take(1)));
        const actions = Object.values(TaskActionEnum);
        const availableActions: AvailableActionItem[] = [];

        actions.forEach(x => {
            const item = <AvailableActionItem>{
                action: x,
                isAvailable: true
            };
            if (x === TaskActionEnum.Inventory) {
                const targetsItem = currentTemplate.targets?.includes(TaskTargetEnum.Item);
                if (targetsItem) {
                    item.isAvailable = false;
                    item.message = "This template targets an item so the inventory action cannot be included";
                }

                const alreadyHasInventory = currentTemplate.sections.some(x => x.steps.some(s => s.actionType === <any>TaskActionEnum.Inventory));
                if (alreadyHasInventory) {
                    item.isAvailable = false;
                    item.message = "This template already contains an inventory step, another one cannot be added";
                }
            }

            availableActions.push(item);
        });

        return availableActions;
    }

    validate(template: TaskTemplate): any {
        const validationResponse = {
            isValid: true,
            validationErrors: []
        }

        //  Check Definition
        if (!template.name) {
            validationResponse.validationErrors.push('The template must have a name');
        }

        if (!template.targets || template.targets?.length === 0) {
            validationResponse.validationErrors.push('The template needs to target at least one of Item or Location');
        }

        //  Check Sections
        let sectionIndex = 1;
        template.sections.forEach(section => {
            const sectionErrors = this.validateSection(section, sectionIndex);
            validationResponse.validationErrors = [...validationResponse.validationErrors, ...sectionErrors];
            sectionIndex++;
        });

        validationResponse.isValid = validationResponse.validationErrors.length === 0;
        return validationResponse;
    }

    validateSection(section: TaskTemplateSection, sectionIndex: number): string[] {
        var validationErrors = [];

        let sectionName = section.name || `at index ${sectionIndex}`;
        if (!section.name) {
            validationErrors.push(`Section ${sectionIndex} is missing a name`);
        }

        if (section.steps.length === 0) {
            validationErrors.push(`Section '${sectionName}' is missing steps, sections require at least one step`);
        }

        let stepIndex = 1;
        section.steps.forEach(step => {
            const stepErrors = this.validateStep(step, sectionName, stepIndex);
            validationErrors = [...validationErrors, ...stepErrors];
            stepIndex++;
        });

        return validationErrors;
    }

    validateStep(step: TaskTemplateStep, sectionName: string, stepIndex: number): string[] {
        var validationErrors = [];

        //  Question Validation
        if (step.actionType.toString() === TaskActionEnum.Check) {
            if (!step.actionDefinition.question) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Question Action) is missing a question statement`);
            }
            if (!step.actionDefinition.yesText) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Question Action) is missing the value for a 'Yes' response`);
            }
            if (!step.actionDefinition.noText) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Question Action) is missing the value for a 'No' response`);
            }
        }

        //  Comment Validation
        if (step.actionType.toString() === TaskActionEnum.Comment) {
            if (!step.actionDefinition.statement) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Comment Action) is missing a comment statement`);
            }
        }

        //  File Validation
        if (step.actionType.toString() === TaskActionEnum.File) {
            if (!step.actionDefinition.category) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (File Upload Action) is missing a category for the file`);
            }
        }

        //  Photo Validation
        if (step.actionType.toString() === TaskActionEnum.Photo) {
            if (!step.actionDefinition.category) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Photo Upload Action) is missing a category for the photo`);
            }
        }

        //  Verify Validation
        if (step.actionType.toString() === TaskActionEnum.Verify) {
            if (!step.actionDefinition.anchorId) {
                if (!step.actionDefinition.name) {
                    validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Verify Action) is missing a name for the adhoc data definition`);
                }
                if (!step.actionDefinition.dataType) {
                    validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Verify Action) is missing a data type for the adhoc data definition`);
                }
                if (step.actionDefinition.dataType === 'Choice' && step.actionDefinition.choices.length === 0) {
                    validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Verify Action) is a choice definition and there are no choices`);
                }
            }
        }

        (step.exceptions || []).forEach(exception => {
            const conditionalErrors = this.validateConditional(exception.condition, step, sectionName, stepIndex);
            validationErrors = [...validationErrors, ...conditionalErrors];

            const exceptionErrors = this.validateException(exception, sectionName, stepIndex);
            validationErrors = [...validationErrors, ...exceptionErrors];
        });

        let conditionalStepIndex = 1;
        (step.conditionalSteps || []).forEach(conditionalStep => {
            const conditionalErrors = this.validateConditional(conditionalStep.condition, step, sectionName, stepIndex);
            validationErrors = [...validationErrors, ...conditionalErrors];

            const stepErrors = this.validateStep(conditionalStep.step, sectionName, conditionalStepIndex);
            validationErrors = [...validationErrors, ...stepErrors];
            conditionalStepIndex++;
        });

        let conditionalSectionIndex = 1;
        (step.conditionalSections || []).forEach(conditionalSection => {
            const conditionalErrors = this.validateConditional(conditionalSection.condition, step, sectionName, stepIndex);
            validationErrors = [...validationErrors, ...conditionalErrors];

            const sectionErrors = this.validateSection(conditionalSection.section, conditionalSectionIndex);
            validationErrors = [...validationErrors, ...sectionErrors];
            conditionalSectionIndex++;
        });

        return validationErrors;
    }

    validateConditional(condition: any, step: TaskTemplateStep, sectionName: string, stepIndex: number): string[] {
        var validationErrors = [];

        if (step.actionType.toString() === TaskActionEnum.Check) {
            if (!condition.hasOwnProperty("answer")) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Question Action) is missing the condition to trigger further action`);
            }
        }

        if (step.actionType.toString() === TaskActionEnum.Verify) {
            if (!condition.operator) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Verify Action) is missing the operation to trigger further action (ie: Equals, Not Equals)`);
            }

            if (!condition.value) {
                validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Verify Action) is missing the value to compare to trigger further action`);
            }
        }

        return validationErrors;
    }

    validateException(exception: TaskTemplateExceptionDefinition, sectionName: string, stepIndex: number): string[] {
        const validationErrors = [];

        if (!exception.name) {
            validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Exception) is missing a name for the exception`);
        }

        if (!exception.description) {
            validationErrors.push(`Section '${sectionName}', Step ${stepIndex} (Exception) is missing a description for the exception`);
        }
        return validationErrors;
    }
}
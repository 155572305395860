import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { TaskTemplate } from '../../../api/models/Lukla/Inventory/task-template';

@Component({
  selector: 'template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss']
})
export class TemplateDetailsComponent implements OnInit {

 private _template: TaskTemplate;
  @Output() templateChange = new EventEmitter<TaskTemplate>();

  @Input()
  set template(value: TaskTemplate) {
    this._template = value;
    this.templateChange.emit(this._template);
  }
  get template(): TaskTemplate {
    return this._template;
  }
  

  @Input() isEditable = true;

  //  Icons
  addIcon = faPlus;

  //  Draggable
  draggable = {
    effectAllowed: "move",
    disable: false,
  };

  ngOnInit(): void {}

  addSection = () => {
    this.template.sections.push({
      steps: []
    });
    this.templateChange.emit(this.template);
  }
  deleteSection = (i: number) => {
    this.template.sections.splice(i, 1);
    this.templateChange.emit(this.template);
  }
  moveSectionUp = (i: number) => {
    var section = this.template.sections[i];
    this.template.sections.splice(i, 1);
    this.template.sections.splice(i-1, 0, section);
    this.templateChange.emit(this.template);
  }
  moveSectionDown = (i: number) => {
    var section = this.template.sections[i];
    this.template.sections.splice(i, 1);
    this.template.sections.splice(i+1, 0, section);
    this.templateChange.emit(this.template);
  }
}

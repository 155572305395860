import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCircleNotch, faListOl, faMagnifyingGlass, IconName } from '@fortawesome/pro-solid-svg-icons';
import { AnchorTypeEnum } from '../../api/models/Lukla/Inventory/anchor-type-enum';
import { AnchorView } from '../../api/models/Lukla/Inventory/anchor-view';
import { AnchorService } from '../../api/services/anchor.service';

@Component({
  selector: 'select-anchor',
  templateUrl: './select-anchor.component.html',
  styleUrls: ['./select-anchor.component.scss']
})
export class SelectAnchorComponent implements OnInit {

  constructor(
    private readonly anchorService: AnchorService
  ) { }

  @Output() selectedAnchor = new EventEmitter<AnchorView>();

  anchors : AnchorView[];
  filteredAnchors: AnchorView[];
  selAnchor: AnchorView;

  //  Flags
  isLoaded = false;
  isSearching = false;

  //  Icons
  processingIcon = faCircleNotch;
  searchIcon = faMagnifyingGlass;
  countIcon = faListOl;

  //  Searching
  searchText: string;
  filtered = 0;
  total = 0;


  async ngOnInit(): Promise<void> {
    this.anchors = await this.anchorService.available().toPromise();
    this.anchorSearch();
    this.isLoaded = true;
  }

  isSelected = (anchor: AnchorView) => this.selAnchor === anchor;
  select = (anchor: AnchorView) => {
    this.selAnchor = anchor;
    this.selectedAnchor.emit(anchor);
  }
  
  getTypeName(type: AnchorTypeEnum) {
    if (type == AnchorTypeEnum.FreeText) {
      return 'Free Text';
    }

    if (type == AnchorTypeEnum.Choice) {
      return 'Choice';
    }

    if (type == AnchorTypeEnum.Date) {
      return 'Date';
    }

    if (type == AnchorTypeEnum.Numeric) {
      return 'Numeric';
    }

    return '';
  }

  getIcon(iconName: string) {
    return <IconName>iconName;
  }

  async anchorSearch() {
    localStorage.setItem('anchor.page.anchors.filter', this.searchText);
    this.filteredAnchors = [...[], ...this.anchors];
    if (this.searchText !== '') {
      this.filteredAnchors = this.anchors.filter((x) => {
        let doesNameMatch = false
        let doesParentMatch = false
        let isGeneral = false

        doesNameMatch = x.displayName.toUpperCase().includes(this.searchText.toUpperCase());
        doesParentMatch = x.parent?.toUpperCase().includes(this.searchText.toUpperCase());
        if (x.parent == null || '') {
          isGeneral = true
          x.parent = "general"
          x.parent.toUpperCase().includes(this.searchText.toUpperCase())
        }

        return doesNameMatch || isGeneral || doesParentMatch;
      });
      this.filtered = this.filteredAnchors.length;
    }
    else {
      this.filteredAnchors = this.filteredAnchors.sort((a, b) =>
        b.displayName < a.displayName ? 1 : -1
      );
      this.filtered = this.filteredAnchors.length;
    }
  }
}

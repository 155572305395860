/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { canNotCompleteTask } from '../fn/tasking-action/can-not-complete-task';
import { CanNotCompleteTask$Params } from '../fn/tasking-action/can-not-complete-task';
import { closeTask } from '../fn/tasking-action/close-task';
import { CloseTask$Params } from '../fn/tasking-action/close-task';
import { completeTask } from '../fn/tasking-action/complete-task';
import { CompleteTask$Params } from '../fn/tasking-action/complete-task';
import { deleteTask } from '../fn/tasking-action/delete-task';
import { DeleteTask$Params } from '../fn/tasking-action/delete-task';
import { mergeDirectTask } from '../fn/tasking-action/merge-direct-task';
import { MergeDirectTask$Params } from '../fn/tasking-action/merge-direct-task';
import { mergeTask } from '../fn/tasking-action/merge-task';
import { MergeTask$Params } from '../fn/tasking-action/merge-task';
import { rejectTask } from '../fn/tasking-action/reject-task';
import { RejectTask$Params } from '../fn/tasking-action/reject-task';
import { reopenTask } from '../fn/tasking-action/reopen-task';
import { ReopenTask$Params } from '../fn/tasking-action/reopen-task';
import { revertTask } from '../fn/tasking-action/revert-task';
import { RevertTask$Params } from '../fn/tasking-action/revert-task';
import { updateTask } from '../fn/tasking-action/update-task';
import { UpdateTask$Params } from '../fn/tasking-action/update-task';

@Injectable({ providedIn: 'root' })
export class TaskingActionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reopenTask()` */
  static readonly ReopenTaskPath = '/tasking/actions/reopen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reopenTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reopenTask$Response(params?: ReopenTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reopenTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reopenTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reopenTask(params?: ReopenTask$Params, context?: HttpContext): Observable<void> {
    return this.reopenTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `rejectTask()` */
  static readonly RejectTaskPath = '/tasking/actions/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rejectTask$Response(params?: RejectTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rejectTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  rejectTask(params?: RejectTask$Params, context?: HttpContext): Observable<void> {
    return this.rejectTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `canNotCompleteTask()` */
  static readonly CanNotCompleteTaskPath = '/tasking/actions/can-not-complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `canNotCompleteTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  canNotCompleteTask$Response(params?: CanNotCompleteTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return canNotCompleteTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `canNotCompleteTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  canNotCompleteTask(params?: CanNotCompleteTask$Params, context?: HttpContext): Observable<void> {
    return this.canNotCompleteTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `closeTask()` */
  static readonly CloseTaskPath = '/tasking/actions/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  closeTask$Response(params?: CloseTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return closeTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  closeTask(params?: CloseTask$Params, context?: HttpContext): Observable<void> {
    return this.closeTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mergeTask()` */
  static readonly MergeTaskPath = '/tasking/actions/merge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeTask$Response(params?: MergeTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mergeTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeTask(params?: MergeTask$Params, context?: HttpContext): Observable<void> {
    return this.mergeTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mergeDirectTask()` */
  static readonly MergeDirectTaskPath = '/tasking/actions/merge/direct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeDirectTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeDirectTask$Response(params?: MergeDirectTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mergeDirectTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeDirectTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeDirectTask(params?: MergeDirectTask$Params, context?: HttpContext): Observable<void> {
    return this.mergeDirectTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `completeTask()` */
  static readonly CompleteTaskPath = '/tasking/actions/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeTask$Response(params?: CompleteTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return completeTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeTask(params?: CompleteTask$Params, context?: HttpContext): Observable<void> {
    return this.completeTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateTask()` */
  static readonly UpdateTaskPath = '/tasking/actions/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTask$Response(params?: UpdateTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTask(params?: UpdateTask$Params, context?: HttpContext): Observable<void> {
    return this.updateTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revertTask()` */
  static readonly RevertTaskPath = '/tasking/actions/revert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revertTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revertTask$Response(params?: RevertTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revertTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revertTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revertTask(params?: RevertTask$Params, context?: HttpContext): Observable<void> {
    return this.revertTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteTask()` */
  static readonly DeleteTaskPath = '/tasking/actions/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask$Response(params: DeleteTask$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask(params: DeleteTask$Params, context?: HttpContext): Observable<void> {
    return this.deleteTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskTemplateStep } from 'projects/ottomate-tasks/api/models/Lukla/Inventory/task-template-step';

@Component({
  selector: 'task-define-spark-harvest',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './define-spark-harvest.component.html',
  styleUrl: './define-spark-harvest.component.scss'
})
export class DefineSparkHarvestComponent implements OnInit {

  @Input() step : TaskTemplateStep;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>

  ngOnInit(): void {
    if (this.step && !this.step.actionDefinition) {
      this.step.actionDefinition = {};
    }
  }
}

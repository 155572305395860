/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addSubscriptionEvent } from '../fn/events/add-subscription-event';
import { AddSubscriptionEvent$Params } from '../fn/events/add-subscription-event';
import { deleteAllSubscriptionEvents } from '../fn/events/delete-all-subscription-events';
import { DeleteAllSubscriptionEvents$Params } from '../fn/events/delete-all-subscription-events';
import { deleteEvent } from '../fn/events/delete-event';
import { DeleteEvent$Params } from '../fn/events/delete-event';
import { flagEvent } from '../fn/events/flag-event';
import { FlagEvent$Params } from '../fn/events/flag-event';
import { getSubscriptionEvents } from '../fn/events/get-subscription-events';
import { GetSubscriptionEvents$Params } from '../fn/events/get-subscription-events';
import { getUnreadSubscriptionEvents } from '../fn/events/get-unread-subscription-events';
import { GetUnreadSubscriptionEvents$Params } from '../fn/events/get-unread-subscription-events';
import { SubscriptionEventsView as LuklaSubscriptionsSubscriptionEventsView } from '../models/Lukla/Subscriptions/subscription-events-view';
import { markAsReadEvent } from '../fn/events/mark-as-read-event';
import { MarkAsReadEvent$Params } from '../fn/events/mark-as-read-event';

@Injectable({ providedIn: 'root' })
export class EventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `addSubscriptionEvent()` */
  static readonly AddSubscriptionEventPath = '/subscriptions/events/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSubscriptionEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSubscriptionEvent$Response(params?: AddSubscriptionEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addSubscriptionEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSubscriptionEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addSubscriptionEvent(params?: AddSubscriptionEvent$Params, context?: HttpContext): Observable<void> {
    return this.addSubscriptionEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getSubscriptionEvents()` */
  static readonly GetSubscriptionEventsPath = '/subscriptions/events/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptionEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionEvents$Response(params?: GetSubscriptionEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaSubscriptionsSubscriptionEventsView>>> {
    return getSubscriptionEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubscriptionEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionEvents(params?: GetSubscriptionEvents$Params, context?: HttpContext): Observable<Array<LuklaSubscriptionsSubscriptionEventsView>> {
    return this.getSubscriptionEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaSubscriptionsSubscriptionEventsView>>): Array<LuklaSubscriptionsSubscriptionEventsView> => r.body)
    );
  }

  /** Path part for operation `getUnreadSubscriptionEvents()` */
  static readonly GetUnreadSubscriptionEventsPath = '/subscriptions/events/unread-counter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnreadSubscriptionEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnreadSubscriptionEvents$Response(params?: GetUnreadSubscriptionEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUnreadSubscriptionEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnreadSubscriptionEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnreadSubscriptionEvents(params?: GetUnreadSubscriptionEvents$Params, context?: HttpContext): Observable<number> {
    return this.getUnreadSubscriptionEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `flagEvent()` */
  static readonly FlagEventPath = '/subscriptions/events/flag-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `flagEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  flagEvent$Response(params?: FlagEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return flagEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `flagEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  flagEvent(params?: FlagEvent$Params, context?: HttpContext): Observable<void> {
    return this.flagEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `markAsReadEvent()` */
  static readonly MarkAsReadEventPath = '/subscriptions/events/mark-as-read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsReadEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markAsReadEvent$Response(params?: MarkAsReadEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markAsReadEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAsReadEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markAsReadEvent(params?: MarkAsReadEvent$Params, context?: HttpContext): Observable<void> {
    return this.markAsReadEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteEvent()` */
  static readonly DeleteEventPath = '/subscriptions/events/delete-event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEvent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteEvent$Response(params?: DeleteEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEvent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteEvent(params?: DeleteEvent$Params, context?: HttpContext): Observable<void> {
    return this.deleteEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAllSubscriptionEvents()` */
  static readonly DeleteAllSubscriptionEventsPath = '/subscriptions/events/delete-all-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAllSubscriptionEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllSubscriptionEvents$Response(params?: DeleteAllSubscriptionEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAllSubscriptionEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAllSubscriptionEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllSubscriptionEvents(params?: DeleteAllSubscriptionEvents$Params, context?: HttpContext): Observable<void> {
    return this.deleteAllSubscriptionEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

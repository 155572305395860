<div class="step-container d-flex">
    <h3>
        <fa-icon [icon]="headerIcon"></fa-icon>
    </h3>
    <div class="ms-3 was-validated has-validation">
        <h4>Inventory</h4>
        <p class="mt-2">This action will allow the capture of an item in the field with the basic information needed to
            be added to the system.</p>
        <div *ngIf="isEditable">
            <p>Below are the available anchors that can be populated on the start of a</p>
            <div class="mt-3 anchors">
                <div *ngFor="let p of groupedAnchors | keyvalue; let i = index" class="parent-anchor">
                    <div>
                        <p class="fs-4 d-inline">{{p.key || 'General'}} </p>
                        <div class="btn-group d-inline ms-3" dropdown>
                            <button dropdownToggle type="button" class="btn btn-dark btn-sm dropdown-toggle">
                              Actions <span class="caret"></span>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                              <li role="menuitem"><button class="btn btn-link-icon dropdown-item" (click)="activateAll(p.value)"><fa-icon [icon]="activateIcon" class="me-3"></fa-icon>Activate All</button></li>
                              <li role="menuitem"><button class="btn btn-link-icon dropdown-item" (click)="deactivateAll(p.value)"><fa-icon [icon]="deactivateIcon" class="me-3"></fa-icon>Deactivate All</button></li>
                              <li role="menuitem"><button class="btn btn-link-icon dropdown-item" (click)="makeAllRequired(p.value)"><fa-icon [icon]="requiredIcon" class="me-3"></fa-icon>Make All Required</button></li>
                              <li role="menuitem"><button class="btn btn-link-icon dropdown-item" (click)="makeAllOptional(p.value)"><fa-icon [icon]="banIcon" class="me-3"></fa-icon>Make All Optional</button></li>
                            </ul>
                          </div>
                    </div>
                    <div class="d-flex flex-wrap fs-6 mt-3">
                        <div *ngFor="let anchor of p.value | orderBy: 'order'"
                            class="text-nowrap p-2 m-2 text-white"
                            [class.bg-primary]="anchor.activated && !anchor.required" 
                            [class.bg-danger]="anchor.required && anchor.activated" 
                            [class.bg-secondary]="!anchor.activated">
                            {{ anchor.name }}
                            <button *ngIf="anchor.activated" (click)="deactivate(anchor.id)" class="btn-icon-link btn text-white ms-2 me-2" tooltip="Activated (shown when inventorying), click to remove from inventory"><fa-icon [icon]="deactivateIcon"></fa-icon></button>
                            <button *ngIf="!anchor.activated" (click)="activate(anchor.id)" class="btn-icon-link btn text-white ms-2 me-2" tooltip="Deactivated (not shown when inventorying), click to add to inventory"><fa-icon [icon]="activateIcon"></fa-icon></button>
                            <button *ngIf="anchor.activated && anchor.required" (click)="makeOptional(anchor.id)" class="btn-icon-link btn text-white"  tooltip="Required, click to only make the field optional"><fa-icon [icon]="requiredIcon"></fa-icon></button>
                            <button *ngIf="anchor.activated && !anchor.required" (click)="makeRequired(anchor.id)" class="btn-icon-link btn text-white" tooltip="Optional, click to only make the field required"><fa-icon [icon]="banIcon"></fa-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isEditable && step">
        </div>
    </div>
</div>
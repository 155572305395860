import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faBarcodeRead } from '@fortawesome/pro-regular-svg-icons';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';

@Component({
  selector: 'task-define-assign-tag',
  templateUrl: './define-assign-tag.component.html',
  styleUrls: ['./define-assign-tag.component.scss']
})
export class DefineAssignTagComponent implements OnInit {

  constructor() { }

  headerIcon = faBarcodeRead;

  @Input() isEditable = true;
  @Input() step : TaskTemplateStep;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();

  ngOnInit(): void {
    if (this.step && !this.step.actionDefinition) {
      this.step.actionDefinition = {};
    }
  }


}

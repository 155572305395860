<div class="step-container d-flex">
    <h3>
        <fa-icon [icon]="headerIcon"></fa-icon>
    </h3>
    <div class="ms-3">
        <h4>Comments</h4>
        <p class="mt-2">This action will allow technicians to submit comments back for further explaination</p>
        <div *ngIf="isEditable">
            <div class="form-group row was-validated has-validation">
                <label class="col-sm-2 col-form-label">Comment Statement</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" [(ngModel)]="step.actionDefinition.statement" required (ngModelChange)="stepChange.emit(step)">
                    <div class="invalid-feedback">
                        Comment statement is required for the action to be valid
                    </div>    
                </div>
            </div>    
        </div>

        <div *ngIf="!isEditable && step">
            <h5>Preview (App View)</h5>
            <div class="preview">
                <div class="d-flex">
                    <fa-icon [icon]="headerIcon"></fa-icon>
                    <div class="ps-2">{{step.actionDefinition.statement}}</div>
                </div>
                <div class="row mt-2">
                    <textarea rows="4" readonly="true"></textarea>
                </div>    
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { DeboucedInputComponent } from './debouced-input/debouced-input.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

@NgModule({
  declarations: [
    DeboucedInputComponent,
    ConfirmationModalComponent
  ],
  imports: [
    FormsModule,
    FontAwesomeModule
  ],
  exports: [
    DeboucedInputComponent,
    ConfirmationModalComponent

  ]
})
export class UtilitiesModule { }

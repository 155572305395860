<div class="flow-container flex-column align-items-center" *ngIf="loaded">
    <div class="flow flex-column align-items-center">
        <div class="badge bg-primary">
            <span class="h3">
                Start
            </span>
        </div>
        <div class="separator"></div>
        <div *ngFor="let s of template.sections; index as i">
            <div class="section">
                <task-template-define-section [(section)]="template.sections[i]" [isEditable]="false"></task-template-define-section>
            </div>
            <div class="separator" *ngIf="i < template.sections.length-1">
            </div>
        </div>
    </div>
</div>
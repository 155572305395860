import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnchorService } from 'src/app/api/services/anchor.service';
import { AnchorFilter } from 'src/app/api/models/Lukla/Inventory/anchor-filter';
import { faAnchor } from '@fortawesome/pro-solid-svg-icons';


@Component({
  selector: 'assets-anchor-group',
  templateUrl: './asset-group.component.html',
  styleUrls: ['./asset-group.component.scss']
})
export class AssetGroupComponent implements OnInit {

  constructor(
    private anchorService : AnchorService
  ) { }

  @Output() groupChanged = new EventEmitter<AnchorFilter[]>();
  groupAnchors: any[];
  @Input() isInventory = false;
  @Input() producerName: string;
  ganchorIcon=faAnchor;
  bufferSize = 50;

  async ngOnInit() {
    const response = await this.anchorService.groups().toPromise();
    this.groupAnchors = response.items;
  }

  async groupUpdated(ev: any) {
    var group = this.groupAnchors
      .filter(x => x.selected && x.selected.length > 0);

    var filters: AnchorFilter[] = [];
    group.forEach(x => {
      x.selected.forEach(s => {
        filters.push({
          id: x.id,
          value: s
        });
      });
    });

    this.groupChanged.emit(filters);
  }

  onScroll(event: any, group: any) {
    if (!group.buffer) group.buffer = [];
    if (group.items.length <= group.buffer.length) {
      return;
    }

    this.loadBuffer(group);
  }

  private loadBuffer(group: any) {
    const len = group.buffer.length;
    const more = group.items.slice(len, this.bufferSize + len);
    group.buffer = group.buffer.concat(more);
  }

  onScrollToEnd(group:any) {
    this.loadBuffer(group);
  }

  updateFilters(filters: AnchorFilter[]) {
    this.clearFilters();
    filters.forEach(filter => {
      var groupFilter = this.groupAnchors.find(x => x.id === filter.id);
      if (!groupFilter.selected) groupFilter.selected = [];
      groupFilter.selected.push(filter.value);
    })
  }

  clearFilters() {
    this.groupAnchors.forEach(x => x.selected = []);
  }
}

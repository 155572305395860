<div class="page-section d-flex align-items-center">
    <fa-icon [icon]="headerIcon"></fa-icon>
    <span class="ms-2 header-title">Content</span>
    <button class="ms-4 btn btn-primary" (click)="onContentUpload()"><fa-icon [icon]="addIcon"></fa-icon> Create New
        Content</button>
</div>

<div class="component-loader" *ngIf="!loaded">
    <facebook-content-loader></facebook-content-loader>
</div>

<div class="page-section-body" *ngIf="loaded">
    <div *ngIf="!hasContent" class="h5 ms-3 mb-5">
        <fa-icon [icon]="noItemsIcon"></fa-icon> There are no content items for this asset
    </div>

    <div *ngIf="hasContent" class="row row-cols-1 row-cols-xl-5 rows-cols-lg-2 g-4">
        <div class="col" *ngFor="let item of content; let i = index">
            <inventory-image-viewer (reupload)="update($event)" (delete)="remove($event)" [content]="item"
                [editable]="true"></inventory-image-viewer>
        </div>
    </div>
</div>
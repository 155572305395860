import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SelectActionModalComponent } from '../select-action-modal/select-action-modal.component';
import { TaskActionEnum } from '../../../utils/task-actions-enum.model';
import {TaskTemplateStep} from '../../../../api/models/Lukla/Inventory/task-template-step';

@Component({
  selector: 'task-define-conditional',
  templateUrl: './define-conditional.component.html',
  styleUrls: ['./define-conditional.component.scss']
})
export class DefineConditionalComponent implements OnInit {

  constructor(
    private modalService: BsModalService
  ) { }

  @Input() step : TaskTemplateStep;
  @Input() isEditable = true;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();

  addIcon = faPlus
  bsModalRef: BsModalRef;

  ngOnInit(): void {
  }

  isAllowed = () => {
    return this.step.actionType === <any>TaskActionEnum.Check || this.step.actionType === <any>TaskActionEnum.Verify;
  }

  isGeolocation = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Geolocate;
  isAssignTag = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.AssignTag;
  isCheck = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Check;
  isVerify = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Verify;
  isPhoto = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Photo;
  isFile = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.File;
  isComment = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Comment;

  addException = () => {
    const exception = {
      condition: {}
    };
    if (!this.step.exceptions) {
      this.step.exceptions = [];
    }
    this.step.exceptions.push(exception);
    this.stepChange.emit(this.step);
  }
  removeException = (exception) => {
    this.step.exceptions = this.step.exceptions.filter(x => x !== exception);
    this.stepChange.emit(this.step);
  }

  addConditionalStep = () => {
    this.bsModalRef = this.modalService.show(SelectActionModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.selectAction.subscribe((action: TaskActionEnum) => {
      if (!this.step.conditionalSteps) {
        this.step.conditionalSteps = [];
      }

      const step = {
        condition: {},
        step : {
          order: this.step.conditionalSteps.length + 1,
          actionType: <any>action,
          isRequired: false
        }
      };
      if (!this.step.conditionalSteps) {
        this.step.conditionalSteps = [];
      }
      this.step.conditionalSteps.push(step);
      this.stepChange.emit(this.step);
      this.bsModalRef.hide();
    });
  }
  removeConditionalStep = (conditionalStep:any) => {
    this.step.conditionalSteps.splice(conditionalStep, 1);
    //this.step.conditionalSteps = this.step.conditionalSteps.filter(x => x !== conditionalStep);
    this.stepChange.emit(this.step);
  }


  addConditionalSection = () => {
    const section = {
      condition: {},
      section: {
        steps: []
      }
    };
    if (!this.step.conditionalSections) {
      this.step.conditionalSections = [];
    }
    this.step.conditionalSections.push(section);
    this.stepChange.emit(this.step);
  }
  removeConditionalSection = (conditionalSection) => {
    this.step.conditionalSections = this.step.conditionalSections.filter(x => x !== conditionalSection);
    this.stepChange.emit(this.step);
  }
}

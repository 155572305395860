import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SharedModule } from './shared/shared.module';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ApiModule } from './api/api.module';
import { environment } from 'src/environments/environment';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AuthInterceptor } from './shared/auth/auth-interceptor';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { CountUpModule } from 'ngx-countup';
import { NgToggleModule } from 'ng-toggle-button';
import { OttoTasksCoreModule } from 'projects/ottomate-tasks';
import { NotificationServiceModule } from './notification-service/notification-service.module';




export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    InfiniteScrollModule,
    CountUpModule,
    TabsModule.forRoot(),
    DragToSelectModule.forRoot(),
    NgToggleModule.forRoot(),
    NgxMaskDirective,
     NgxMaskPipe,
    NotificationServiceModule.forRoot(),
    SharedModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.api],
        sendAccessToken: true,
      },
    }),
    OttoTasksCoreModule.forRoot({apiRootUrl: environment.api, tinymcekey: environment.tinymcekey}),
    ApiModule.forRoot({ rootUrl: environment.api }),
  ],
  providers: [
    provideNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory },
     

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas);
  }
}

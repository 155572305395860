/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkInviteDivisionMembers } from '../fn/division-access/bulk-invite-division-members';
import { BulkInviteDivisionMembers$Params } from '../fn/division-access/bulk-invite-division-members';
import { getDivisionTechnicians } from '../fn/division-access/get-division-technicians';
import { GetDivisionTechnicians$Params } from '../fn/division-access/get-division-technicians';
import { TechnicianView as LuklaInventoryTechnicianView } from '../models/Lukla/Inventory/technician-view';
import { registerDivisionTechnician } from '../fn/division-access/register-division-technician';
import { RegisterDivisionTechnician$Params } from '../fn/division-access/register-division-technician';
import { revokeDivisionTechnician } from '../fn/division-access/revoke-division-technician';
import { RevokeDivisionTechnician$Params } from '../fn/division-access/revoke-division-technician';
import { sendTechnicianInvite } from '../fn/division-access/send-technician-invite';
import { SendTechnicianInvite$Params } from '../fn/division-access/send-technician-invite';

@Injectable({ providedIn: 'root' })
export class DivisionAccessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDivisionTechnicians()` */
  static readonly GetDivisionTechniciansPath = '/divisions/access/{id}/technicians';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDivisionTechnicians()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDivisionTechnicians$Response(params: GetDivisionTechnicians$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTechnicianView>>> {
    return getDivisionTechnicians(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDivisionTechnicians$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDivisionTechnicians(params: GetDivisionTechnicians$Params, context?: HttpContext): Observable<Array<LuklaInventoryTechnicianView>> {
    return this.getDivisionTechnicians$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTechnicianView>>): Array<LuklaInventoryTechnicianView> => r.body)
    );
  }

  /** Path part for operation `registerDivisionTechnician()` */
  static readonly RegisterDivisionTechnicianPath = '/divisions/access/register/technician';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerDivisionTechnician()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerDivisionTechnician$Response(params?: RegisterDivisionTechnician$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerDivisionTechnician(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerDivisionTechnician$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerDivisionTechnician(params?: RegisterDivisionTechnician$Params, context?: HttpContext): Observable<void> {
    return this.registerDivisionTechnician$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendTechnicianInvite()` */
  static readonly SendTechnicianInvitePath = '/divisions/access/technician/send/invite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTechnicianInvite()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendTechnicianInvite$Response(params?: SendTechnicianInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendTechnicianInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendTechnicianInvite$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendTechnicianInvite(params?: SendTechnicianInvite$Params, context?: HttpContext): Observable<void> {
    return this.sendTechnicianInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revokeDivisionTechnician()` */
  static readonly RevokeDivisionTechnicianPath = '/divisions/access/revoke/technician';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeDivisionTechnician()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeDivisionTechnician$Response(params?: RevokeDivisionTechnician$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeDivisionTechnician(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeDivisionTechnician$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revokeDivisionTechnician(params?: RevokeDivisionTechnician$Params, context?: HttpContext): Observable<void> {
    return this.revokeDivisionTechnician$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `bulkInviteDivisionMembers()` */
  static readonly BulkInviteDivisionMembersPath = '/divisions/access/{id}/bulk/invites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkInviteDivisionMembers()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkInviteDivisionMembers$Response(params: BulkInviteDivisionMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkInviteDivisionMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkInviteDivisionMembers$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkInviteDivisionMembers(params: BulkInviteDivisionMembers$Params, context?: HttpContext): Observable<void> {
    return this.bulkInviteDivisionMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

<button (click)="toggleOffcanvas()" tooltip="Notifications" class="button-style btn btn-link  ">
    <fa-icon class='fa-lg' [icon]="notificationIcon"></fa-icon>
</button>
<div [ngClass]="{'open': isOpen, 'offcanvas': true}">
    <div class="d-flex justify-content-between align-items-center">
        <label class=" notification-header fs-3 ms-3">Notifications</label>
        <button class="btn btn-light" (click)="toggleOffcanvas()">
            <fa-icon class="fa-2x" [icon]="closeIcon"></fa-icon>
        </button>
    </div>
    <div class="content">
        <notification-list></notification-list>
    </div>
</div>
<div *ngIf="(!loaded && notifications.length < 1) || isScrolling">
    <list-content-loader></list-content-loader>
</div>

<div *ngIf="loaded">
    <div class=" mb-3 d-flex justify-content-between align-items-center">
        <div class=" ms-3 btn-group" role="group" aria-label="Notification actions">
            <button [disabled]="!unreadNotificationsExist()" type="button" tooltip="Mark All As Read"
                class="btn btn-outline-secondary" (click)="markAllAsRead()">
                <fa-icon class="fs-6" [icon]="readAllIcon"></fa-icon>
            </button>
            <button type="button" tooltip="Toggle Flagged Notifications" class="btn btn-outline-secondary"
                (click)="toggleShowFlagged()">
                <fa-icon class="fs-6" [icon]="showFlagOnly ? flagIconSolid : flagIconRegular"></fa-icon>
            </button>
            <button type="button" tooltip="Delete All Notifications" class="btn btn-outline-danger"
                (click)="deleteAllNotifications()">
                <fa-icon class="fs-6" [icon]="deleteIcon"></fa-icon>
            </button>
        </div>

        <div class="d-flex justify-content-end align-items-center">
            <div class="search">
                <div class="input-group flex-nowrap">
                    <span class="input-group-text">
                        <fa-icon [icon]="searchIcon"></fa-icon>
                    </span>
                    <debounced-input class="flex-grow-1" placeholder="Search..." [delay]="400" [inputValue]="searchTerm"
                        (value)="search($event)">
                    </debounced-input>
                </div>
            </div>
            <label class="ms-1 me-2 fs-6 fw-normal">Showing {{filtered}} of {{total}}</label>
        </div>
    </div>

    <div *ngIf="notifications.length > 0" infiniteScroll class="notification-list" [infiniteScrollDistance]="2"
        [scrollWindow]="false" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <div class="ms-3">
            <div *ngFor="let notification of notifications; let i = index" class="card shadow notifications-container"
                [ngClass]="{'read': !notification.read}" (mouseover)="setRead(notification.id)">
                <div class="notification card-body">
                    <div class="notification-content d-flex align-items-start">
                        <fa-icon class="fa-3x me-3" [fixedWidth]="true" [icon]="getEventIcon(notification)"></fa-icon>
                        <div class="flex-grow-1 d-flex justify-content-between">
                            <div>
                                <div class="mb-1">
                                    <p class="mb-0  fs-5 fst-bold">{{notification.eventName}}</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-start mb-2">
                                    <lukla-user [userId]="notification.auditUserId" [small]="true"></lukla-user>
                                    <p class="ms-2 timestamp">{{notification.eventDate | amDateFormat:'LLLL'}}</p>
                                </div>
                                <p class="mt-2  content-align">{{notification.formattedEvent}}</p>
                                <p class="mt-1 content-align">{{notification.eventTitle}}</p>
                            </div>
                            <div class="ms-2 btn-group d-flex align-items-start" role="group"
                                aria-label="Notification actions">
                                <button type="button" tooltip="Flag Notification" class="btn btn-outline-secondary"
                                    (click)="toggleFlagged(notification.id);$event.stopPropagation()">
                                    <fa-icon class="fs-6"
                                        [icon]="notification.flagged ? flagIconSolid : flagIconRegular"></fa-icon>
                                </button>
                                <button type="button" tooltip="Delete Notification" class="btn btn-outline-danger"
                                    (click)="deleteNotification(notification.id);$event.stopPropagation()">
                                    <fa-icon class="fs-6" icon="trash-alt"></fa-icon>
                                </button>
                                <button type="button" tooltip="Go to Event" class="btn btn-outline-primary"
                                    (click)="routeToEvent(notification.id);$event.stopPropagation()">
                                    <fa-icon class="fs-6" [icon]="goToIcon"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isSaving" class="overlay">
        <fa-icon [icon]="processIcon" [spin]="true" class=" spinner fa-3x"></fa-icon>
    </div>

    <div *ngIf="(notifications.length < 1 && loaded) && isScrolling === false "
        class="mt-4 p-5 bg-primary text-white rounded">
        <div class="d-flex justify-content-start">
            <fa-icon class="display-4" [icon]="noNotificationsIcon"></fa-icon>
            <div class="ms-5">
                <h1>No Notifications!</h1>
                <p class="pb-2">You currently have no new notifications!<br> If you have not yet subscribed to any
                    events, you can go to one of the following pages:</p>
                <ul>
                    <li><a class="text-white" [routerLink]="'/'">Specific Inventory Item</a></li>
                    <li><a class="text-white" [routerLink]="'/campaigns'">Specific Campaign</a></li>
                    <li>Specific Task appended to the Campaign or Item</li>
                </ul>
                <p>And click on the bell icon inside of the chosen Item, Campaign, or Task.</p>
            </div>
        </div>
    </div>
</div>


import { CommonModule } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Response } from 'src/app/api/models/Lukla/Profile/Search/response';
import { ProfileView } from 'src/app/api/models/Lukla/Profile/profile-view';
import { ProfileService } from 'src/app/api/services/profile.service';

import { FormsModule} from '@angular/forms';
import { UserComponent } from '../user/user.component';

@Component({
  selector: 'lukla-person-picker',
  templateUrl: './person-picker.component.html',
  styleUrls: ['./person-picker.component.scss'],
  standalone: true,
  imports:[NgSelectModule, CommonModule, FormsModule, UserComponent]
})
export class PersonPickerComponent implements OnInit {

  constructor(
    private profileService : ProfileService
  ) { }

  @Input() allowMultiple = false;
  @Input() allowExternal = false;
  @Input() email = "";
  @Output() profileSelected: EventEmitter<ProfileView> = new EventEmitter<ProfileView>();
  @Output() profilesSelected: EventEmitter<ProfileView[]> = new EventEmitter<ProfileView[]>();
  @Output() emailChange = new EventEmitter();
  @Input() emails: string[] = [];
  @Output() emailsChange = new EventEmitter();
  @Output() change = new EventEmitter();

  items$: Observable<ProfileView[]>;
  loading = false;
  searchInput$ = new Subject<string>();
  selectedItem: any;

  ngOnInit(): void {
    this.loadItems();
  }

  trackByFn(item: ProfileView) {
    return item.userName;
  }

  private loadItems() {
    this.items$ = concat(
      of([]), // default items
      this.searchInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap(term => this.profileService.search({SearchTerms: term}).pipe(
          catchError(() => []), // empty list on error
          tap((x: Response) => this.loading = false),
          map((v: Response) =>  v.items!)
        ))
      )
    );
  }

  onChange(p: any) {
    if (!this.allowMultiple) {
      this.email = p.userName;
      this.emailChange.emit(p.userName);
      this.profileSelected.emit(p);
      this.change.emit(this.email);
    }
    else {
      this.emails = p.map((v:any) => v.userName);
      this.emailsChange.emit(this.emails);
      this.profilesSelected.emit(p);
      this.change.emit(this.emails);
    }
  }
}

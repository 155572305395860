import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserTypeEnum } from 'src/app/api/models/Lukla/Services/user-type-enum';
import { CurrentUserService } from 'src/app/lukla/user.service';

@Injectable()
 export class AuthGuard  {
  constructor(private router: Router, private oauthService: OAuthService, private readonly userService: CurrentUserService) {}

 async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    var accessToken = this.oauthService.getAccessToken();
    var user = await this.userService.get();
    if (accessToken && user.userType === UserTypeEnum.Lukla) {
      return true;
    } else {
      this.router.navigate(['/session/end'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }
}

export const IsAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(AuthGuard).canActivate(route, state);
}



/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadImportTemplate } from '../fn/import/download-import-template';
import { DownloadImportTemplate$Params } from '../fn/import/download-import-template';
import { downloadPopulatedUpdateTemplate } from '../fn/import/download-populated-update-template';
import { DownloadPopulatedUpdateTemplate$Params } from '../fn/import/download-populated-update-template';
import { downloadUpdateTemplate } from '../fn/import/download-update-template';
import { DownloadUpdateTemplate$Params } from '../fn/import/download-update-template';
import { executeImport } from '../fn/import/execute-import';
import { ExecuteImport$Params } from '../fn/import/execute-import';
import { executeUpdate } from '../fn/import/execute-update';
import { ExecuteUpdate$Params } from '../fn/import/execute-update';
import { BulkImportValidationResponse as LuklaInventoryBulkImportValidationResponse } from '../models/Lukla/Inventory/bulk-import-validation-response';
import { BulkUpdateValidationResponse as LuklaInventoryBulkUpdateValidationResponse } from '../models/Lukla/Inventory/bulk-update-validation-response';
import { validateImport } from '../fn/import/validate-import';
import { ValidateImport$Params } from '../fn/import/validate-import';
import { validateImport$Plain } from '../fn/import/validate-import-plain';
import { ValidateImport$Plain$Params } from '../fn/import/validate-import-plain';
import { validateUpdate } from '../fn/import/validate-update';
import { ValidateUpdate$Params } from '../fn/import/validate-update';
import { validateUpdate$Plain } from '../fn/import/validate-update-plain';
import { ValidateUpdate$Plain$Params } from '../fn/import/validate-update-plain';

@Injectable({ providedIn: 'root' })
export class ImportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `downloadImportTemplate()` */
  static readonly DownloadImportTemplatePath = '/import/import-item/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadImportTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImportTemplate$Response(params?: DownloadImportTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadImportTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadImportTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImportTemplate(params?: DownloadImportTemplate$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadImportTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `downloadUpdateTemplate()` */
  static readonly DownloadUpdateTemplatePath = '/import/update/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadUpdateTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUpdateTemplate$Response(params?: DownloadUpdateTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadUpdateTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadUpdateTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUpdateTemplate(params?: DownloadUpdateTemplate$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadUpdateTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `downloadPopulatedUpdateTemplate()` */
  static readonly DownloadPopulatedUpdateTemplatePath = '/import/update/template/populated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadPopulatedUpdateTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPopulatedUpdateTemplate$Response(params?: DownloadPopulatedUpdateTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadPopulatedUpdateTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadPopulatedUpdateTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPopulatedUpdateTemplate(params?: DownloadPopulatedUpdateTemplate$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadPopulatedUpdateTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `validateImport()` */
  static readonly ValidateImportPath = '/import/import/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateImport$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateImport$Plain$Response(params?: ValidateImport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryBulkImportValidationResponse>> {
    return validateImport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateImport$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateImport$Plain(params?: ValidateImport$Plain$Params, context?: HttpContext): Observable<LuklaInventoryBulkImportValidationResponse> {
    return this.validateImport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryBulkImportValidationResponse>): LuklaInventoryBulkImportValidationResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateImport$Response(params?: ValidateImport$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryBulkImportValidationResponse>> {
    return validateImport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateImport(params?: ValidateImport$Params, context?: HttpContext): Observable<LuklaInventoryBulkImportValidationResponse> {
    return this.validateImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryBulkImportValidationResponse>): LuklaInventoryBulkImportValidationResponse => r.body)
    );
  }

  /** Path part for operation `executeImport()` */
  static readonly ExecuteImportPath = '/import/import/execute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  executeImport$Response(params?: ExecuteImport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return executeImport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  executeImport(params?: ExecuteImport$Params, context?: HttpContext): Observable<void> {
    return this.executeImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `validateUpdate()` */
  static readonly ValidateUpdatePath = '/import/update/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateUpdate$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateUpdate$Plain$Response(params?: ValidateUpdate$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryBulkUpdateValidationResponse>> {
    return validateUpdate$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateUpdate$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateUpdate$Plain(params?: ValidateUpdate$Plain$Params, context?: HttpContext): Observable<LuklaInventoryBulkUpdateValidationResponse> {
    return this.validateUpdate$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryBulkUpdateValidationResponse>): LuklaInventoryBulkUpdateValidationResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateUpdate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateUpdate$Response(params?: ValidateUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryBulkUpdateValidationResponse>> {
    return validateUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateUpdate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateUpdate(params?: ValidateUpdate$Params, context?: HttpContext): Observable<LuklaInventoryBulkUpdateValidationResponse> {
    return this.validateUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryBulkUpdateValidationResponse>): LuklaInventoryBulkUpdateValidationResponse => r.body)
    );
  }

  /** Path part for operation `executeUpdate()` */
  static readonly ExecuteUpdatePath = '/import/update/execute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeUpdate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  executeUpdate$Response(params?: ExecuteUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return executeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeUpdate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  executeUpdate(params?: ExecuteUpdate$Params, context?: HttpContext): Observable<void> {
    return this.executeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { add } from '../fn/item/add';
import { Add$Params } from '../fn/item/add';
import { deactivate } from '../fn/item/deactivate';
import { Deactivate$Params } from '../fn/item/deactivate';
import { deleteItem } from '../fn/item/delete-item';
import { DeleteItem$Params } from '../fn/item/delete-item';
import { effectiveDate } from '../fn/item/effective-date';
import { EffectiveDate$Params } from '../fn/item/effective-date';
import { get } from '../fn/item/get';
import { Get$Params } from '../fn/item/get';
import { getItemTasks } from '../fn/item/get-item-tasks';
import { GetItemTasks$Params } from '../fn/item/get-item-tasks';
import { lock } from '../fn/item/lock';
import { Lock$Params } from '../fn/item/lock';
import { InventoryItemView as LuklaInventoryInventoryItemView } from '../models/Lukla/Inventory/inventory-item-view';
import { ItemTaskView as LuklaInventoryItemTaskView } from '../models/Lukla/Inventory/item-task-view';
import { reactivate } from '../fn/item/reactivate';
import { Reactivate$Params } from '../fn/item/reactivate';
import { unlock } from '../fn/item/unlock';
import { Unlock$Params } from '../fn/item/unlock';
import { updateIdentifier } from '../fn/item/update-identifier';
import { UpdateIdentifier$Params } from '../fn/item/update-identifier';

@Injectable({ providedIn: 'root' })
export class ItemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `add()` */
  static readonly AddPath = '/inventory/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `add()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  add$Response(params?: Add$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return add(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `add$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  add(params?: Add$Params, context?: HttpContext): Observable<string> {
    return this.add$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deactivate()` */
  static readonly DeactivatePath = '/inventory/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deactivate$Response(params?: Deactivate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deactivate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deactivate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deactivate(params?: Deactivate$Params, context?: HttpContext): Observable<void> {
    return this.deactivate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reactivate()` */
  static readonly ReactivatePath = '/inventory/reactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reactivate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reactivate$Response(params?: Reactivate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reactivate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reactivate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reactivate(params?: Reactivate$Params, context?: HttpContext): Observable<void> {
    return this.reactivate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `lock()` */
  static readonly LockPath = '/inventory/lock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lock()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lock$Response(params?: Lock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return lock(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lock$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  lock(params?: Lock$Params, context?: HttpContext): Observable<void> {
    return this.lock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unlock()` */
  static readonly UnlockPath = '/inventory/unlock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlock()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlock$Response(params?: Unlock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unlock(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlock$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unlock(params?: Unlock$Params, context?: HttpContext): Observable<void> {
    return this.unlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `effectiveDate()` */
  static readonly EffectiveDatePath = '/inventory/effective-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `effectiveDate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  effectiveDate$Response(params?: EffectiveDate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return effectiveDate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `effectiveDate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  effectiveDate(params?: EffectiveDate$Params, context?: HttpContext): Observable<void> {
    return this.effectiveDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateIdentifier()` */
  static readonly UpdateIdentifierPath = '/inventory/identifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIdentifier()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIdentifier$Response(params?: UpdateIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateIdentifier(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIdentifier$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIdentifier(params?: UpdateIdentifier$Params, context?: HttpContext): Observable<void> {
    return this.updateIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `get()` */
  static readonly GetPath = '/inventory/items/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryInventoryItemView>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: Get$Params, context?: HttpContext): Observable<LuklaInventoryInventoryItemView> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryInventoryItemView>): LuklaInventoryInventoryItemView => r.body)
    );
  }

  /** Path part for operation `deleteItem()` */
  static readonly DeleteItemPath = '/inventory/items/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteItem$Response(params: DeleteItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteItem(params: DeleteItem$Params, context?: HttpContext): Observable<void> {
    return this.deleteItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getItemTasks()` */
  static readonly GetItemTasksPath = '/inventory/items/{id}/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemTasks$Response(params: GetItemTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryItemTaskView>>> {
    return getItemTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItemTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemTasks(params: GetItemTasks$Params, context?: HttpContext): Observable<Array<LuklaInventoryItemTaskView>> {
    return this.getItemTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryItemTaskView>>): Array<LuklaInventoryItemTaskView> => r.body)
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/notification-service/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'UI.Inventory';
  constructor(private notificationService: NotificationsService) { }

  ngOnInit() {
    
  }
}


/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createNewContent } from '../fn/content/create-new-content';
import { CreateNewContent$Params } from '../fn/content/create-new-content';
import { downloadContent } from '../fn/content/download-content';
import { DownloadContent$Params } from '../fn/content/download-content';
import { downloadHistoricalContent } from '../fn/content/download-historical-content';
import { DownloadHistoricalContent$Params } from '../fn/content/download-historical-content';
import { exportContent } from '../fn/content/export-content';
import { ExportContent$Params } from '../fn/content/export-content';
import { getCategories } from '../fn/content/get-categories';
import { GetCategories$Params } from '../fn/content/get-categories';
import { getContent } from '../fn/content/get-content';
import { GetContent$Params } from '../fn/content/get-content';
import { InventoryItemContentView as LuklaInventoryInventoryItemContentView } from '../models/Lukla/Inventory/inventory-item-content-view';
import { removeContent } from '../fn/content/remove-content';
import { RemoveContent$Params } from '../fn/content/remove-content';
import { removeRaw } from '../fn/content/remove-raw';
import { RemoveRaw$Params } from '../fn/content/remove-raw';
import { updateContent } from '../fn/content/update-content';
import { UpdateContent$Params } from '../fn/content/update-content';
import { uploadFile } from '../fn/content/upload-file';
import { UploadFile$Params } from '../fn/content/upload-file';
import { uploadInventoryPhotoVersion } from '../fn/content/upload-inventory-photo-version';
import { UploadInventoryPhotoVersion$Params } from '../fn/content/upload-inventory-photo-version';

@Injectable({ providedIn: 'root' })
export class ContentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadFile()` */
  static readonly UploadFilePath = '/content/upload/file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile$Response(params?: UploadFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uploadFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile(params?: UploadFile$Params, context?: HttpContext): Observable<void> {
    return this.uploadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createNewContent()` */
  static readonly CreateNewContentPath = '/content/create/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createNewContent$Response(params?: CreateNewContent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createNewContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNewContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createNewContent(params?: CreateNewContent$Params, context?: HttpContext): Observable<void> {
    return this.createNewContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `uploadInventoryPhotoVersion()` */
  static readonly UploadInventoryPhotoVersionPath = '/content/upload/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadInventoryPhotoVersion()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadInventoryPhotoVersion$Response(params?: UploadInventoryPhotoVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uploadInventoryPhotoVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadInventoryPhotoVersion$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadInventoryPhotoVersion(params?: UploadInventoryPhotoVersion$Params, context?: HttpContext): Observable<void> {
    return this.uploadInventoryPhotoVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `downloadHistoricalContent()` */
  static readonly DownloadHistoricalContentPath = '/content/historical/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadHistoricalContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadHistoricalContent$Response(params: DownloadHistoricalContent$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadHistoricalContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadHistoricalContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadHistoricalContent(params: DownloadHistoricalContent$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadHistoricalContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `downloadContent()` */
  static readonly DownloadContentPath = '/content/download/{contentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadContent$Response(params: DownloadContent$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadContent(params: DownloadContent$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportContent()` */
  static readonly ExportContentPath = '/content/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportContent$Response(params?: ExportContent$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportContent(params?: ExportContent$Params, context?: HttpContext): Observable<Blob> {
    return this.exportContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `updateContent()` */
  static readonly UpdateContentPath = '/content/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateContent$Response(params?: UpdateContent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateContent(params?: UpdateContent$Params, context?: HttpContext): Observable<void> {
    return this.updateContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getContent()` */
  static readonly GetContentPath = '/content/all/{inventoryItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Response(params: GetContent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryInventoryItemContentView>>> {
    return getContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent(params: GetContent$Params, context?: HttpContext): Observable<Array<LuklaInventoryInventoryItemContentView>> {
    return this.getContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryInventoryItemContentView>>): Array<LuklaInventoryInventoryItemContentView> => r.body)
    );
  }

  /** Path part for operation `removeContent()` */
  static readonly RemoveContentPath = '/content/remove/{inventoryContentItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeContent$Response(params: RemoveContent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeContent(params: RemoveContent$Params, context?: HttpContext): Observable<void> {
    return this.removeContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeRaw()` */
  static readonly RemoveRawPath = '/content/remove/raw/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRaw()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRaw$Response(params: RemoveRaw$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeRaw(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeRaw$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRaw(params: RemoveRaw$Params, context?: HttpContext): Observable<void> {
    return this.removeRaw$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCategories()` */
  static readonly GetCategoriesPath = '/content/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategories()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCategories$Response(params?: GetCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCategories$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCategories(params?: GetCategories$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { available } from '../fn/anchor/available';
import { Available$Params } from '../fn/anchor/available';
import { getAnchor } from '../fn/anchor/get-anchor';
import { GetAnchor$Params } from '../fn/anchor/get-anchor';
import { groups } from '../fn/anchor/groups';
import { Groups$Params } from '../fn/anchor/groups';
import { AnchorView as LuklaInventoryAnchorView } from '../models/Lukla/Inventory/anchor-view';
import { Response as LuklaInventoryGroupAnchorsResponse } from '../models/Lukla/Inventory/GroupAnchors/response';

@Injectable({ providedIn: 'root' })
export class AnchorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `available()` */
  static readonly AvailablePath = '/administration/anchors/available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `available()` instead.
   *
   * This method doesn't expect any request body.
   */
  available$Response(params?: Available$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryAnchorView>>> {
    return available(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `available$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  available(params?: Available$Params, context?: HttpContext): Observable<Array<LuklaInventoryAnchorView>> {
    return this.available$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryAnchorView>>): Array<LuklaInventoryAnchorView> => r.body)
    );
  }

  /** Path part for operation `getAnchor()` */
  static readonly GetAnchorPath = '/administration/anchors/get/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnchor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnchor$Response(params: GetAnchor$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryAnchorView>> {
    return getAnchor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAnchor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnchor(params: GetAnchor$Params, context?: HttpContext): Observable<LuklaInventoryAnchorView> {
    return this.getAnchor$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryAnchorView>): LuklaInventoryAnchorView => r.body)
    );
  }

  /** Path part for operation `groups()` */
  static readonly GroupsPath = '/administration/anchors/groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groups()` instead.
   *
   * This method doesn't expect any request body.
   */
  groups$Response(params?: Groups$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryGroupAnchorsResponse>> {
    return groups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groups(params?: Groups$Params, context?: HttpContext): Observable<LuklaInventoryGroupAnchorsResponse> {
    return this.groups$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryGroupAnchorsResponse>): LuklaInventoryGroupAnchorsResponse => r.body)
    );
  }

}

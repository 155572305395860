<div class="alert-btn alert mt-4 border-bottom" (click)="selectTemplate()" [class.selected]="isSelected">
    <h4>{{template.name}} 
      <fa-icon *ngIf="targets(templateTargets.Item)" class="fs-5 ms-3" [icon]="itemIcon" [fixedWidth]="true" tooltip="This task targets an item"></fa-icon>
      <fa-icon *ngIf="targets(templateTargets.Location)" class="fs-5 ms-3" [icon]="locationIcon" [fixedWidth]="true" tooltip="This task targets a location"></fa-icon>
      <fa-icon *ngIf="template.allowAdhoc" class="fs-5 ms-3" [icon]="adhocIcon" [fixedWidth]="true" tooltip="Allows for ad-hoc task creation from either the item page or the mobile application"></fa-icon>
  
    </h4>
    <div *ngIf="canExpand && !isExpanded">
      <p class="lead" [innerHTML]="collapsedText"></p>
      <button class="btn btn-link" (click)="isExpanded = true">Show More</button>
    </div>
    <div *ngIf="!canExpand || isExpanded">
      <p class="lead" [innerHTML]="template.explanation"></p>
    </div>
    <label *ngFor="let c of template.characteristics" class="fs-6 d-line mt-1 me-2 p-1 px-2 bg-primary text-white text-nowrap"><fa-icon [icon]="tagIcon"></fa-icon> {{c.name}} : {{c.value}} 
    </label>
  </div>

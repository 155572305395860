import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskTemplate } from '../../../api/models/Lukla/Inventory/task-template';
import { TaskingService } from '../../../api/services/tasking.service';

@Component({
  selector: 'template-viewer',
  templateUrl: './template-viewer.component.html',
  styleUrls: ['./template-viewer.component.scss']
})
export class TemplateViewerComponent implements OnInit {

  constructor(
    private readonly taskingService : TaskingService
  ) { }

  @Input() templateId: string;

  template: TaskTemplate;
  loaded = false;

  async ngOnInit(): Promise<void> {
    this.template = await this.taskingService.getTemplate({id: this.templateId}).toPromise();
    this.loaded = true;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faComments } from '@fortawesome/pro-regular-svg-icons';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';

@Component({
    selector: 'task-define-comments',
    templateUrl: './define-comments.component.html',
    styleUrls: ['./define-comments.component.scss']
})
export class DefineCommentsComponent implements OnInit {

    constructor() { }

    headerIcon = faComments;

    @Input() isEditable = true;
    @Input() step: TaskTemplateStep;
    @Output() stepChange = new EventEmitter<TaskTemplateStep>();

    ngOnInit(): void {
        if (this.step && !this.step.actionDefinition) {
            this.step.actionDefinition = {
                statement: '',
            };
        }
    }
}

import { NgModule } from '@angular/core';
import { HeaderProfileComponent } from './header-profile/header-profile.component';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PersonPickerComponent } from 'projects/ottomate-tasks/lukla/person-picker/person-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { DeletionConfirmationModalComponent } from './modals/deletion-confirmation-modal/deletion-confirmation-modal.component';
import { UserComponent } from 'projects/ottomate-tasks/lukla/user/user.component';
@NgModule({
  declarations: [
    HeaderProfileComponent,
    ConfirmationModalComponent,
    DeletionConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    PersonPickerComponent,
    PopoverModule,
    FontAwesomeModule,
    NgSelectModule,
    UserComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderProfileComponent,
    ConfirmationModalComponent
  ]
})
export class LuklaModule { }


<div *ngIf="displayError " class="h5 ms-3 mb-5">
  <fa-icon [icon]="noItemsIcon"></fa-icon> {{content.fileName}} is having an issue  with loading correctly. Please contact Lukla support.
</div>
<div *ngIf="isLoaded" class="mt-2">
  <div class="header d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-between align-items-center">

      <div class="download" tooltip="Download Content">
        <a class="btn btn-link" (click)="download(content)">
          <fa-icon [icon]="downloadIcon"></fa-icon>
        </a>
      </div>
      <div>
        <label class="ms-2 fw-bold lead">{{content.name}}</label>
        <label *ngIf="!content.name" class=" ms-2 fw-bold lead">{{content.fileName}}</label>
      </div>
    </div>
    <div *ngIf="editable" class="d-flex justify-content-end align-items-center">
      <div class="btn-group" dropdown  placement="right">
        <button id="button-dropup" dropdownToggle type="button" class=" me-2 btn btn-primary dropdown-toggle"
                aria-controls="dropdown-container">
          Options <span class="caret"></span>
        </button>
        <ul id="dropdown-container" *dropdownMenu class="dropdown-menu"
            role="menu" aria-labelledby="button-dropup">
            <li><a (click)="update()" class="dropdown-item"><fa-icon class="me-2" [icon]="updateIcon"></fa-icon> Re-Upload Content</a></li>
            <li><a class="dropdown-item" (click)="remove()"><fa-icon class="me-2" [icon]="removeIcon"></fa-icon> Remove Content</a></li>
        </ul>
      </div>
  </div>
  </div>
  <div class="card mb-1" (click)="showLightbox(selectedImageIndex)">
    <div class="expand">
      <fa-icon [icon]="expandIcon"></fa-icon>
    </div>
    <img [src]="content.thumb" class="card-img-top item-image">
  </div>
  <div class="mb-5 mt-2 d-flex justify-content-between" >
    <label *ngFor="let tag of content.tags" class="d-line fs-5 fw-light p-1 px-2 bg-primary text-white text-nowrap  "><fa-icon [icon]="tagIcon"></fa-icon> {{tag.name}} - {{tag.value}}
  </label>
  </div>
</div>

import { ModuleWithProviders, NgModule } from "@angular/core"
import { NotificationsService } from "./notification.service";


@NgModule({
    imports: [],
    declarations: [],
    providers: []
})


export class NotificationServiceModule{
  static forRoot(): ModuleWithProviders<NotificationServiceModule> {
    return {
      providers: [
        NotificationsService
      ],
      ngModule: NotificationServiceModule
    };
  }
}




import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { faEdit, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TaskTemplate } from "projects/ottomate-tasks/api/models/Lukla/Inventory/task-template";
import { Subscription } from "rxjs";
import { TemplateDraft } from "../../utils/template-draft-model";
import { TemplateUpdateService } from "../../services/template-update-service";
import { TaskingService } from "../../../api/services/tasking.service";
import { TaskTemplateVersionItem } from "../../../api/models/Lukla/Inventory/task-template-version-item";

@Component({
  selector: 'task-template-wizard',
  templateUrl: './template-wizard.component.html',
  styleUrls: ['./template-wizard.component.scss']
})
export class TemplateWizardComponent implements OnInit, OnDestroy {

  constructor(
    private templateUpdateService: TemplateUpdateService,
    private taskingService: TaskingService
  ) { }

  @Input() template: TaskTemplate;
  @Input() isEditable = true;

  hasDrafts = false;
  draftItems: TemplateDraft[] = [];
  draftIcon = faEdit;

  showVersions = false;
    
  //  Icons
  addIcon = faPlus;
  removeIcon = faTimes;

  //  Subscriptions
  subscriptions: Subscription[] = [];

  async ngOnInit(): Promise<void> {
    var items = this.templateUpdateService.loadDrafts();
    if (this.template) {
      this.templateUpdateService.load(this.template);
    }

    if (this.template?.id) {
      this.draftItems = items.filter(x => x.id == this.template.id);
    } else {
      this.draftItems = items.filter(x => x.isNew);
    }
    this.hasDrafts = this.draftItems.length > 0;  

    this.subscriptions.push(this.templateUpdateService.currentTemplate.subscribe(x => this.templateUpdated(x)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  loadDraft(draft: TemplateDraft) {
    this.template = draft.template;
    this.hasDrafts = false;
  }

  discardDraft(draft: TemplateDraft) {
    this.templateUpdateService.discardDraft(draft.id);
    this.draftItems = this.draftItems.filter(x => x.id !== draft.id);
    this.hasDrafts = this.draftItems.length > 0;
  }

  templateUpdated = (template: TaskTemplate) => this.templateUpdateService.saveDraft(this.template);

  async changeVersion(version: TaskTemplateVersionItem) {
    this.template = await this.taskingService.getTemplate({id: version.id}).toPromise();
    this.templateUpdateService.load(this.template);
  }
}

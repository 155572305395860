import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSplit } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'task-define-check-condition',
  templateUrl: './define-check-condition.component.html',
  styleUrls: ['./define-check-condition.component.scss']
})
export class DefineCheckConditionComponent implements OnInit {

  constructor() { }

  @Input() definition: any;
  @Input() item: any;
  @Input() isEditable = true;
  @Output() itemChange = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();

  conditionIcon = faSplit;

  ngOnInit(): void {}

  onRemove = () => this.remove.emit(this.item);
  setYes() {
    this.item.condition.answer = true;
    this.itemChange.emit(this.item);
  }
  setNo() {
    this.item.condition.answer = false;
    this.itemChange.emit(this.item);
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TaskActionEnum } from '../../../utils/task-actions-enum.model';
import { AvailableActionItem } from '../../../utils/available-action-item.modal';
import { TemplateUpdateService } from '../../../../task-templates/services/template-update-service';

@Component({
  selector: 'task-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.scss']
})
export class SelectActionComponent implements OnInit {

  constructor(
    private readonly templateUpdateService : TemplateUpdateService
  ) { }

  @Output() selectedAction = new EventEmitter<TaskActionEnum>();

  actions = TaskActionEnum;
  selAction: TaskActionEnum;
  availableActions: AvailableActionItem[] = [];
  loaded = false;

  async ngOnInit(): Promise<void> {
    this.availableActions = await this.templateUpdateService.getAvailableActions();
    this.loaded = true;
  }

  actionMessage(action: TaskActionEnum) {
    const item = this.availableActions.find(x => x.action === action);
    return item.message;
  }
  canSelect(action: TaskActionEnum) {
    const item = this.availableActions.find(x => x.action === action);
    return item.isAvailable;
  }
  isSelected = (action: TaskActionEnum) => this.selAction === action;
  select = (action: TaskActionEnum) =>  {
    this.selAction = action;
    this.selectedAction.emit(action);
  }
}

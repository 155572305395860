import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faArrowDown, faArrowUp, faBars, faChevronCircleDown, faChevronCircleRight, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SelectActionModalComponent } from '../select-action-modal/select-action-modal.component';
import { TaskActionEnum } from '../../../utils/task-actions-enum.model';
import { TaskTemplateSection } from '../../../../api/models/Lukla/Inventory/task-template-section';
import { OttoTasksConfig } from '../../../../ottotasks-core/ottotasks.config';

@Component({
  selector: 'task-template-define-section',
  templateUrl: './define-section.component.html',
  styleUrls: ['./define-section.component.scss']
})
export class DefineSectionComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    private readonly config: OttoTasksConfig  
  ) { }

  //  INPUTS/OUTPUTS
  @Input() section: TaskTemplateSection;
  @Input() isEditable = true;
  @Output() sectionChange = new EventEmitter<TaskTemplateSection>();
  @Output() sectionDeleted = new EventEmitter<TaskTemplateSection>();
  @Output() moveSectionUp = new EventEmitter<TaskTemplateSection>();
  @Output() moveSectionDown = new EventEmitter<TaskTemplateSection>();

  //  TINYMCE CONFIG
  tinymcekey;
  tinymceinit = {
    menubar: false,
    plugins: 'lists',
    toolbar: 'undo redo | bold italic | numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent'
  }

  //  FLAGS
  confirmDelete = false;
  editingName = false;
  editingDescription = false;
  isCollapsed = false;

  //  ICONS
  deleteIcon = faTimes;
  dragIcon = faBars;
  upIcon = faArrowUp;
  downIcon = faArrowDown;
  addIcon = faPlus;
  newIcon = faSparkles;
  collapseIcon = faChevronCircleDown;
  expandIcon = faChevronCircleRight;

  //collapse
  tooltipText: string = this.isCollapsed ? 'Expand' : 'Collapse';

  //  MODAL
  bsModalRef: BsModalRef;
  action = TaskActionEnum;

  ngOnInit(): void {
    this.tinymcekey = this.config.tinymcekey;
    this.editingName = !this.section.name && this.isEditable;
    this.editingDescription = !this.section.description && this.isEditable;
  }

  editName() {
    if (this.isEditable) this.editingName = true;
  }

  blurName() {
    if (this.section.name) this.editingName = false;
  }

  editDescription() {
    if (this.isEditable) this.editingDescription = true;
  }

  blurDescription() {
    if (this.section.description) this.editingDescription = false;
  }

  deleteSection = () => this.sectionDeleted.emit(this.section);
  confirmingDelete = () => this.confirmDelete = true;
  moveup = () => this.moveSectionUp.emit(this.section);
  movedown = () => this.moveSectionDown.emit(this.section);

  //  Steps
  addStep() {
    this.bsModalRef = this.modalService.show(SelectActionModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.selectAction.subscribe((action: TaskActionEnum) => {
      this.section.steps.push({
        order: this.section.steps.length + 1,
        actionType: <any>action,
        isRequired: <any>action === TaskActionEnum.Inventory ? true: false,
      });
      this.bsModalRef.hide();
      this.sectionChange.emit(this.section);
    });
  }

  canAddStep = () => this.section.steps.length === 0 || this.section.steps[this.section.steps.length-1].actionType !== <any>TaskActionEnum.End;

  deleteStep = (i: number) =>  {
    this.section.steps.splice(i, 1);
    this.sectionChange.emit(this.section);
  }
  moveStepUp = (i: number) => {
    var step = this.section.steps[i];
    this.section.steps.splice(i, 1);
    this.section.steps.splice(i - 1, 0, step);
    this.sectionChange.emit(this.section);
  }
  moveStepDown = (i: number) => {
    var step = this.section.steps[i];
    this.section.steps.splice(i, 1);
    this.section.steps.splice(i + 1, 0, step);
    this.sectionChange.emit(this.section);
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.tooltipText = this.isCollapsed ? 'Expand' : 'Collapse';
  }
}

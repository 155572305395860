/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { associate } from '../fn/tag/associate';
import { Associate$Params } from '../fn/tag/associate';
import { download } from '../fn/tag/download';
import { Download$Params } from '../fn/tag/download';
import { findTag } from '../fn/tag/find-tag';
import { FindTag$Params } from '../fn/tag/find-tag';
import { getTag } from '../fn/tag/get-tag';
import { GetTag$Params } from '../fn/tag/get-tag';
import { Response as LuklaInventorySearchTagsResponse } from '../models/Lukla/Inventory/SearchTags/response';
import { TagItem as LuklaInventoryTagItem } from '../models/Lukla/Inventory/tag-item';
import { printPackage } from '../fn/tag/print-package';
import { PrintPackage$Params } from '../fn/tag/print-package';
import { searchTags } from '../fn/tag/search-tags';
import { SearchTags$Params } from '../fn/tag/search-tags';
import { view } from '../fn/tag/view';
import { View$Params } from '../fn/tag/view';

@Injectable({ providedIn: 'root' })
export class TagService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findTag()` */
  static readonly FindTagPath = '/tags/find/{identifier}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTag$Response(params: FindTag$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryTagItem>> {
    return findTag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTag(params: FindTag$Params, context?: HttpContext): Observable<LuklaInventoryTagItem> {
    return this.findTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryTagItem>): LuklaInventoryTagItem => r.body)
    );
  }

  /** Path part for operation `getTag()` */
  static readonly GetTagPath = '/tags/get/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTag$Response(params: GetTag$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryTagItem>> {
    return getTag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTag(params: GetTag$Params, context?: HttpContext): Observable<LuklaInventoryTagItem> {
    return this.getTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventoryTagItem>): LuklaInventoryTagItem => r.body)
    );
  }

  /** Path part for operation `view()` */
  static readonly ViewPath = '/tags/view/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `view()` instead.
   *
   * This method doesn't expect any request body.
   */
  view$Response(params: View$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return view(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `view$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  view(params: View$Params, context?: HttpContext): Observable<Blob> {
    return this.view$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `download()` */
  static readonly DownloadPath = '/tags/download/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `download()` instead.
   *
   * This method doesn't expect any request body.
   */
  download$Response(params: Download$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return download(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `download$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  download(params: Download$Params, context?: HttpContext): Observable<Blob> {
    return this.download$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `searchTags()` */
  static readonly SearchTagsPath = '/tags/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTags()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTags$Response(params?: SearchTags$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventorySearchTagsResponse>> {
    return searchTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchTags$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTags(params?: SearchTags$Params, context?: HttpContext): Observable<LuklaInventorySearchTagsResponse> {
    return this.searchTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaInventorySearchTagsResponse>): LuklaInventorySearchTagsResponse => r.body)
    );
  }

  /** Path part for operation `associate()` */
  static readonly AssociatePath = '/tags/associate/asset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associate$Response(params?: Associate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return associate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  associate(params?: Associate$Params, context?: HttpContext): Observable<void> {
    return this.associate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `printPackage()` */
  static readonly PrintPackagePath = '/tags/print/package/{batchId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  printPackage$Response(params: PrintPackage$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return printPackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `printPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printPackage(params: PrintPackage$Params, context?: HttpContext): Observable<Blob> {
    return this.printPackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-deletion-confirmation-modal',
  templateUrl: './deletion-confirmation-modal.component.html',
  styleUrls: ['./deletion-confirmation-modal.component.scss']
})
export class DeletionConfirmationModalComponent implements OnInit {

  constructor(
    public BsModalRef: BsModalRef,
  ) { }

  // Inputs/Outputs
  @Input() headerText: string;
  @Input() confirmationBody: string;
  @Input() identifier: string;
  @Output() close = new EventEmitter();
  @Output() confirmed = new EventEmitter();

  //  Icons
  headerIcon = faTrash;
  processingIcon = faCircleNotch;
  deleteIcon = faTrash;

  //  Models
  validationIdentifier: string;

  //  Flags
  isSaving = false;


  validated = () => this.validationIdentifier == this.identifier;

  ngOnInit(): void { }

  confirm() {
    this.isSaving = true;
    this.confirmed.emit();
  }

  cancel() {
    this.BsModalRef.hide();
  }
}

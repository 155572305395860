<div class="row">
  <div class="col-xs-12" [class.col-xxl-3]="showList" *ngIf="showList">
      <div class="filters">
          <task-template-filters [filtered]="filtered" [total]="total"
              (filterChanged)="filterChanged($event)"></task-template-filters>
          <div *ngFor="let template of templates">
              <template-list-item [template]="template" (selected)="selectTemplate($event)"></template-list-item>
          </div>
      </div>
  </div>
  <div class="col-xs-12" [class.col-xxl-8]="showDetails" *ngIf="showDetails">
      <div class="m-3" *ngIf="selectedTemplate">
          <div class="sticky d-flex align-items-center justify-content-center">
              <div class="header bg-primary text-light p-3">
                  <h3><fa-icon class="me-3" [icon]="selectedIcon"></fa-icon> Selected Template</h3>
                  <p class="lead">Please review the selected template, if this is the template you'd like to choose
                      for the adhoc task select, please confirm below</p>
                      <button class="w-50 m-auto btn btn-outline-light" (click)="confirm()">Confirm Selected</button>
              </div>
          </div>
          <div class="d-flex" *ngIf="templateDetails">
              <div class="flex-grow-1">
                  <template-details [(template)]="templateDetails" [isEditable]="isEditable"></template-details>
              </div>
          </div>
      </div>
      <div class="m-3" *ngIf="!selectedTemplate">
          <div class="alert alert-info d-flex p-5">
              <fa-icon class="fs-2" [icon]="missingTemplateIcon"></fa-icon>
              <div class="ms-4">
                  <h3>Select a Template</h3>
                  <p class="lead">Select a template from the list to see the details and structure of the template</p>
              </div>
          </div>
      </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSkullCrossbones } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { ExceptionSeverity } from '../../../utils/exception-severity.enum';
import { TaskTemplateExceptionDefinition } from '../../../../api/models/Lukla/Inventory/task-template-exception-definition';

@Component({
  selector: 'task-define-exception',
  templateUrl: './define-exception.component.html',
  styleUrls: ['./define-exception.component.scss']
})
export class DefineExceptionComponent implements OnInit {

  constructor() { }

  @Input() isEditable = true;
  @Input() exception: TaskTemplateExceptionDefinition;
  @Output() exceptionChange = new EventEmitter<TaskTemplateExceptionDefinition>();

  severities = ['Low', 'Critical'];

  ngOnInit(): void {
    
  }

  exceptionIcon = () => {
    return this.exception.severity === <any>ExceptionSeverity.Critical ? faSkullCrossbones : faExclamationCircle
  }
  exceptionClass = () => this.exception.severity === <any>ExceptionSeverity.Critical ? 'bg-danger text-white' : 'bg-warning';
  exceptionName = () => this.exception.severity === <any>ExceptionSeverity.Critical ? 'Critical Exception' : 'Exception';

  tinymceinit = {
    menubar: false,
    plugins: 'lists',
    height: 200,
    content_style: "body {font-size: 11pt;}",
    toolbar: 'undo redo | bold italic | numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent'
  }
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assignTechnician } from '../fn/division/assign-technician';
import { AssignTechnician$Params } from '../fn/division/assign-technician';
import { getDivisions } from '../fn/division/get-divisions';
import { GetDivisions$Params } from '../fn/division/get-divisions';
import { DivisionView as LuklaInventoryDivisionView } from '../models/Lukla/Inventory/division-view';
import { technicianDivisions } from '../fn/division/technician-divisions';
import { TechnicianDivisions$Params } from '../fn/division/technician-divisions';
import { upsertDivision } from '../fn/division/upsert-division';
import { UpsertDivision$Params } from '../fn/division/upsert-division';

@Injectable({ providedIn: 'root' })
export class DivisionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDivisions()` */
  static readonly GetDivisionsPath = '/divisions/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDivisions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDivisions$Response(params?: GetDivisions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryDivisionView>>> {
    return getDivisions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDivisions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDivisions(params?: GetDivisions$Params, context?: HttpContext): Observable<Array<LuklaInventoryDivisionView>> {
    return this.getDivisions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryDivisionView>>): Array<LuklaInventoryDivisionView> => r.body)
    );
  }

  /** Path part for operation `upsertDivision()` */
  static readonly UpsertDivisionPath = '/divisions/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertDivision()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertDivision$Response(params?: UpsertDivision$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertDivision(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertDivision$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertDivision(params?: UpsertDivision$Params, context?: HttpContext): Observable<void> {
    return this.upsertDivision$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `technicianDivisions()` */
  static readonly TechnicianDivisionsPath = '/divisions/technician/assigned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `technicianDivisions()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicianDivisions$Response(params?: TechnicianDivisions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryDivisionView>>> {
    return technicianDivisions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `technicianDivisions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicianDivisions(params?: TechnicianDivisions$Params, context?: HttpContext): Observable<Array<LuklaInventoryDivisionView>> {
    return this.technicianDivisions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryDivisionView>>): Array<LuklaInventoryDivisionView> => r.body)
    );
  }

  /** Path part for operation `assignTechnician()` */
  static readonly AssignTechnicianPath = '/divisions/technician/assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignTechnician()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignTechnician$Response(params?: AssignTechnician$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assignTechnician(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignTechnician$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignTechnician(params?: AssignTechnician$Params, context?: HttpContext): Observable<void> {
    return this.assignTechnician$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

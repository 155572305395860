/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { campaignsExportContentPost } from '../fn/campaign-export/campaigns-export-content-post';
import { CampaignsExportContentPost$Params } from '../fn/campaign-export/campaigns-export-content-post';
import { campaignsExportIdCategoriesGet } from '../fn/campaign-export/campaigns-export-id-categories-get';
import { CampaignsExportIdCategoriesGet$Params } from '../fn/campaign-export/campaigns-export-id-categories-get';
import { campaignsExportIdDataGet } from '../fn/campaign-export/campaigns-export-id-data-get';
import { CampaignsExportIdDataGet$Params } from '../fn/campaign-export/campaigns-export-id-data-get';
import { campaignsExportIdTaskDataGet } from '../fn/campaign-export/campaigns-export-id-task-data-get';
import { CampaignsExportIdTaskDataGet$Params } from '../fn/campaign-export/campaigns-export-id-task-data-get';
import { campaignsExportIdVerifyActionsGet } from '../fn/campaign-export/campaigns-export-id-verify-actions-get';
import { CampaignsExportIdVerifyActionsGet$Params } from '../fn/campaign-export/campaigns-export-id-verify-actions-get';
import { TemplateVerifyAction as LuklaInventoryTemplateVerifyAction } from '../models/Lukla/Inventory/template-verify-action';

@Injectable({ providedIn: 'root' })
export class CampaignExportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `campaignsExportContentPost()` */
  static readonly CampaignsExportContentPostPath = '/campaigns/export/content';

  /**
   * export-content.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignsExportContentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  campaignsExportContentPost$Response(params?: CampaignsExportContentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return campaignsExportContentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * export-content.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `campaignsExportContentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  campaignsExportContentPost(params?: CampaignsExportContentPost$Params, context?: HttpContext): Observable<Blob> {
    return this.campaignsExportContentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `campaignsExportIdDataGet()` */
  static readonly CampaignsExportIdDataGetPath = '/campaigns/export/{id}/data';

  /**
   * export-data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignsExportIdDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdDataGet$Response(params: CampaignsExportIdDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return campaignsExportIdDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * export-data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `campaignsExportIdDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdDataGet(params: CampaignsExportIdDataGet$Params, context?: HttpContext): Observable<Blob> {
    return this.campaignsExportIdDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `campaignsExportIdTaskDataGet()` */
  static readonly CampaignsExportIdTaskDataGetPath = '/campaigns/export/{id}/task-data';

  /**
   * export-task-data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignsExportIdTaskDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdTaskDataGet$Response(params: CampaignsExportIdTaskDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return campaignsExportIdTaskDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * export-task-data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `campaignsExportIdTaskDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdTaskDataGet(params: CampaignsExportIdTaskDataGet$Params, context?: HttpContext): Observable<Blob> {
    return this.campaignsExportIdTaskDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `campaignsExportIdVerifyActionsGet()` */
  static readonly CampaignsExportIdVerifyActionsGetPath = '/campaigns/export/{id}/verify-actions';

  /**
   * get-verify-actions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignsExportIdVerifyActionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdVerifyActionsGet$Response(params: CampaignsExportIdVerifyActionsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTemplateVerifyAction>>> {
    return campaignsExportIdVerifyActionsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * get-verify-actions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `campaignsExportIdVerifyActionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdVerifyActionsGet(params: CampaignsExportIdVerifyActionsGet$Params, context?: HttpContext): Observable<Array<LuklaInventoryTemplateVerifyAction>> {
    return this.campaignsExportIdVerifyActionsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTemplateVerifyAction>>): Array<LuklaInventoryTemplateVerifyAction> => r.body)
    );
  }

  /** Path part for operation `campaignsExportIdCategoriesGet()` */
  static readonly CampaignsExportIdCategoriesGetPath = '/campaigns/export/{id}/categories';

  /**
   * get-categories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `campaignsExportIdCategoriesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdCategoriesGet$Response(params: CampaignsExportIdCategoriesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryTemplateVerifyAction>>> {
    return campaignsExportIdCategoriesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * get-categories.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `campaignsExportIdCategoriesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  campaignsExportIdCategoriesGet(params: CampaignsExportIdCategoriesGet$Params, context?: HttpContext): Observable<Array<LuklaInventoryTemplateVerifyAction>> {
    return this.campaignsExportIdCategoriesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryTemplateVerifyAction>>): Array<LuklaInventoryTemplateVerifyAction> => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface CampaignsExportIdDataGet$Params {
  id: string;
  rebuild?: boolean;
  showOutstanding?: boolean;
}

export function campaignsExportIdDataGet(http: HttpClient, rootUrl: string, params: CampaignsExportIdDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
  const rb = new RequestBuilder(rootUrl, campaignsExportIdDataGet.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('rebuild', params.rebuild, {});
    rb.query('showOutstanding', params.showOutstanding, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Blob>;
    })
  );
}

campaignsExportIdDataGet.PATH = '/campaigns/export/{id}/data';

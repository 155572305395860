<div class="end-container p-4 d-flex bg-danger text-white text-start">
    <h3>
        <fa-icon [icon]="stopIcon"></fa-icon>
    </h3>
    <div class="ms-3">
        <h4>Stop Task</h4>
        <p class="my-2">Task can end and no further steps are required</p>
        <button *ngIf="isEditable" class="w-50 btn btn-outline-light" (click)="onRemove()" ><fa-icon class="me-2" [icon]="removeIcon"></fa-icon>Remove</button>
    </div>
</div>    

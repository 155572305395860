export const environment = {
  api: 'https://gateways.inventory.dev.lukla.com',
  production: true,
  identityEndpoint: 'https://identity.dev.lukla.com',
  appName: 'Inventory',
  luklaDocumentUrl: 'https://app.lukla.co/details',
  googleKey: 'AIzaSyCcp7_rTs3ENNwKE69c91DtOC7bvR3KoX8',
  appId: '30c4e957-3856-4312-9eb8-7d11ee1ccd56',
  notificationEmail: 'chadk@lukla.com',
  contentUploadEndpoint: 'https://api.inventory.dev.lukla.com/content/upload/version',
  tempUploadEndpoint: 'https://api.inventory.dev.lukla.com/content/upload/file',
  tinymcekey: 's9mgynkayyur88nt7gtwowcbjchlfbb4x8cj554p1w8k3kmr',
  tasksUrl: 'https://tasks.dev.lukla.com'
};
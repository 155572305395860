/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Response as LuklaProfileGetCompaniesResponse } from '../models/Lukla/Profile/GetCompanies/response';
import { Response as LuklaProfileHasAccessResponse } from '../models/Lukla/Profile/HasAccess/response';
import { ProfileView as LuklaProfileProfileView } from '../models/Lukla/Profile/profile-view';
import { Query as LuklaProfileSearchQuery } from '../models/Lukla/Profile/Search/query';
import { Response as LuklaProfileSearchResponse } from '../models/Lukla/Profile/Search/response';
import { RoleQuery as LuklaProfileSearchRoleQuery } from '../models/Lukla/Profile/Search/role-query';
import { StatusEnum as LuklaProfileStatusEnum } from '../models/Lukla/Profile/status-enum';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation current
   */
  static readonly CurrentPath = '/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `current()` instead.
   *
   * This method doesn't expect any request body.
   */
  current$Response(params?: {
  }): Observable<StrictHttpResponse<LuklaProfileProfileView>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.CurrentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LuklaProfileProfileView>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `current$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  current(params?: {
  }): Observable<LuklaProfileProfileView> {

    return this.current$Response(params).pipe(
      map((r: StrictHttpResponse<LuklaProfileProfileView>) => r.body as LuklaProfileProfileView)
    );
  }

  /**
   * Path part for operation get
   */
  static readonly GetPath = '/profile/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<LuklaProfileProfileView>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LuklaProfileProfileView>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: {
    id: string;
  }): Observable<LuklaProfileProfileView> {

    return this.get$Response(params).pipe(
      map((r: StrictHttpResponse<LuklaProfileProfileView>) => r.body as LuklaProfileProfileView)
    );
  }

  /**
   * Path part for operation getByLogin
   */
  static readonly GetByLoginPath = '/profile/login/{login}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByLogin$Response(params: {
    login: string;
  }): Observable<StrictHttpResponse<LuklaProfileProfileView>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetByLoginPath, 'get');
    if (params) {
      rb.path('login', params.login, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LuklaProfileProfileView>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByLogin(params: {
    login: string;
  }): Observable<LuklaProfileProfileView> {

    return this.getByLogin$Response(params).pipe(
      map((r: StrictHttpResponse<LuklaProfileProfileView>) => r.body as LuklaProfileProfileView)
    );
  }

  /**
   * Path part for operation hasAccess
   */
  static readonly HasAccessPath = '/profile/access/{packageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAccess$Response(params: {
    packageId: string;
  }): Observable<StrictHttpResponse<LuklaProfileHasAccessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.HasAccessPath, 'get');
    if (params) {
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LuklaProfileHasAccessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `hasAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasAccess(params: {
    packageId: string;
  }): Observable<LuklaProfileHasAccessResponse> {

    return this.hasAccess$Response(params).pipe(
      map((r: StrictHttpResponse<LuklaProfileHasAccessResponse>) => r.body as LuklaProfileHasAccessResponse)
    );
  }

  /**
   * Path part for operation getCompanies
   */
  static readonly GetCompaniesPath = '/profile/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies$Response(params?: {
  }): Observable<StrictHttpResponse<LuklaProfileGetCompaniesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.GetCompaniesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LuklaProfileGetCompaniesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies(params?: {
  }): Observable<LuklaProfileGetCompaniesResponse> {

    return this.getCompanies$Response(params).pipe(
      map((r: StrictHttpResponse<LuklaProfileGetCompaniesResponse>) => r.body as LuklaProfileGetCompaniesResponse)
    );
  }

  /**
   * Path part for operation switchCompany
   */
  static readonly SwitchCompanyPath = '/profile/switch/companies/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `switchCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  switchCompany$Response(params: {
    companyId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.SwitchCompanyPath, 'post');
    if (params) {
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `switchCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  switchCompany(params: {
    companyId: string;
  }): Observable<void> {

    return this.switchCompany$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation search
   */
  static readonly SearchPath = '/profile/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Response(params?: {
    SearchTerms?: string;
    Roles?: Array<LuklaProfileSearchRoleQuery>;
    Status?: Array<LuklaProfileStatusEnum>;
    Page?: number;
    Take?: number;
  }): Observable<StrictHttpResponse<LuklaProfileSearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.SearchPath, 'get');
    if (params) {
      rb.query('SearchTerms', params.SearchTerms, {});
      rb.query('Roles', params.Roles, {});
      rb.query('Status', params.Status, {});
      rb.query('Page', params.Page, {});
      rb.query('Take', params.Take, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LuklaProfileSearchResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  search(params?: {
    SearchTerms?: string;
    Roles?: Array<LuklaProfileSearchRoleQuery>;
    Status?: Array<LuklaProfileStatusEnum>;
    Page?: number;
    Take?: number;
  }): Observable<LuklaProfileSearchResponse> {

    return this.search$Response(params).pipe(
      map((r: StrictHttpResponse<LuklaProfileSearchResponse>) => r.body as LuklaProfileSearchResponse)
    );
  }

  /**
   * Path part for operation query
   */
  static readonly QueryPath = '/profile/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `query()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  query$Response(params?: {
    body?: LuklaProfileSearchQuery
  }): Observable<StrictHttpResponse<LuklaProfileSearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.QueryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LuklaProfileSearchResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `query$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  query(params?: {
    body?: LuklaProfileSearchQuery
  }): Observable<LuklaProfileSearchResponse> {

    return this.query$Response(params).pipe(
      map((r: StrictHttpResponse<LuklaProfileSearchResponse>) => r.body as LuklaProfileSearchResponse)
    );
  }

}

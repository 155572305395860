import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './shared/pages/error/error.component';
import { NoAccessComponent } from './shared/pages/no-access/no-access.component';
import { UnauthorizedComponent } from './shared/pages/unauthorized/unauthorized.component';
import { UnknownComponent } from './shared/pages/unknown/unknown.component';

const routes: Routes = [
  {
    path: 'session',
    loadChildren: () =>
      import('./auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: 'details',
    loadChildren: () =>
      import('./inventory-item-pages/inventory-item-pages.module').then(mod => mod.InventoryItemPagesModule)
  },
  {
    path: 'locations',
    loadChildren:() =>
      import('./location-pages/location-pages.module').then(mod => mod.LocationPagesModule)
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./campaigns/campaigns.module').then(mod => mod.CampaignsModule)
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('./task-pages/task-pages.module').then(mod => mod.TaskPagesModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./features/pages/report-page.module').then(mod => mod.ReportPageModule)
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'not-found', component: UnknownComponent },
  {
    path: '',
    loadChildren: () =>
      import('./inventory-list/inventory-list.module').then(mod => mod.InventoryListModule)
  },
  { path: '**', component: UnknownComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

// export const authCodeFlowConfig: AuthConfig = {
//   issuer: environment.identityEndpoint,
//   redirectUri: window.location.origin + '/session/login',
//   silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
//   tokenEndpoint: `${environment.identityEndpoint}/connect/token`,
//   useSilentRefresh: true,
//   clientId: 'lukla_assets_app_client',
//   responseType: 'code',
//   requireHttps: false, //TODO: fix and make https required
//   scope: 'openid profile offline_access lukla_profile_api lukla_assets_api lukla_ingestion_api',
//   showDebugInformation: true,
//   sessionChecksEnabled: true
// };

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.identityEndpoint,
  redirectUri: window.location.origin + '/session/login',
  useSilentRefresh: false,
  clientId: 'lukla_inventory_app_client',
  responseType: 'code',
  requireHttps: false, //TODO: fix and make https required
  scope: 'openid profile offline_access lukla_profile_api lukla_auditing_api lukla_inventory_api lukla_task_api lukla_notification_api',
  showDebugInformation: true,
  sessionChecksEnabled: false
};

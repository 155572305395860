import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/api/services/profile.service';
import { CurrentUserService } from '../user.service';
import { Item as CompanyItem } from 'src/app/api/models/Lukla/Profile/GetCompanies/item';
import { faBuilding, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ProfileView } from 'src/app/api/models/Lukla/Profile/profile-view';

@Component({
  selector: 'lukla-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnInit {

  constructor(
    private currentUserService: CurrentUserService,
    private profileService: ProfileService
  ) { }

  //  Icons
  companyIcon = faBuilding;
  selectedIcon = faCheck;

  currentUser: ProfileView;
  companies: CompanyItem[];

  async ngOnInit() {
    this.currentUser = await this.currentUserService.get();
    const companyResponse = await this.profileService.getCompanies().toPromise();
    this.companies = companyResponse.items || [];
  }

  //  Company List
  isSelected = (c: CompanyItem) => this.currentUser.companyId === c.id;
  changeCompany = async (c: CompanyItem) => await this.currentUserService.changeCompany(c);
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { faLocationCrosshairs, faLocationDot, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TaskTemplateTargetEnum } from '../../utils/task-template-target-enum';
import { TemplateCharacteristic } from '../../../api/models/Lukla/Inventory/template-characteristic';
import { OttoTasksConfig } from '../../../ottotasks-core/ottotasks.config';
import { TaskTemplate } from '../../../api/models/Lukla/Inventory/task-template';

@Component({
  selector: 'task-template-definition',
  templateUrl: './template-definition.component.html',
  styleUrls: ['./template-definition.component.scss']
})
export class TemplateDefinitionComponent implements OnInit {

  constructor(
    private readonly config: OttoTasksConfig
  ) { }

  @Input() template: TaskTemplate;
  @Output() templateChange = new EventEmitter<TaskTemplate>();
  @Input() isEditable = true;


  //  TINYMCE CONFIG
  tinymcekey = '';
  tinymceinit = {
    menubar: false,
    plugins: 'lists',
    toolbar: 'undo redo | bold italic | numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent'
  }

  //  FLAGS
  isNew = false;
  editingName = false;
  editingDescription = false;

  //  Icons
  newIcon = faSparkles;
  removeIcon = faTimes;
  itemIcon = faLocationCrosshairs;
  locationIcon = faLocationDot;

  ngOnInit(): void {
    this.tinymcekey = this.config.tinymcekey;
    this.isNew = !this.template.name;
    this.editingName = !this.template.name;
    this.editingDescription = !this.template.explanation;
  }


  editName() {
    if (this.isEditable) this.editingName = true;
  }

  stopEditName() {
    if (!this.isNew || this.template.name) {
      this.editingName = false;
      this.templateChange.emit(this.template);
    }
  }

  editDescription() {
    if (this.isEditable) this.editingDescription = true;
  }

  stopEditDescription() {
    if (!this.isNew || this.template.explanation) {
      this.editingDescription = false;
      this.templateChange.emit(this.template);
    }
  }

  templateTargets = TaskTemplateTargetEnum;
  targets(targetType: any) {
    return this.template?.targets?.includes(targetType) ?? false;
  }

  toggleTarget(targetType: TaskTemplateTargetEnum) {
    if (!this.template.targets) this.template.targets = [];
    if (!this.targets(targetType)) {
      this.template.targets.push(<any>targetType);
    }
    else {
      this.template.targets = this.template.targets.filter(x => x !== <any>targetType);
    }
  }

  targetIcon(targetType: any) {
    switch (<TaskTemplateTargetEnum>targetType) {
      case TaskTemplateTargetEnum.Item: return this.itemIcon
      case TaskTemplateTargetEnum.Location: return this.locationIcon;
    }
  }

  characteristicName: string;
  characteristicValue: string;
  addCharacteristic() {
    if (!this.characteristicName || !this.characteristicValue) return;

    this.template.characteristics.push({
      name: this.characteristicName,
      value: this.characteristicValue
    });
    this.templateChange.emit(this.template);
    this.characteristicName = null;
    this.characteristicValue = null;
  }

  removeCharacteristic(characteristic: TemplateCharacteristic) {
    this.template.characteristics = this.template.characteristics.filter(x => x.name !== characteristic.name);
    this.templateChange.emit(this.template);
  }

  toggleAdhoc() {
    this.template.allowAdhoc = !this.template.allowAdhoc;
    this.templateChange.emit(this.template);
  }
}

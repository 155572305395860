import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from '../../api/services/profile.service';
import { ProfileView } from 'src/app/api/models/Lukla/Profile/profile-view';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'lukla-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  standalone: true,
  imports:[CommonModule]
})
export class UserComponent implements OnInit {
  constructor(
    private profileService: ProfileService
  ) { }

  @Input() user: ProfileView;
  @Input() subtext: string;
  @Input() small: boolean = false;
  @Input() flip: boolean = false;
  @Input() slim: boolean = false;
  @Input() avatarOnly: boolean = false;
  @Input() bland: boolean = false;
  @Input() nameOnly: boolean = false;

  private _email: string;
  @Input() set email(value: string) {
      this._email = value;
      this.loadByLogin();
  }
  get email(): string {
    return this._email;
  }

  private _userId: string;
  @Input() set userId(value: string) {
      this._userId = value;
      this.loadProfile();
  }
  get userId(): string {
    return this._userId;
  }

  async ngOnInit() {
    if (!this.user && this.userId) {
      await this.loadProfile();
    };
    if (!this.user && this.email) {
      await this.loadByLogin();
    }
    this.color = this.avatarColor();
  }

  color: string
  colours = [
    "rgb(26,188,156)", "rgb(46,204,113)", "rgb(52,152,219)", "rgb(155,89,182)", "rgb(52,73,94)", "rgb(22,160,133)",
    "rgb(241,196,15)", "rgb(230,126,34)", "rgb(231,76,60)", "rgb(149,165,166)", "rgb(243,156,18)", "rgb(211,84,0)"
  ];

  async loadProfile() {
    if (!this.userId) return;
    this.user = await this.profileService.get({id: this.userId}).toPromise();
  }

  async loadByLogin() {
    if (!this.email) return;
    this.user = await this.profileService.getByLogin({login: this.email}).toPromise();
  }

  avatarColor() {
    return this.bland 
      ? "rgb(255,250,250)" 
      : this.colours[Math.floor(Math.random() * (this.colours.length))];
  }

  avatarName() {
    let arr;
    if (this.user) {
      arr = (this.user.name|| this.user.userName || "").split(" ");
    } else {
      arr = [this.email]
    }
    let str: string;
    if (arr.length > 1)
      str = `${arr[0].charAt(0)}${arr[1].charAt(0)}`
    else
      str = `${arr[0].charAt(0)}${arr[0].charAt(1)}`
    return str
  }
}

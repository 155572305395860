<div class="condition d-flex text-light">
    <fa-icon class="h3" [icon]="conditionIcon"></fa-icon>
    <div class="ms-3 flex-fill">
        <div class="d-flex justify-content-between align-items-center">
            <h4>Condition</h4>
            <button *ngIf="isEditable" class="btn btn-sm btn-danger mb-2" (click)="onRemove()">Remove</button>
        </div>
        <div *ngIf="isEditable">
            <div class="d-flex align-items-center">
                <span>When</span>
                <span class="ms-2 fw-bold"><span *ngIf="parentName">{{parentName}} > </span>{{name}}</span>    
                <div class="flex-fill">
                    <div class="d-flex align-items-center">
                        <div class="w-50">
                            <ng-select class="ms-2 input-group-lg flex-grow-1"
                            [items]="operators"
                            bindLabel="name" 
                            bindValue="operator"           
                            [(ngModel)]="item.condition.operator"
                            placeholder="Select an operation"
                            required></ng-select>
                            <div class="invalid-feedback">
                                The operator for the conditional is required
                            </div>                
                        </div>
                        <div class="w-50">
                            <ng-select class="ms-2 input-group-lg flex-grow-1"
                            *ngIf="dataType == 'Choice'"
                            [items]="choices"
                            [(ngModel)]="item.condition.value" 
                            bindLabel="name"
                            bindValue="name"
                            placeholder="Select a choice"
                            required></ng-select>
                            <div class="invalid-feedback">
                                The choice for the conditional is required
                            </div>                
                            <input *ngIf="dataType == 'FreeText'" [(ngModel)]="item.condition.value" class="form-control" />
                            <div class="invalid-feedback">
                                The value for the conditional is required
                            </div>                
                        </div>    
                    </div>
                </div>       
            </div>
        </div>
        <div *ngIf="!isEditable">
            <h5>
                <span>When</span>
                <span class="ms-2 fw-bold">{{name}}</span>
                <span class="ms-2">{{item.condition.operator}}</span>    
                <span class="ms-2 p-2 bg-primary">{{item.condition.value}}</span>    
            </h5>
        </div>
    </div>
</div>
/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AnchorService } from './services/anchor.service';
import { AnchorAdministrationService } from './services/anchor-administration.service';
import { AreaService } from './services/area.service';
import { BulkService } from './services/bulk.service';
import { CampaignService } from './services/campaign.service';
import { CampaignAccessService } from './services/campaign-access.service';
import { CampaignExportService } from './services/campaign-export.service';
import { CampaignIndexService } from './services/campaign-index.service';
import { CommentService } from './services/comment.service';
import { ContentService } from './services/content.service';
import { DivisionService } from './services/division.service';
import { DivisionAccessService } from './services/division-access.service';
import { ExportService } from './services/export.service';
import { FlagService } from './services/flag.service';
import { ImportService } from './services/import.service';
import { IndexService } from './services/index.service';
import { ItemService } from './services/item.service';
import { ItemUpdatesService } from './services/item-updates.service';
import { LifecycleService } from './services/lifecycle.service';
import { LifecycleActionService } from './services/lifecycle-action.service';
import { LocationService } from './services/location.service';
import { LocationAnchorService } from './services/location-anchor.service';
import { LocationContentService } from './services/location-content.service';
import { LocationFlagService } from './services/location-flag.service';
import { TagService } from './services/tag.service';
import { TagAdministrationService } from './services/tag-administration.service';
import { TaskExceptionsService } from './services/task-exceptions.service';
import { TaskingService } from './services/tasking.service';
import { TaskingActionService } from './services/tasking-action.service';
import { TaskingBulkActionService } from './services/tasking-bulk-action.service';
import { TaskingMetaService } from './services/tasking-meta.service';
import { TaskScheduleService } from './services/task-schedule.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AnchorService,
    AnchorAdministrationService,
    AreaService,
    BulkService,
    CampaignService,
    CampaignAccessService,
    CampaignExportService,
    CampaignIndexService,
    CommentService,
    ContentService,
    DivisionService,
    DivisionAccessService,
    ExportService,
    FlagService,
    ImportService,
    IndexService,
    ItemService,
    ItemUpdatesService,
    LifecycleService,
    LifecycleActionService,
    LocationService,
    LocationAnchorService,
    LocationContentService,
    LocationFlagService,
    TagService,
    TagAdministrationService,
    TaskExceptionsService,
    TaskingService,
    TaskingActionService,
    TaskingBulkActionService,
    TaskingMetaService,
    TaskScheduleService,
    ApiConfiguration
  ],
})
export class OttoTasksApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<OttoTasksApiModule> {
    return {
      ngModule: OttoTasksApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: OttoTasksApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('OttoTasksApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}

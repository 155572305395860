import { EventEmitter, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { OAuthService } from "angular-oauth2-oidc";
import { ToastrService } from "ngx-toastr";
import { LuklaAudit } from "src/app/api/models/Lukla/Auditing/lukla-audit";
import { environment } from "src/environments/environment";
import { NotificationCountService } from "../features/notification-page/notification-count-service";




@Injectable({providedIn: 'root'})
export class NotificationsService{
    messageReceived = new EventEmitter<any>();
  connectionEstablished = new EventEmitter<Boolean>();

  connectionIsEstablished = false;
  private tabFocused = true;
  private _hubConnection: HubConnection;

  constructor(
    private oAuthService: OAuthService,
    private notificationCountService: NotificationCountService,
    private toastrService: ToastrService) {
    if (this.oAuthService.getAccessToken()) {
      this.load();
    }
    this.oAuthService.events.subscribe(event => {
      if (event.type !== 'token_received') return;
      this.load();
    });

    window.addEventListener('focus', event => {
      this.tabFocused = true;
    });
    window.addEventListener('blur', event => {
      this.tabFocused = false;
    });
  }

   load() {
    //  TODO: Fix issue with SignalR
    this.createConnection();
    this.startConnection();
    this.registerOnServerEvents();
  }

   createConnection() {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        var token = this.oAuthService.getAccessToken();
        return token;
      }
    };

    this._hubConnection = new HubConnectionBuilder()
      .withUrl('https://api.notification.dev.lukla.com/hubs/events', options)
      .build();
  }

   startConnection(): void {
    this._hubConnection
      .start()
      .then((val) => {
        console.log('Notification Sevice Started');
        this.connectionIsEstablished = true;
        
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        setTimeout( () => { this.startConnection(); }, 5000);
      });
  }

   registerOnServerEvents(): void {
    this._hubConnection.on('notification.success', (data: any) => {
      if (this.tabFocused) {
        
        this.sendToast(data);
      }
      else {
        this.checkBrowserNotification(data);
      }
      this.messageReceived.emit(data);
      this.notificationCountService.addEvent();
    });
  }

  private checkBrowserNotification(data: any) {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    }

    else if (Notification.permission === "granted") {
      this.sendBrowserNotification(data);
    }

    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          this.sendBrowserNotification(data);
        }
      });
    }
  }
  private sendBrowserNotification(data: any) {
    
    let parsedData:any;
    
    parsedData = JSON.parse(data.data);
    let affectedItem = parsedData.Affected.Type;
    let affectedItemId = parsedData.Affected.Id;
   
      let notification = new Notification(data.title, {
      body: data.body,
      icon: data.icon
    });
    notification.onclick = function () {
      switch (affectedItem) {
        case 'InventoryItem':
          window.open(`/details/${affectedItemId}`);
          break;
        case 'Campaign':
          window.open(`/campaigns/${affectedItemId}`);
          break;
        case 'Task':
          window.open(`/tasks/execution/${affectedItemId}`);
          break;
        default: window.open('/not-found')
      }
    };
  }

  private sendToast(data: any) {
    this.toastrService.success(data.body, data.title);
  }
}
/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createComment } from '../fn/comment/create-comment';
import { CreateComment$Params } from '../fn/comment/create-comment';
import { getComments } from '../fn/comment/get-comments';
import { GetComments$Params } from '../fn/comment/get-comments';
import { CommentView as LuklaInventoryCommentView } from '../models/Lukla/Inventory/comment-view';

@Injectable({ providedIn: 'root' })
export class CommentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createComment()` */
  static readonly CreateCommentPath = '/comments/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createComment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createComment$Response(params?: CreateComment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createComment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createComment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createComment(params?: CreateComment$Params, context?: HttpContext): Observable<void> {
    return this.createComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getComments()` */
  static readonly GetCommentsPath = '/comments/{inventoryItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments$Response(params: GetComments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaInventoryCommentView>>> {
    return getComments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComments(params: GetComments$Params, context?: HttpContext): Observable<Array<LuklaInventoryCommentView>> {
    return this.getComments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaInventoryCommentView>>): Array<LuklaInventoryCommentView> => r.body)
    );
  }

}

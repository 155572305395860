<div class="modal-body">
    <div class="popover-intro bg-danger">
        <h3 class="display-4">
            <fa-icon [icon]="headerIcon"></fa-icon> {{headerText}}
        </h3>
        <p class="lead">{{confirmationBody}}</p>
           
        <div class="d-flex">
            <input type="text" class="form-control" [(ngModel)]="validationIdentifier" placeholder="{{identifier}}">
            <button type="button" class="btn btn-outline-light text-nowrap ms-3" [disabled]="isSaving || !this.validated()" (click)="confirm()">
                <fa-icon [icon]="isSaving ? processingIcon : deleteIcon" [spin]="isSaving"></fa-icon> Permanetly Delete
            </button>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAnchorComponent } from './select-anchor/select-anchor.component';
import { SelectAnchorModalComponent } from './select-anchor/select-anchor-modal/select-anchor-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SelectAnchorComponent,
    SelectAnchorModalComponent  
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule
  ],
  exports: [
    SelectAnchorComponent,
    SelectAnchorModalComponent
  ]
})
export class AnchorsModule { }

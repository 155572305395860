<div class="step-container d-flex">
    <h3>
        <fa-icon [icon]="headerIcon"></fa-icon>
    </h3>
    <div class="ms-3 was-validated has-validation">
        <h4>File</h4>
        <p class="mt-2">This action will allow the user to upload a file from their phone</p>
        <div *ngIf="isEditable">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Category</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" [(ngModel)]="step.actionDefinition.category" (ngModelChange)="stepChange.emit(step)" required />
                    <div class="invalid-feedback">
                        A cateogry for the file is required for the action to be valid
                    </div>            
                </div>
            </div>    
            <div class="form-group row mt-2">
                <label for="noText" class="col-sm-2 col-form-label">Description</label>
                <div class="col-sm-10 editor">
                    <editor apiKey="s9mgynkayyur88nt7gtwowcbjchlfbb4x8cj554p1w8k3kmr"
                    [init]="tinymceinit" [(ngModel)]="step.actionDefinition.description" (ngModelChange)="stepChange.emit(step)"></editor>
                </div>
            </div>    
        </div>

        <div *ngIf="!isEditable && step">
            <h5>
                {{step.actionDefinition.category}}
            </h5>
            <div class="row mt-2">
                <span [innerHtml]="step.actionDefinition.description"></span>
            </div>    
        </div>
    </div>
</div>
/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createLocationContent } from '../fn/location-content/create-location-content';
import { CreateLocationContent$Params } from '../fn/location-content/create-location-content';
import { removeLocationContent } from '../fn/location-content/remove-location-content';
import { RemoveLocationContent$Params } from '../fn/location-content/remove-location-content';
import { updateLocationContent } from '../fn/location-content/update-location-content';
import { UpdateLocationContent$Params } from '../fn/location-content/update-location-content';

@Injectable({ providedIn: 'root' })
export class LocationContentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createLocationContent()` */
  static readonly CreateLocationContentPath = '/locations/content/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLocationContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLocationContent$Response(params?: CreateLocationContent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createLocationContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createLocationContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createLocationContent(params?: CreateLocationContent$Params, context?: HttpContext): Observable<void> {
    return this.createLocationContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateLocationContent()` */
  static readonly UpdateLocationContentPath = '/locations/content/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLocationContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocationContent$Response(params?: UpdateLocationContent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateLocationContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateLocationContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateLocationContent(params?: UpdateLocationContent$Params, context?: HttpContext): Observable<void> {
    return this.updateLocationContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeLocationContent()` */
  static readonly RemoveLocationContentPath = '/locations/content/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeLocationContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeLocationContent$Response(params?: RemoveLocationContent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeLocationContent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeLocationContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeLocationContent(params?: RemoveLocationContent$Params, context?: HttpContext): Observable<void> {
    return this.removeLocationContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faArrowUpFromArc, faLocationCrosshairs, faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { TaskTemplateTargetEnum } from '../../utils/task-template-target-enum';
import { TaskTemplateDefinition } from '../../../api/models/Lukla/Inventory/task-template-definition';

@Component({
  selector: 'template-list-item',
  templateUrl: './template-list-item.component.html',
  styleUrls: ['./template-list-item.component.scss']
})
export class TemplateListItemComponent implements OnInit {

  constructor() { }

  @Input() template : TaskTemplateDefinition;
  @Input() isSelected = false;
  @Output() selected = new EventEmitter<TaskTemplateDefinition>();

  ngOnInit(): void {
    if (this.template?.explanation?.length > 250) {
      this.canExpand = true;
      this.collapsedText = this.template?.explanation?.substring(0,250) + ' ...';
    }
  }

  canExpand = false;
  isExpanded = false;
  collapsedText = '';

  //  Icons
  itemIcon = faLocationCrosshairs;
  locationIcon = faLocationDot;
  adhocIcon = faArrowUpFromArc
  tagIcon = faTag;
  
  templateTargets = TaskTemplateTargetEnum;
  targets(targetType: any) {
    return this.template?.targets?.includes(targetType) ?? false;
  };

  selectTemplate() {
    this.selected.emit(this.template); 
  }

}

import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class SchedulerService {

    findGaps(periods: any[], startDate: string, endDate: string) {
        var gaps: any[] = [];
        var dates = periods.sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix());
        if (dates.length === 0) {
            var daysBetween = moment(endDate).diff(moment(startDate), 'd');
            gaps.push({
                startDate: startDate,
                endDate: endDate,
                days: daysBetween
            });
            return gaps;
        }

        //  Determine if first period starts after start date
        var firstDate = dates[0];
        if (moment(firstDate.startDate).diff(moment(startDate), 'd') > 1) {
            gaps.push({
                startDate: startDate,
                endDate: moment(firstDate.startDate).subtract(1, 'day'),
            });
        }

        //  loop through current dates to find gaps
        for (var i = 0; i < dates.length - 1; i++) {
            var first = dates[i];
            var second = dates[i + 1];
            var daysBetween = moment(second.startDate).diff(moment(first.endDate), 'd');
            if (daysBetween > 1) {
                gaps.push({
                    startDate: moment(first.endDate).add(1, 'day'),
                    endDate: moment(second.startDate).subtract(1, 'day'),
                });
            }
        }

        //  Determine if last period ends on last period date
        var lastdate = dates[dates.length - 1];
        if (moment(endDate).diff(moment(lastdate.endDate), 'd') > 1) {
            gaps.push({
                startDate: moment(lastdate.endDate).add(1, 'day'),
                endDate: endDate
            });
        }

        gaps.forEach((x:any) => {
            x.days = moment(x.endDate).diff(moment(x.startDate), 'days')
        });

        return gaps;
    }

    determineResolution(startDate: string, endDate: string) {
        var numberOfMonths = moment(endDate).diff(startDate, 'month');
        var resolution = 1;
        if (numberOfMonths > 2) resolution = 2;
        if (numberOfMonths > 12) resolution = 3;
        if (numberOfMonths > 60) resolution = 4;
        if (numberOfMonths > 120) resolution = 5;
        return resolution;
    }

    buildData(a: any[], v: any[]) {
        var dates = a.map(x => moment(x.timestamp).utc().valueOf());
        var series: any[] = [];
        dates.forEach((x, i) => {
          var item = v[i];
          if (item) item = +item.toFixed(2);
          series.push([x, item]);
        });
        return series;
      }    

      addAlpha(color: string, opacity: number): string {
        // coerce values so ti is between 0 and 1.
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
      }
    
}
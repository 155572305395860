import { EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SubscriptionEventsView } from 'src/app/api/models/Lukla/Subscriptions/subscription-events-view';
import { EventsService } from 'src/app/api/services/events.service';
import { NotificationQuery } from './paging.model';
import { GetSubscriptionEvents$Params } from 'src/app/api/fn/events/get-subscription-events';
import { NotificationCountService } from './notification-count-service';
import { faBan, faCheckSquare, faClipboardCheck, faClock, faCogs, faDoorClosed, faDoorOpen, faEnvelopeCircleCheck, faGridDividers, faLock, faPencil, faPlusSquare, faRepeat, faSignal, faSignalSlash, faTrashAlt, faUnlock, faUserCheck, faUserPlus, faUserSlash } from '@fortawesome/pro-regular-svg-icons';


@Injectable({
  providedIn: 'root'
})
export class NotificationLogicService {

  constructor(
    private readonly eventService: EventsService,
    private readonly toastrService: ToastrService,
    private readonly notificationCountService: NotificationCountService
  ) { }

  @Output() notificationChanged = new EventEmitter();


  notifications: SubscriptionEventsView[] = [];
  notification: SubscriptionEventsView;
  searchTerm: string;
  paging = false;

  async getNotifications() {

    this.notifications = await this.eventService.getSubscriptionEvents().toPromise();
    return this.notifications
  }

  async getNotificationsPaging(query: NotificationQuery) {
    const params: GetSubscriptionEvents$Params = {
      Page: query.page,
      Take: query.take,
    }
    this.notifications = await this.eventService.getSubscriptionEvents(params).toPromise();
    return this.notifications
  }

  unreadNotificationsExist(): boolean {
    return this.notifications.some(notification => notification.read === false);
  }

  async toggleFlagged(notification: SubscriptionEventsView) {
    try {
      await this.eventService.flagEvent({ body: { id: notification.id, flagged: notification.flagged } }).toPromise();
      if (notification.flagged === true) { this.toastrService.success("This Notification has been flagged!", "Success!", { positionClass: 'toast-top-left' }) }
    } catch (err) {
      this.toastrService.error("An error occured while flagging the notification", "Error!", { positionClass: 'toast-top-left' })
    }
  }

  async setRead(notification: SubscriptionEventsView) {
    try {
      await this.eventService.markAsReadEvent({ body: { id: notification.id, read: notification.read } }).toPromise();
      this.notificationChanged.emit();
      this.notificationCountService.readEvent();
    } catch (err) {
      this.toastrService.error("An error occured while marking the notification as read", "Error!", { positionClass: 'toast-top-left' })
    }
  }

  async deleteNotification(notification: SubscriptionEventsView) {
    try {
      await this.eventService.deleteEvent({ body: { id: notification.id } }).toPromise();
      this.notificationChanged.emit();
    } catch (err) {
      this.toastrService.error("An error occured while deleting the notification", "Error!", { positionClass: 'toast-top-left' })
    }
  }

  async deleteAllNotifications() {
    try {
      await this.eventService.deleteAllSubscriptionEvents().toPromise();
      this.notificationChanged.emit();
      this.toastrService.success("All Notifications have been deleted!", "Success", { positionClass: 'toast-top-left' })
    } catch (err) {
      this.toastrService.error("An error occured while deleting all present Notifications.", "Error!", { positionClass: 'toast-top-left' })
    }
  }

  routeToEvent(id:string, notifications: SubscriptionEventsView[]) {
    let notification = this.notifications.find(notification => notification.id === id);
    switch (notification.affectedItemType) {
      case 'InventoryItem':
        window.open(`/details/${notification.affectedItemId}`);
        break;
      case 'Campaign':
        window.open(`/campaigns/${notification.affectedItemId}`);
        break;
      case 'Task':
        window.open(`/tasks/execution/${notification.affectedItemId}`);
        break;
      default: window.open('/not-found')
    }
  }


  getEventIcon(notification: SubscriptionEventsView) {
    const name = notification?.eventName;
    try{
    //inventory Item
    if (name === 'Division Changed') return faGridDividers;
    if (name === 'Area Changed') return faCogs;
    if (name === "Item Unlocked") return faUnlock;
    if (name === "Item Locked") return faLock;
    if (name === "Item Reactivated") return faSignal;
    if (name === "Item Deactivated") return faSignalSlash;
    //Tasking
    if (name === 'Task Submitted As Can Not Compete') return faBan;
    if (name === 'Task Reject') return faBan;
    if (name === 'Task Completed') return faClipboardCheck;
    if (name === 'Task Scheduled') return faClock;
    if (name === 'Task Close') return faDoorClosed;
    if (name === 'Task Reverted') return faRepeat;
    if (name === 'Task Deleted') return faTrashAlt;
    if (name === 'Task Reopened') return faDoorOpen;
    if (name === 'Task Updated') return faPencil;
    if (name === 'Task Merged') return faPlusSquare;
    if (name === 'Task Exception Cleared') return faCheckSquare;
    //campaign
    if (name === 'Campaign Partner Access Extended') return faUserPlus;
    if (name === 'Campaign Partner Access Revoked') return faUserSlash;
    if (name === 'Campaign Partner Invited') return faEnvelopeCircleCheck
    if (name === 'Campaign Technician Assigned') return faUserCheck;
    if (name === 'Campaign Technician Revoked') return faUserSlash;
    }catch(err){}

  }
}







import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faBan, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { TaskTargetEnum } from '../../../api/models/Lukla/Inventory/task-target-enum';
import { TaskTemplate } from '../../../api/models/Lukla/Inventory/task-template';
import { TaskTemplateDefinition } from '../../../api/models/Lukla/Inventory/task-template-definition';
import { TaskingService } from '../../../api/services/tasking.service';

@Component({
  selector: 'template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent implements OnInit {

  constructor(
    private readonly taskingService: TaskingService
  ) { }

    // Inputs/Outputs
    @Input() targets: TaskTargetEnum[];
    @Input() allowAdhoc?: boolean;
    @Output() templateSelected = new EventEmitter<TaskTemplate>();

   // Models
   templates: TaskTemplateDefinition[] = [];
   selectedTemplate: TaskTemplateDefinition
   templateDetails: TaskTemplate;
   total = 0;
   filtered = 0;

   // flags
   showList = true;
   showDetails = true;
   isEditable = false
   showVersions = false;

   //  Icons
   missingTemplateIcon = faBan;
   selectedIcon = faCheckCircle;

   async ngOnInit(): Promise<void> {
    const response = await this.taskingService.searchTemplates({body: {
      targets: this.targets,
      allowAdhoc: this.allowAdhoc
    }}).toPromise();
    this.templates = response.items;
    this.total = response.total;
    this.filtered = response.filtered;
  }

  async selectTemplate(template: TaskTemplateDefinition) {
    this.selectedTemplate = template
    this.templateDetails = await this.taskingService.getTemplate({ id: template.id }).toPromise();
  }

  async confirm() {
    this.templateSelected.emit(this.selectedTemplate);
  }

  filterChanged(event: any) {
    
  }
}

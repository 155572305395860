<div class="exception-wrapper">
    <div class="exception d-flex" [ngClass]="exceptionClass()">
        <h3>
            <fa-icon [icon]="exceptionIcon()"></fa-icon>
        </h3>
        <div class="ms-3" *ngIf="isEditable">
            <h4>{{exceptionName()}}</h4>
            <p class="my-2">Define the exception below based on the criteria provided by the action</p>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Severity</label>
                <div class="col-sm-10">
                    <ng-select class="input-group-lg flex-grow-1" [items]="severities"
                        [(ngModel)]="exception.severity" (ngModelChange)="exceptionChange.emit(exception)"></ng-select>
                </div>
            </div>
            <div class="form-group row mt-2">
                <label class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" [(ngModel)]="exception.name" (ngModelChange)="exceptionChange.emit(exception)" required />
                    <div class="invalid-feedback">
                        A name for the exception is required
                    </div>
                </div>
            </div>
            <div class="form-group row mt-2">
                <label for="noText" class="col-sm-2 col-form-label">Description</label>
                <div class="col-sm-10 editor">
                    <editor  apiKey="s9mgynkayyur88nt7gtwowcbjchlfbb4x8cj554p1w8k3kmr" [init]="tinymceinit"
                        [(ngModel)]="exception.description" (ngModelChange)="exceptionChange.emit(exception)"></editor>
                </div>
            </div>
        </div>
        <div class="ms-3" *ngIf="!isEditable">
            <h4>{{exceptionName()}} - {{exception.name}}</h4>
            <p class="my-2" [innerHtml]="exception.description"></p>
        </div>
    </div>
</div>
<div class="d-flex flex-wrap" *ngIf="loaded">
    <div class="action" *ngIf="canSelect(actions.Inventory)" [class.selected]="isSelected(actions.Inventory)" (click)="select(actions.Inventory)">
        <task-define-inventory [isEditable]="false"></task-define-inventory> 
        <div *ngIf="actionMessage(actions.Inventory)">
            {{actionMessage(actions.Inventory)}}
        </div>
    </div>
    <div class="action" *ngIf="canSelect(actions.Geolocate)" [class.selected]="isSelected(actions.Geolocate)" (click)="select(actions.Geolocate)">
        <task-define-geolocation [isEditable]="false"></task-define-geolocation> 
        <div *ngIf="actionMessage(actions.Geolocate)">
            {{actionMessage(actions.Geolocate)}}
        </div>
    </div>
    <div class="action" *ngIf="canSelect(actions.AssignTag)" [class.selected]="isSelected(actions.AssignTag)" (click)="select(actions.AssignTag)">
        <task-define-assign-tag [isEditable]="false"></task-define-assign-tag> 
        <div *ngIf="actionMessage(actions.AssignTag)">
            {{actionMessage(actions.AssignTag)}}
        </div>
    </div>
    <div class="action" *ngIf="canSelect(actions.Check)" [class.selected]="isSelected(actions.Check)" (click)="select(actions.Check)">
        <task-define-check [isEditable]="false"></task-define-check> 
        <div *ngIf="actionMessage(actions.Check)">
            {{actionMessage(actions.Check)}}
        </div>
    </div>    
    <div class="action" *ngIf="canSelect(actions.Verify)" [class.selected]="isSelected(actions.Verify)" (click)="select(actions.Verify)">
        <task-define-verify-data [isEditable]="false"></task-define-verify-data> 
        <div *ngIf="actionMessage(actions.Verify)">
            {{actionMessage(actions.Verify)}}
        </div>
    </div>
    <div class="action" *ngIf="canSelect(actions.Photo)" [class.selected]="isSelected(actions.Photo)" (click)="select(actions.Photo)">
        <task-define-photo [isEditable]="false"></task-define-photo> 
        <div *ngIf="actionMessage(actions.Photo)">
            {{actionMessage(actions.Photo)}}
        </div>
    </div>
    <div class="action"  *ngIf="canSelect(actions.File)" [class.selected]="isSelected(actions.File)" (click)="select(actions.File)">
        <task-define-file [isEditable]="false"></task-define-file> 
        <div *ngIf="actionMessage(actions.File)">
            {{actionMessage(actions.File)}}
        </div>
    </div>
    <div class="action" *ngIf="canSelect(actions.Comment)" [class.selected]="isSelected(actions.Comment)" (click)="select(actions.Comment)">
        <task-define-comments [isEditable]="false"></task-define-comments> 
        <div *ngIf="actionMessage(actions.Comment)">
            {{actionMessage(actions.Comment)}}
        </div>
    </div>
    <div class="action" *ngIf="canSelect(actions.End)" [class.selected]="isSelected(actions.End)" (click)="select(actions.End)">
        <task-end-workflow [isEditable]="false"></task-end-workflow> 
        <div *ngIf="actionMessage(actions.End)">
            {{actionMessage(actions.End)}}
        </div>
    </div>
</div>
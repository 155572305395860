import { Injectable} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { EventsService } from "src/app/api/services/events.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationCountService{

  constructor(private readonly eventService: EventsService) { }

  private unreadCount = new BehaviorSubject<number>(0);
  unreadCount$ = this.unreadCount.asObservable();
  private listUpdate = new Subject<void>();
  listUpdate$ = this.listUpdate.asObservable();

  fetchUnreadCount(){
    this.eventService.getUnreadSubscriptionEvents().subscribe(count => {
      this.unreadCount.next(count);
    });
  }

  readEvent(){
    let currentCount = this.unreadCount.value;
    if (currentCount >0){
      currentCount -=1;
      this.unreadCount.next(currentCount);
    }
  }

  addEvent(){
    let currentCount = this.unreadCount.value;
    currentCount +=1;
    this.unreadCount.next(currentCount);
    this.listUpdate.next();
  }


}

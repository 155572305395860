import { Component, OnInit } from '@angular/core';
import { faBan } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'lukla-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  noAccessIcon = faBan;

  constructor() { }
  ngOnInit(): void {}

}

<div *ngIf="isLoaded" class="anchors-container">
  <div class="search-container p-2 mb-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="input-group flex-nowrap heading">
        <span class="input-group-text search-icon">
          <fa-icon [icon]="isSearching ? processingIcon : searchIcon" [spin]="isSearching"></fa-icon>
        </span>
        <input type="text" class="form-control search" [(ngModel)]="searchText" (keyup)="anchorSearch()" placeholder="Search... ">
      </div>
      <h5 class="counter text-nowrap">
        <fa-icon [icon]="countIcon"></fa-icon>
        <span class="ms-2">Showing {{ filtered }} of {{ total }}</span>
      </h5>
    </div>
  </div>
    <div class="d-flex flex-wrap">
      <div *ngFor="let b of filteredAnchors" class="grid-card-spacing">
        <div class="anchor card grid-item alert alert-light" (click)="select(b)" [class.selected]="isSelected(b)">
          <div class="mt-2 d-flex justify-content-start align-items-center">
            <h3 class="anchor-name ms-2">
              <fa-icon *ngIf="b.iconName" [icon]="getIcon(b.iconName)" [fixedWidth]="true">
              </fa-icon> {{b.displayName}}
            </h3>
          </div>
          <div class="d-flex flags mt-2">
            <div *ngIf="b.isActive" class="ms-2 badge bg-success">Active</div>
            <div *ngIf=" !b.isActive" class="ms-2 badge bg-danger">Inactive</div>
          </div>
          <div class="mt-4 ms-2">
            <label>Parent Name: <b>{{b.parent || 'General'}}</b> </label>
          </div>
          <div class="mt-2 ms-2">
            <label class="anchor-type ">Anchor Type: <b>{{getTypeName(b.anchorType)}}</b> </label>
          </div>
        </div>
      </div>
    </div>
</div>
  
<div class="condition d-flex text-light">
    <fa-icon class="h3" [icon]="conditionIcon"></fa-icon>
    <div class="ms-3 flex-fill">
        <div class="d-flex justify-content-between align-items-center">
            <h4>Condition</h4>
            <button *ngIf="isEditable" class="btn btn-sm btn-danger mb-2" (click)="onRemove()">Remove</button>
        </div>
        <div *ngIf="isEditable">
            <p>Choose which anwser causes this event to occur?</p>
            <div class="row">
                <div class="col">
                    <button class="btn w-100" [class.btn-outline-light]="item.condition?.answer !== true"
                        [class.btn-light]="item.condition?.answer === true"
                        (click)="setYes()">{{definition.yesText}}</button>
                </div>
                <div class="col">
                    <button class="btn w-100" [class.btn-outline-light]="item.condition?.answer !== false"
                        [class.btn-light]="item.condition?.answer === false"
                        (click)="setNo()">{{definition.noText}}</button>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditable">
            <h5>When Answer is 
                <label *ngIf="item.condition?.answer === true" class="bg-success p-2">{{definition.yesText}}</label>    
                <label *ngIf="item.condition?.answer === false" class="bg-danger p-2">{{definition.noText}}</label>
            </h5>
        </div>
    </div>
</div>
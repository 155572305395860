import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faRoute, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { faCircleNotch, faCopy, faSave, faSquareUpRight } from '@fortawesome/pro-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { TemplateUpdateService } from '../../services/template-update-service';
import { TaskTemplateStatus } from '../../utils/task-template-status';
import { ConfirmationModalComponent } from '../../../utilities/confirmation-modal/confirmation-modal.component';
import { TaskingService } from '../../../api/services/tasking.service';
import { TaskTemplate } from '../../../api/models/Lukla/Inventory/task-template';

@Component({
  selector: 'task-template-header',
  templateUrl: './template-header.component.html',
  styleUrls: ['./template-header.component.scss']
})
export class TemplateHeaderComponent implements OnInit {
  
  constructor(
    private readonly taskingService: TaskingService,
    private readonly toastrService: ToastrService,
    private readonly location: Location,
    public readonly modalService: BsModalService,
    private readonly templateUpdateService: TemplateUpdateService
  ) { }

  //  Input/Output
  @Input() template: TaskTemplate;
  @Output() templateChange = new EventEmitter<TaskTemplate>();

  @Input() isEditable: boolean;
  @Output() isEditableChange = new EventEmitter<boolean>();

  @Input() showVersions: boolean;
  @Output() showVersionsChange = new EventEmitter<boolean>();

  @Input() canEdit = true;


  //  Icons
  headerIcon = faRoute;
  saveIcon = faSave;
  processingIcon = faCircleNotch;
  goToIcon = faSquareUpRight;
  cloneIcon = faCopy;
  validationIcon = faWarning;
  latestIcon = faWarning;

  //  Flags
  isSaving = false;
  isLatest = false;
  enableEdit = false;

  //  Modals
  private bsModalRef: BsModalRef;

  //  Validation
  isValid = true;
  validationErrors = [];

  //  Subscriptions
  subscriptions : Subscription[] = [];

  ngOnInit(): void {
    if (this.template) this.templateUpdated(this.template);
    this.subscriptions.push(this.templateUpdateService.currentTemplate.subscribe(x => this.templateUpdated(x)));
  }

  templateUpdated(template: TaskTemplate) {
    this.isEditable = this.isEditable && template.latest;
    this.isLatest = template.latest;
    this.enableEdit = this.canEdit && this.isLatest;
  }

  //  Status Helpers
  statusClass = () => {
    switch (<TaskTemplateStatus><any>this.template.status) {
      case TaskTemplateStatus.New: return 'bg-primary';
      case TaskTemplateStatus.Draft: return 'bg-primary';
      case TaskTemplateStatus.Active: return 'bg-success';
      case TaskTemplateStatus.Archived: return 'bg-danger';
    }
  }

  statusText = () => this.template.status.toString();

  async saveTemplate() {
    const validation = this.templateUpdateService.validate(this.template);
    this.isValid = validation.isValid;
    this.validationErrors = validation.validationErrors;

    if (!this.isValid) return;

    this.isSaving = true;
    this.template.version++;
    try {
      const definition = await this.taskingService.upsertTemplate({
        body: {
          taskTemplate: this.template
        }
      }).toPromise();
      const oldId = this.template.id;
      this.templateUpdateService.discardDraft((<any>this.template).draftId);
      this.template.id = definition.id;
      this.template.companyId = definition.companyId;
      this.template.status = definition.status,
      this.template.taskTemplateId = definition.taskTemplateId;
      this.location.replaceState(this.location.path().replace(oldId, this.template.id));
      this.templateChange.emit(this.template);
      this.templateUpdateService.load(this.template);
  
      this.toastrService.success('Task template successfully updated', 'Template Updated');  
    } catch(error: any) {
      if (error.status === 400) {
        this.isValid = false;
        for (let key in error.error.errors) {
          let validationErrors = error.error.errors[key];
          for (let validationError of validationErrors) {
            this.validationErrors.push(validationError);
          }
        }
      }
      else {
        this.isValid = false;
        this.validationErrors.push(`There was an unknown error attempting to process this template update, please contact lukla support with the following template details: Error: ${error.error.message}, Template: ${JSON.stringify(this.template)}`);
      }      
    }
    this.isSaving = false;
  }

  changeEditable() {
    this.isEditable = !this.isEditable;
    this.isEditableChange.emit(this.isEditable);
  }

  changeVersionVisibility() {
    this.showVersions = !this.showVersions;
    this.showVersionsChange.emit(this.showVersions);
  }

  clone() {
    const initialState = {
      title: "Clone Template",
      confirmText: `Are you sure you want to clone ${this.template.name}? This will create an identical template that you branch off and continue building on`,
    }
    this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState })

    this.bsModalRef.content.confirmed.subscribe(async () => {
      delete (this.template.id);
      delete (this.template.taskTemplateId);
      this.template.status = <any>TaskTemplateStatus.New;
      this.template.version = 1;
      this.template.name = `${this.template.name} (CLONE)`;

      this.templateChange.emit(this.template);
      this.templateUpdateService.load(this.template);
    });
  }
}

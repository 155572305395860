<nav class="navbar navbar-expand-lg navbar-light ">
  <div class="container-fluid">
    <span class=" display-4">Tasks</span>
    <nav id="main-nav" class="app-header-actions d-flex justify-content-between w-100">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item d-flex justify-content-start align-items-center">
          <a class="btn btn-link action-button" [routerLink]="'/'" [routerLinkActive]="['is-active']"
            [routerLinkActiveOptions]="{exact: true}" tooltip="Dashboard" container="body">
            <fa-icon [icon]="dashboardIcon"></fa-icon>
          </a>
          <h3 class="d-lg-none fw-light d-xxl-block">Dashboard</h3>
        </li> -->
        <li class="nav-item d-flex justify-content-start align-items-center">
          <a class="btn btn-link action-button" [routerLink]="'/'" [routerLinkActive]="['is-active']"
            [routerLinkActiveOptions]="{exact: true}" tooltip="Items" container="body">
            <fa-icon [icon]="assetIcon"></fa-icon>
          </a>
          <h3 class="d-lg-none fw-light d-xxl-block">Items</h3>
        </li>
        <!-- <li class="nav-item d-flex justify-content-start align-items-center">
          <a class="btn btn-link action-button" [routerLink]="'/items'" [routerLinkActive]="['is-active']"
            tooltip="Asset List" container="body">
            <fa-icon [icon]="assetIcon"></fa-icon>
          </a>
          <h3 class="d-lg-none fw-light d-xxl-block">Items</h3>
        </li> -->
        <li class="nav-item d-flex justify-content-start align-items-center">
          <a class="btn btn-link action-button" [routerLink]="'/campaigns'" [routerLinkActive]="['is-active']"
          tooltip="Campaigns" container="body">
          <fa-icon [icon]="campaignIcon"></fa-icon>
        </a>
        <h3 class="d-lg-none fw-light d-xxl-block">Campaigns</h3>
        </li>
        <li class="nav-item d-flex justify-content-start align-items-center">
          <a class="btn btn-link action-button" [routerLink]="'/locations'" [routerLinkActive]="['is-active']"
          tooltip="Locations" container="body">
          <fa-icon [icon]="locationsIcon"></fa-icon>
        </a>
        <h3 class="d-lg-none fw-light d-xxl-block">Locations</h3>
        </li>
        <li class="nav-item d-flex justify-content-start align-items-center">
          <a class="btn btn-link action-button" [routerLink]="'/admin'" [routerLinkActive]="['is-active']"
          tooltip="Administration" container="body">
          <fa-icon [icon]="adminIcon"></fa-icon>
        </a>
        <h3 class="d-lg-none fw-light d-xxl-block" >Administration</h3>

        </li>
      </ul>
      <div *ngIf="currentUser" class="d-flex align-items-center app-header-actions">
        <div class="position-relative">
          <span *ngIf="unreadCount > 0" class="position-absolute bottom-50 end-0 translate-middle badge rounded-pill bg-danger">
            {{unreadCount}}
          </span> 
         <notification-drawer></notification-drawer>
        </div>
        <button class="btn btn-link mr-2 ml-0" (click)="logout()" tooltip="Logout">
          <fa-icon [icon]="logoutIcon"></fa-icon>
        </button>
        <lukla-header-profile></lukla-header-profile>
      </div>
    </nav>
  </div>
</nav>

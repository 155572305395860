import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { faCircleNotch, faImagePolaroid, faSave, faTimes, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { UploadState, UploadxOptions, UploadxService } from 'ngx-uploadx';
import { InventoryItemView } from 'src/app/api/models/Lukla/Inventory/inventory-item-view';
import { ContentService } from 'src/app/api/services/content.service';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { MultiPartFormData } from 'src/app/shared/multipart-uploader';
import { v4 as uuidv4 } from 'uuid';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ContentView } from 'src/app/shared/inventory-image-viewer/content-model';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent implements OnInit {

  constructor(
    private readonly contentService: ContentService,
    private readonly toastrService: ToastrService,
    private readonly oAuthService: OAuthService,
    public bsModalRef: BsModalRef
  ) { }

  @Input() item: InventoryItemView
  @Input() content: ContentView ={};
  @Input()  isUpdating = false;

  @Output() confirmed = new EventEmitter();

  // FORM
  options: UploadxOptions;
  fileName: string;
  fileId: string;
  givenName: string;
  contentType: string;
  size: number;

  // FLAGS
  isSaving = false;
  currentState = null;
  isUploaded = false;

  // icons
  headerIcon = faImagePolaroid;
  uploadIcon = faUpload;
  removeIcon = faTimes;
  saveIcon = faSave;
  processingIcon = faCircleNotch;
  infoIcon = faInfoCircle

  ngOnInit(): void {

    this.isUploaded = false
    this.fileId = `${this.item.id}_${uuidv4()}`;

    this.options = {
      multiple: false,
      token: () => this.oAuthService.getAccessToken(),
      uploaderClass: MultiPartFormData,
      endpoint: environment.tempUploadEndpoint,
      metadata: {
        fileUploadId: this.fileId
      }
    }

    this.content.tags = this.content.tags || [];
    this.givenName= this.content.name || '';
  }

  onUpload(state: UploadState) {
    this.isUploaded = true;
    this.currentState = state;
    this.fileName = state.file.name;
    this.contentType = state.file.type;
    this.size = state.file.size;
  }

  tagName: string;
  tagValue: string;
  addtags() {
    if (!this.tagName || !this.tagValue) return;
    this.content.tags.push({
      name: this.tagName,
      value: this.tagValue
    })
    this.tagName = null;
    this.tagValue = null;
  }

  removeCharacteristic(tagDetails: any) {
    this.content.tags = this.content.tags.filter(x => x.name !== tagDetails.name);
  }

  async confirm() {
    this.isSaving = true;
    try {
    await this.contentService.createNewContent({
      body:
      {
        fileId: this.fileId,
        name: this.givenName,
        fileName: this.fileName,
        contentType: this.contentType,
        inventoryItemId: this.item.id,
        tags: this.content.tags
      }
    }).toPromise();
    this.confirmed.emit()
    this.isSaving = false;
    this.toastrService.success('New content has been added', 'SUCCESS')
    this.bsModalRef.hide()
  }catch(err){
    this.isSaving = false;
    this.toastrService.error("Please try again or contact Lukla support!", "Something Went Wrong!")

  }
  }

  async update(){
    this.isSaving = true;
    try {
      await this.contentService.updateContent({
        body:
        {
          fileId: this.fileId,
          name: this.givenName,
          fileName: this.fileName,
          contentType: this.contentType,
          inventoryContentItemId: this.content.id,
          tags: this.content.tags
        }
      }).toPromise();
      this.confirmed.emit()
      this.isSaving = false;
      this.toastrService.success('Content has been updated', 'SUCCESS')
      this.bsModalRef.hide()
    }catch(err){
      this.isSaving = false;
      this.toastrService.error("Please try again or contact Lukla support!", "Something Went Wrong!")
    }
  }

  cancel() {
    this.bsModalRef.hide()
  }
}

<div class="step-container d-flex">
    <h3>
        <fa-icon [icon]="headerIcon"></fa-icon>
    </h3>
    <div class="ms-3 was-validated has-validation">
        <h4>Verify Data</h4>
        <p class="mt-2">This action will allow you verify or add new data, you can use existing anchor data or define a
            new adhoc dataset to use. If the step is required, the user must enter in a new value regardless of it was previously populated</p>
        <div *ngIf="isEditable">
            <div *ngIf="!decisionMade()" class="d-flex">
                <div class="btn btn-panel btn-outline-dark" (click)="selectAnchor()">
                    <div class="d-flex">
                        <h4>
                            <fa-icon [icon]="anchorIcon"></fa-icon>
                        </h4>
                        <div class="ms-3">
                            <h4>Select Anchor</h4>
                            <p>Use existing anchor to define data requirements and pull existing data from item to be
                                verified</p>
                        </div>
                    </div>
                </div>
                <div class="btn btn-panel btn-outline-dark ms-3" (click)="defineAdhoc()">
                    <div class="d-flex">
                        <h4>
                            <fa-icon [icon]="adhocIcon"></fa-icon>
                        </h4>
                        <div class="ms-3">
                            <h4>Build Ad-hoc</h4>
                            <p>Build definition for requirements for the data to be captured</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="useAnchor">
                <div class="d-flex justify-content-between">
                    <h5>
                        <fa-icon *ngIf="selectedAnchor?.iconName" [icon]="getIcon(selectedAnchor.iconName)"
                            [fixedWidth]="true">
                        </fa-icon> {{selectedAnchor?.displayName}}
                    </h5>
                    <div class="d-flex">
                        <button class="btn btn-primary" (click)="selectAnchor()">Change Anchor</button>
                        <button class="ms-2 btn btn-primary" (click)="defineAdhoc()">Define Adhoc</button>
                    </div>
                </div>
                <div *ngIf="selectedAnchor">
                    <div class="row ms-1">
                        <label class="col-sm-2 col-form-label">Parent</label>
                        <label class="col-sm-10 col-form-label fw-bold">
                            {{selectedAnchor.parent || 'General'}}
                        </label>
                    </div>
                    <div class="row ms-1">
                        <label class="col-sm-2 col-form-label">Data Type</label>
                        <label class="col-sm-10 col-form-label fw-bold">
                            {{getTypeName(selectedAnchor.anchorType)}}
                        </label>
                    </div>
                </div>
            </div>

            <div *ngIf="useAdhoc">
                <div class="d-flex justify-content-between">
                    <h5>Adhoc Definition</h5>
                    <div class="d-flex">
                        <button class="ms-2 btn btn-primary" (click)="selectAnchor()">Use Anchor</button>
                    </div>
                </div>

                <div class="form-group row mt-2">
                    <label for="noText" class="col-sm-2 col-form-label">Name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" [(ngModel)]="step.actionDefinition.name" required (ngModelChange)="stepChange.emit(step)" />
                        <div class="invalid-feedback">
                            A name for the adhoc data check is required
                        </div>                
                    </div>
                </div>
                <div class="form-group row mt-2">
                    <label for="noText" class="col-sm-2 col-form-label">Data Type</label>
                    <div class="col-sm-10">
                        <ng-select [items]="availableDataTypes" class="input-group-lg flex-grow-1" bindLabel="name" bindValue="id" 
                            [(ngModel)]="step.actionDefinition.dataType" placeholder="Select a data Type" required (ngModelChange)="stepChange.emit(step)"></ng-select>
                        <div class="invalid-feedback">
                            A data type is required for the adhoc data
                        </div>            
                    </div>
                </div>
                <div class="mt-2" *ngIf="isChoiceType()">
                    <h5>Choices</h5>
                    <div class="form-group row mt-2">
                        <label for="noText" class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" [(ngModel)]="currentChoiceName" required>
                        </div>
                        <button class="col-sm-2 btn btn-primary btn-sm" (click)="addChoice()">Add</button>
                    </div>
                    <div class="form-group row mt-2">
                        <label for="noText" class="col-sm-2 col-form-label">Choices</label>
                        <div class="col-sm-10">
                            <label *ngFor="let c of step.actionDefinition.choices"
                                class="d-line mt-1 me-2 p-1 px-2 bg-primary text-white text-nowrap">{{c}} <button
                                    class="btn btn-icon-link text-white" (click)="removeChoice(c)">
                                    <fa-icon [icon]="removeIcon"></fa-icon>
                                </button></label>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="decisionMade()" class="mt-2">
                <h5>Options</h5>
                <div class="d-flex mt-3">
                    <button class="btn" [class.btn-outline-primary]="!step.actionDefinition.allowNotApplicable"
                        [class.btn-primary]="step.actionDefinition.allowNotApplicable"
                        (click)="toggleNotApplicable()">Allow Not Applicable Answer</button>
                    <button class="ms-2 btn" [class.btn-outline-primary]="!step.actionDefinition.allowUnknown"
                        [class.btn-primary]="step.actionDefinition.allowUnknown"
                        (click)="toggleUnknown()">Allow Unknown Answer</button>
                    <button *ngIf="!useAdhoc" class="ms-2 btn" [class.btn-outline-primary]="!step.actionDefinition.raiseFlagWhenChanged"
                        [class.btn-primary]="step.actionDefinition.raiseFlagWhenChanged"
                        (click)="toggleRaiseException()">Raise Exception When Changed</button>
                    <button *ngIf="!useAdhoc" class="ms-2 btn" [class.btn-outline-primary]="!step.actionDefinition.requireDateWhenChanged"
                        [class.btn-primary]="step.actionDefinition.requireDateWhenChanged"
                        (click)="toggleRequireDateWhenChanged()">Require Date When Changed</button>
                </div>
            </div>
        </div>

        <div *ngIf="!isEditable && step">
            <div *ngIf="useAdhoc">
                <h5>
                    <span>{{step.actionDefinition.name}}</span>
                    <span class="ms-2 text-muted small">(Adhoc)</span>
                </h5>
                <div class="row ms-1 mt-2">
                    <label class="col-sm-2 col-form-label">Data Type</label>
                    <label class="col-sm-10 col-form-label fw-bold">
                        {{step.actionDefinition.dataType}}
                    </label>
                </div>
                <div class="row ms-1" *ngIf="isChoiceType()">
                    <label class="col-sm-2 col-form-label">Choices</label>
                    <div class="col-sm-10">
                        <label *ngFor="let c of step.actionDefinition.choices"
                            class="d-line mt-1 me-2 p-1 px-2 bg-primary text-white text-nowrap">{{c}}
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="useAnchor && selectedAnchor">
                <h5>
                    <fa-icon *ngIf="selectedAnchor?.iconName" [icon]="getIcon(selectedAnchor.iconName)"
                        [fixedWidth]="true"></fa-icon>
                    <span class="ms-1">{{selectedAnchor?.displayName}}</span>
                    <span class="ms-2 text-muted small">(Anchor)</span>
                </h5>
                <div class="row ms-1">
                    <label class="col-sm-2 col-form-label">Parent</label>
                    <label class="col-sm-10 col-form-label fw-bold">
                        {{selectedAnchor.parent || 'General'}}
                    </label>
                </div>
                <div class="row ms-1">
                    <label class="col-sm-2 col-form-label">Data Type</label>
                    <label class="col-sm-10 col-form-label fw-bold">
                        {{getTypeName(selectedAnchor.anchorType)}}
                    </label>
                </div>
            </div>

            <div class="m-2 badge bg-primary" *ngIf="step.actionDefinition.allowNotApplicable">Allow Not Applicable Answer</div>
            <div class="m-2 badge bg-primary" *ngIf="step.actionDefinition.allowUnknown">Allow Unknown Answer</div>
            <div class="m-2 badge bg-primary" *ngIf="step.actionDefinition.raiseFlagWhenChanged">Raise Exception When Changed </div>
            <div class="m-2 badge bg-primary" *ngIf="step.actionDefinition.requireDateWhenChanged">Require Date When Changed</div>

        </div>
    </div>
</div>
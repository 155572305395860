<div class="row">
    <div id="landing-page" class="g-0 col-xs-12">
        <div id="landing-container">
            <div class="header">
                <img src="https://app.lukla.co/Content/img/Login/logo-white.png">
                <div class="d-flex justify-content-end align-items-baseline workspace-title">
                    <h3 class="display-2">Inventory</h3>
                </div>
                <div class="alert alert-danger landing-item">
                    <div class="d-flex align-items-center">
                        <h3>
                            <fa-icon [icon]="errorIcon"></fa-icon> Unexpected Error
                        </h3>
                    </div>
                    <p>An error occurred in which we could not recover, our support team will have been notified of the
                        error but if you'd like immediate assistance please contact our <a
                            href="mailto:support@lukla.co">support team</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="step-actions" *ngIf="isEditable && !isEnd(step)">
    <div class="actions">
        <div *ngIf="!isEditable && step.isRequired" class="badge badge-success">
        </div>
        <button *ngIf="isEditable" 
            class="btn" 
            [class.btn-outline-success]="!step.isRequired"
            [class.btn-success]="step.isRequired" 
            (click)="toggleRequired()">Required</button>
        <div *ngIf="!confirmDelete" class="ms-2 btn btn-outline-danger" (click)="confirmingDelete()">
            <fa-icon [icon]="deleteIcon"></fa-icon> Delete Step
        </div>
        <div *ngIf="confirmDelete" class="ms-2 btn btn-danger" (click)="deleteStep()">
            <fa-icon [icon]="deleteIcon"></fa-icon> Confirm & Delete Step
        </div>
        <button class="ms-2 btn btn-outline-primary" tooltip="Move Step up" (click)="moveup()">
            <fa-icon [icon]="upIcon"></fa-icon>
        </button>
        <button class="ms-2 btn btn-outline-primary" tooltip="Move Step down" (click)="movedown()">
            <fa-icon [icon]="downIcon"></fa-icon>
        </button>    
    </div>
</div>

<task-define-inventory [isEditable]="isEditable" *ngIf="isInventory(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-inventory>
<task-define-geolocation [isEditable]="isEditable" *ngIf="isGeolocation(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-geolocation>
<task-define-assign-tag [isEditable]="isEditable" *ngIf="isAssignTag(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-assign-tag>
<task-define-check [isEditable]="isEditable" *ngIf="isCheck(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-check>
<task-define-verify-data [isEditable]="isEditable" *ngIf="isVerify(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-verify-data>
<task-define-photo [isEditable]="isEditable" *ngIf="isPhoto(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-photo>
<task-define-file [isEditable]="isEditable" *ngIf="isFile(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-file>
<task-define-comments [isEditable]="isEditable" *ngIf="isComment(step)" [(step)]="step" (stepChange)="stepChange.emit(step)"></task-define-comments>
<task-define-spark-harvest [(step)]="step" *ngIf="isSparkHarvest(step)"  (stepChange)="stepChange.emit(step)"></task-define-spark-harvest>
<task-end-workflow [isEditable]="isEditable" *ngIf="isEnd(step)" (endRemoved)="deleteStep()"></task-end-workflow>


<task-define-conditional [isEditable]="isEditable" [(step)]="step" (stepChange)="stepChange.emit(step)"> </task-define-conditional>

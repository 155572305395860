
<div [outsideClick]="true" [popover]="popover" container="body" #pop="bs-popover" containerClass="action-popover" triggers="mouseenter">
    <lukla-user [user]="currentUser"></lukla-user>
</div>

<ng-template #popover>
    <div>
        <h4 class="header"><fa-icon [icon]="companyIcon"></fa-icon> Change Company</h4>
        <div class="items">
            <div *ngFor="let c of companies" class="item" (click)="changeCompany(c)" [class.selected]="isSelected(c)" [class.available]="!isSelected(c)">
                <fa-icon *ngIf="isSelected(c)" [icon]="selectedIcon" [fixedWidth]="true"></fa-icon>
                <span>
                    {{c.name}}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<div class="section">

  <div class="section-header was-validated has-validation">
    <div class="mb-3 d-flex justify-content-between">
        <div class="flex-grow-1 me-2">
          <div class="mb-3 d-flex justify-content-between">
            <div class="flex-grow-1 me-2">
              <h3 *ngIf="!editingName || !isEditable" class="field" [class.editable]="isEditable" (click)="editName()">
                {{template.name}}</h3>
              <div *ngIf="isNew && !template.name && isEditable" class="alert alert-danger">
                <fa-icon [icon]="newIcon"></fa-icon>
                <span class="ms-2 lead">Please enter in the name for this template (required)</span>
              </div>
              <input *ngIf="editingName && isEditable" type="text" class="form-control" [(ngModel)]="template.name"
                (blur)="stopEditName()" required>
            </div>
          </div>
          <div class="mb-3 pb-2">
            <p *ngIf="!editingDescription || !isEditable" class="field" [class.editable]="isEditable"
              (click)="editDescription()" [innerHtml]="template.explanation"></p>
            <div *ngIf="isNew && !template.explanation && isEditable" class="alert alert-info">
              <fa-icon [icon]="newIcon"></fa-icon>
              <span class="ms-2 lead">Please enter in the explanation for this template</span>
            </div>
            <editor *ngIf="editingDescription && isEditable" apiKey="s9mgynkayyur88nt7gtwowcbjchlfbb4x8cj554p1w8k3kmr"
              (ngModelChange)="templateChange.emit(template)" [init]="tinymceinit" [(ngModel)]="template.explanation"
              (onBlur)="stopEditDescription()"></editor>
          </div>
          <div class="mb-3 pb-2 border-bottom">
            <h5>Targets</h5>
            <div *ngIf="isEditable" class="alert alert-info">
              <fa-icon [icon]="newIcon"></fa-icon>
              <span class="ms-2 lead">Targets let us know which type of entity the task is targeted towards</span>
            </div>
            <div class="d-flex mt-2" *ngIf="isEditable">
              <button class="btn me-3" (click)="toggleTarget(templateTargets.Item)"
                [class.btn-outline-primary]="!targets(templateTargets.Item)"
                [class.btn-primary]="targets(templateTargets.Item)">
                <fa-icon [icon]="itemIcon" [fixedWidth]="true"></fa-icon> Item
              </button>
              <button class="btn" (click)="toggleTarget(templateTargets.Location)"
                [class.btn-outline-primary]="!targets(templateTargets.Location)"
                [class.btn-primary]="targets(templateTargets.Location)">
                <fa-icon [icon]="locationIcon" [fixedWidth]="true"></fa-icon> Location
              </button>
            </div>
            <div class="d-flex mt-2" *ngIf="!isEditable">
              <span *ngFor="let target of template.targets" class="bg-primary text-white p-2 me-3">
                <fa-icon [icon]="targetIcon(target)" [fixedWidth]="true"></fa-icon> {{target}}
              </span>
            </div>
          </div>
          <div class="mb-3 pb-2 border-bottom">
            <h5>Characteristics</h5>
            <div *ngIf="isEditable" class="alert alert-info">
              <fa-icon [icon]="newIcon"></fa-icon>
              <span class="ms-2 lead">Enter characteristics to help define your template for searching and
                filtering</span>
            </div>
            <div class="form-group row mt-2" *ngIf="isEditable">
              <label for="noText" class="col-sm-1 col-form-label">Name</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" [(ngModel)]="characteristicName" required />
              </div>
              <label for="noText" class="col-sm-1 col-form-label">Value</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" [(ngModel)]="characteristicValue" required />
              </div>
              <button class="col-sm-2 btn btn-primary btn-sm" (click)="addCharacteristic()">Add</button>
            </div>
            <div class="form-group row mt-2">
              <div class="col-sm-10">
                <label *ngFor="let c of template.characteristics"
                  class="d-line mt-1 me-2 p-1 px-2 bg-primary text-white text-nowrap">{{c.name}} : {{c.value}}
                  <button *ngIf="isEditable" class="btn btn-icon-link text-white" (click)="removeCharacteristic(c)">
                    <fa-icon [icon]="removeIcon"></fa-icon>
                  </button>
                </label>
              </div>
            </div>
          </div>
          <div class="mb-3" *ngIf="isEditable">
            <p class="lead">Click this button to allow tasks to be created adhoc (outside of a campaign, eg: on the
              user's
              phone while out in the field) to allow for tasks to be created and started outside of a campaign</p>
            <button class="btn mb-2" [class.btn-outline-primary]="!template.allowAdhoc"
              [class.btn-primary]="template.allowAdhoc" (click)="toggleAdhoc()">Allow Adhoc Tasks</button>
          </div>
          <div class="mb-3 p-3 bg-primary text-white" *ngIf="!isEditable && template.allowAdhoc">
            <h5>Adhoc Task Creation Allowed</h5>
            <p>This template can be used to create a task outside of a campaign (eg: in the field by a technician) for
              tasks that may not be planned or accounted for inside of a campaign</p>
          </div>
          <div class="mb-3 p-3 bg-dark text-white" *ngIf="!isEditable && !template.allowAdhoc">
            <h5>Adhoc Task Creation Not Allowed</h5>
            <p>This template can only be used inside of a campaign and technicians cannot create tasks from this
              template
              from the field</p>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="step-container d-flex" #stepElement>
    <h3>
        <fa-icon [icon]="headerIcon"></fa-icon>
    </h3>
    <div class="ms-3 flex-grow-1 was-validated has-validation">
        <h4>Question</h4>
        <p class="mt-2">This action will allow you to ask a question with a yes/no answer</p>
        <div *ngIf="isEditable">
            <div class="row mb-2">
                <div class="col-lg-6">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Yes Text</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" [(ngModel)]="step.actionDefinition.yesText" (ngModelChange)="stepChange.emit(step)"
                                required>
                            <div class="invalid-feedback">
                                A value for what to display for the Yes anwser is required for the action to be valid
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">No Text</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" [(ngModel)]="step.actionDefinition.noText" required (ngModelChange)="stepChange.emit(step)">
                            <div class="invalid-feedback">
                                A value for what to display for the No anwser is required for the action to be valid
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Question</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" [(ngModel)]="step.actionDefinition.question" required (ngModelChange)="stepChange.emit(step)">
                    <div class="invalid-feedback">
                        The question to ask is required for the action to be valid
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isEditable && step">
            <h5>Preview (App View)</h5>
            <div class="preview">
                <div class="d-flex">
                    <fa-icon [icon]="questionIcon"></fa-icon>
                    <div class="ps-2">{{step.actionDefinition.question}}</div>
                </div>
                <div class="row mt-4">
                    <div class="col"><button class="btn w-100 btn-primary">{{step.actionDefinition.yesText}}</button>
                    </div>
                    <div class="col"><button class="btn w-100 btn-primary">{{step.actionDefinition.noText}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
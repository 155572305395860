<div class="modal-body">
    <div class="popover-intro">
        <h3 class="display-4">
            <fa-icon [icon]="headerIcon"></fa-icon> Select Anchor
        </h3>
        <p class="lead">Select an anchor to add to the selection</p>
    </div>
    <select-anchor (selectedAnchor)="onSelectAnchor($event)"></select-anchor>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-success" (click)="select()" [disabled]="noAnchorSelected()"> 
        <fa-icon [icon]="selectIcon"></fa-icon> Select Anchor
    </button>
</div>
<div *ngIf="isAllowed() && isEditable" class="step-container">
    <h5>Conditional Actions</h5>
    <p>Create an event based on the result of the step, for instance you can raise an exception or create an additonal step if the answer to the previous step meets the specified rule</p>
    <div class="row">
        <div class="col">
            <button class="btn btn-warning w-100" (click)="addException()">Add Exception</button>
        </div>
        <div class="col">
            <button class="btn btn-dark w-100" (click)="addConditionalStep()">Add Step</button>
        </div>
        <div class="col">
            <button class="btn btn-dark w-100" (click)="addConditionalSection()">Add Section</button>
        </div>
    </div>
</div>

<div *ngIf="step?.exceptions">
    <div *ngFor="let exception of step.exceptions; let i = index">
        <div class="separator"></div>
        <div class class="conditionals">
            <task-define-check-condition
                *ngIf="isCheck(step)"
                [definition]="step.actionDefinition"
                [(item)]="step.exceptions[i]"
                [isEditable]="isEditable"
                (remove)="removeException($event)"
                (itemChange)="stepChange.emit(step)"></task-define-check-condition>
            <task-define-verify-condition
                *ngIf="isVerify(step)"
                [definition]="step.actionDefinition"
                [(item)]="step.exceptions[i]"
                [isEditable]="isEditable"
                (remove)="removeException($event)"
                (itemChange)="stepChange.emit(step)"></task-define-verify-condition>
            <task-define-exception [isEditable]="isEditable" [(exception)]="step.exceptions[i]" (exceptionChange)="stepChange.emit(step)"></task-define-exception>
        </div>
    </div>
</div>


<div *ngIf="step?.conditionalSteps">
    <div *ngFor="let conditionalStep of step.conditionalSteps; let i = index">
        <div class="separator"></div>
        <div class class="conditionals">
            <task-define-check-condition
                *ngIf="isCheck(step)"
                [definition]="step.actionDefinition"
                [(item)]="step.conditionalSteps[i]"
                [isEditable]="isEditable"
                (remove)="removeConditionalStep(i)"
                (itemChange)="stepChange.emit(step)"></task-define-check-condition>

            <task-define-verify-condition
                *ngIf="isVerify(step)"
                [definition]="step.actionDefinition"
                [(item)]="step.conditionalSteps[i]"
                [isEditable]="isEditable"
                (remove)="removeConditionalStep(i)"
                (itemChange)="stepChange.emit(step)"></task-define-verify-condition>

            <div *ngIf="conditionalStep.step">
                <task-define-step
                [isEditable]="isEditable"
                [(step)]="step.conditionalSteps[i].step"
               (stepDeleted)="removeConditionalStep(i)"
                (stepChange)="stepChange.emit(step)"></task-define-step>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step?.conditionalSections">
    <div *ngFor="let conditionalSection of step.conditionalSections; let i = index">
        <div class="separator"></div>
        <div class class="conditionals">
            <task-define-check-condition 
                *ngIf="isCheck(step)"
                [definition]="step.actionDefinition"
                [(item)]="step.conditionalSections[i]"
                [isEditable]="isEditable"
                (remove)="removeConditionalSection($event)"
                (itemChange)="stepChange.emit(step)">
            </task-define-check-condition>

            <task-define-verify-condition
                *ngIf="isVerify(step)"
                [definition]="step.actionDefinition"
                [(item)]="step.conditionalSections[i]"
                [isEditable]="isEditable"
                (remove)="removeConditionalSection($event)"
                (itemChange)="stepChange.emit(step)">
            </task-define-verify-condition>

            <div class="section condtional-section">
                <task-template-define-section [isEditable]="isEditable" [(section)]="step.conditionalSections[i].section"
                    (sectionDeleted)="removeConditionalStep(i)" (sectionChange)="stepChange.emit(step)">
                </task-template-define-section>
            </div>
        </div>
    </div>
</div>

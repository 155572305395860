import { Component, OnInit } from '@angular/core';
import { faMapSigns } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'lukla-unknown',
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.scss']
})
export class UnknownComponent implements OnInit {

  notFoundIcon = faMapSigns;
  constructor() { }
  ngOnInit(): void {}
}

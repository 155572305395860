/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Response as LuklaInventoryGroupAnchorsResponse } from '../../models/Lukla/Inventory/GroupAnchors/response';

export interface Groups$Params {
}

export function groups(http: HttpClient, rootUrl: string, params?: Groups$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaInventoryGroupAnchorsResponse>> {
  const rb = new RequestBuilder(rootUrl, groups.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LuklaInventoryGroupAnchorsResponse>;
    })
  );
}

groups.PATH = '/administration/anchors/groups';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faGrid2Plus, faToggleLargeOff, faToggleLargeOn } from '@fortawesome/pro-regular-svg-icons';
import { faSlash } from '@fortawesome/pro-solid-svg-icons';
import { faAsterisk, faBan } from '@fortawesome/pro-regular-svg-icons';
import { lastValueFrom } from 'rxjs';
import { AnchorService } from '../../../../api/services/anchor.service';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';
import { AnchorView } from '../../../../api/models/Lukla/Inventory/anchor-view';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'task-define-inventory',
  templateUrl: './define-inventory.component.html',
  styleUrls: ['./define-inventory.component.scss']
})
export class DefineInventoryComponent implements OnInit {

  constructor(
    private readonly anchorService: AnchorService
  ) { }

  headerIcon = faGrid2Plus;
  deactivateIcon = faToggleLargeOff;
  activateIcon = faToggleLargeOn;
  requiredIcon = faAsterisk;
  slashIcon = faSlash;
  banIcon = faBan;

  @Input() isEditable = true;
  @Input() step : TaskTemplateStep;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();

  anchors : AnchorView[];
  groupedAnchors: Record<string, any>;
  loaded = false;

  async ngOnInit(): Promise<void> {
    this.anchors = await lastValueFrom(this.anchorService.available());

    if (this.step && !this.step.actionDefinition) {
      this.step.actionDefinition = {
        anchors: this.anchors.map(x => ({
          id: x.id,
          activated: true
        }))
      };
    }
    
    if (this.step?.actionDefinition) {
      this.setupAnchors();
    }

    this.loaded = true
  }

  setupAnchors() {
    this.groupedAnchors = this.anchors.reduce((r, a) => {
      const anchor = this.step.actionDefinition.anchors.find(x => x.id === a.id);
      const displayItem = {
        id: anchor.id,
        iconName: <IconProp>a.iconName,
        name: a.displayName,
        required: anchor.required,
        activated: anchor.activated
      };

      var parentName = a?.parent ?? '';
      r[parentName] = [...(r[parentName] || []), displayItem];
      return r;
    }, {});
  }

  deactivate(id: string) {
    const anchor = this.step.actionDefinition.anchors.find(x => x.id === id);
    anchor.activated = false;
    this.stepChange.emit(this.step);
    this.setupAnchors();
  }

  activate(id: string) {
    const anchor = this.step.actionDefinition.anchors.find(x => x.id === id);
    anchor.activated = true;
    this.stepChange.emit(this.step);
    this.setupAnchors();
  }

  makeRequired(id: string) {
    const anchor = this.step.actionDefinition.anchors.find(x => x.id === id);
    anchor.required = true;
    this.stepChange.emit(this.step);
    this.setupAnchors();
  }

  makeOptional(id: string) {
    const anchor = this.step.actionDefinition.anchors.find(x => x.id === id);
    anchor.required = false;
    this.stepChange.emit(this.step);
    this.setupAnchors();
  }

  activateAll = (anchors) => anchors.forEach(x => this.activate(x.id));
  deactivateAll = (anchors) => anchors.forEach(x => this.deactivate(x.id));
  makeAllRequired = (anchors) => anchors.forEach(x => this.makeRequired(x.id));
  makeAllOptional = (anchors) => anchors.forEach(x => this.makeOptional(x.id));
}
